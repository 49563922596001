import { useMemo } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { RoleType, User } from 'src/models/user';
import { Organization } from 'src/models/organization';
import { apiRoutes, useApi } from 'src/services/swr';

export interface MenuOption {
  name: string;
  icon: IconDefinition;
  path?: string;
  isDisabled?: boolean;
  requiresRegistration?: boolean;
  requiresRole?: RoleType[];
}

export const CheckMenuOptions = (options: MenuOption[]): MenuOption[] => {
  const { data: user } = useApi<User>(apiRoutes.user.get);
  const { data: organization } = useApi<Organization>(
    apiRoutes.organization.get
  );

  return useMemo(
    () =>
      options.map((opt) => ({
        ...opt,
        isDisabled:
          opt.isDisabled ||
          (opt.requiresRole &&
            !user?.roles.some((r) => opt.requiresRole?.includes(r))) ||
          (opt.requiresRegistration &&
            (!organization?.id || !organization.isRegistrationComplete)),
      })),
    [
      options,
      user?.roles,
      organization?.id,
      organization?.isRegistrationComplete,
    ]
  );
};
