import { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { Route, Switch, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AdminDashboard } from './pages/admin/Dashboard';
import { AdminOrganizations } from './pages/admin/Organizations';
import { AdvocateClients } from './pages/advocate/Clients';
import { AdvocateDashboard } from './pages/advocate/Dashboard';
import { AdvocateDirectory } from './pages/advocate/Directory';
import { AdvocateDirectoryResult } from './pages/advocate/DirectoryResult';
import { PublicDirectory } from './pages/directory/PublicDirectory';
import { ErrorPage } from './pages/ErrorPage';
import { HandleLogin } from './pages/HandleLogin';
import { InvitePage } from './pages/InvitePage';
import { HomePage } from './pages/home/HomePage';
import { ProviderDashboard } from './pages/provider/Dashboard';
import { ProviderOrganization } from './pages/provider/Organization';
import { ProviderReferral } from './pages/provider/Referral';
import { ProviderServices } from './pages/provider/Services';
import { ProviderSettings } from './pages/provider/Settings';
import { Routes } from './utils/routes';

// Load global styles on app launch.
import './styles/styles.scss';

export const App = (): JSX.Element => {
  // When the path changes, scroll to the top of the page
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <Route exact path={Routes.HomePath} component={HomePage} />
      <Route path={Routes.HandleLoginPath} component={HandleLogin} />
      <Route path={Routes.DirectoryPath} component={PublicDirectory} />
      <Route path={Routes.InvitePath} component={InvitePage} />

      {/* Admin */}
      <ProtectedRoute
        path={Routes.AdminDashboardPath}
        component={AdminDashboard}
      />
      <ProtectedRoute
        path={Routes.AdminOrganizationsPath}
        component={AdminOrganizations}
      />

      {/* Provider */}
      <ProtectedRoute
        path={Routes.ProviderDashboardPath}
        component={ProviderDashboard}
      />
      <ProtectedRoute
        path={Routes.ProviderOrganizationPath}
        component={ProviderOrganization}
      />
      <ProtectedRoute
        path={Routes.ProviderServicesPath}
        component={ProviderServices}
      />
      <ProtectedRoute
        path={Routes.ProviderReferralPath}
        component={ProviderReferral}
      />
      <ProtectedRoute
        path={Routes.ProviderSettingsPath}
        component={ProviderSettings}
      />

      {/* Advocate */}
      <ProtectedRoute
        path={Routes.AdvocateDashboardPath}
        component={AdvocateDashboard}
      />
      <ProtectedRoute
        path={Routes.AdvocateClientsPath}
        component={AdvocateClients}
      />
      <ProtectedRoute
        path={Routes.AdvocateDirectoryResultPath}
        component={AdvocateDirectoryResult}
      />
      <ProtectedRoute
        path={Routes.AdvocateDirectoryPath}
        component={AdvocateDirectory}
      />

      <Route path="*" component={ErrorPage} />
    </Switch>
  );
};
