import { IdValuePair } from './idValuePair';

export enum QuestionKind {
  Text,
  Boolean,
  SingleOption,
  MultiSelect,
  Number,
  Date,
}

export enum QuestionConditionComparison {
  Equals,
  Or,
  EqualOrLess,
  EqualOrGreater,
}

export type QuestionCondition = {
  questionId: number;
  value: string;
  comparison: QuestionConditionComparison;
};

export type Question = {
  id: number;
  label: string;
  questionKind: QuestionKind;
  order: number;
  options: IdValuePair[];
  condition?: QuestionCondition;
};
