import { Alert, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PageLoading } from 'src/components/pageLoading';
import { Referral } from 'src/models/referral';
import { ReferralCard } from 'src/components/referralCard';
import { ResourceType } from 'src/models/resource';
import { Routes } from 'src/utils/routes';
interface Props {
  resourceType: ResourceType;
  resourceId: number;
  referrals: Referral[];
  isLoading?: boolean;
  onUpdated?: (referral: Referral) => void;
  onArchived?: (referral: Referral) => void;
}

export const Referrals = ({
  resourceType,
  resourceId,
  referrals,
  isLoading,
  onUpdated,
  onArchived,
}: Props): JSX.Element => {
  return (
    <>
      {isLoading && !referrals ? (
        <PageLoading />
      ) : (
        <>
          {referrals && referrals.length > 0 ? (
            referrals.map((referral, index) => (
              <ReferralCard
                key={`referral${index}`}
                referral={referral}
                resourceType={resourceType}
                resourceId={resourceId}
                onUpdated={onUpdated}
                onArchived={onArchived}
              />
            ))
          ) : (
            <Row xs={12}>
              <Col>
                <Alert variant="primary" className="text-center">
                  <small>
                    {resourceType === ResourceType.Client ? (
                      <>{`Use the "Find Services" button to search for services and make referrals`}</>
                    ) : resourceType === ResourceType.Service ? (
                      <>
                        {`Referrals will be displayed here once they are made to your service. Be sure to keep your service availability up to date so that your service shows up in search results. You can update your service availability by clicking on the "X Open" link on a service card from your `}
                        <Link to={Routes.ProviderDashboardPath}>Dashboard</Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </small>
                </Alert>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};
