import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';

interface Props {
  popup: string | JSX.Element;
  content: JSX.Element;
}

export const CustomTooltip = (props: Props): JSX.Element => {
  const renderTooltip = (tooltipProps: OverlayInjectedProps): JSX.Element => (
    <Tooltip id="tooltip" {...tooltipProps}>
      {props.popup}
    </Tooltip>
  );

  // The hover trigger is repeated twice here as a workaround to the error message that specifing
  // only "hover" and not "focus" makes it so that touch and keyboard users cannot see it. The problem
  // is that when "focus" is included then the tooltip shows up when you tap on an element, like a
  // button, and then stays visible, which is not the right behavior. Therefore "focus" is excluded.

  // OverlayTrigger causes a "findDOMNode is deprecated in StrictMode" error which is fixed
  // in newer versions of react-bootstrap (https://github.com/react-bootstrap/react-bootstrap/issues/5519).
  return (
    <OverlayTrigger
      placement="top"
      trigger={['hover', 'hover']}
      overlay={renderTooltip}
    >
      {props.content}
    </OverlayTrigger>
  );
};
