import { useCallback, useEffect, useState } from 'react';
import { ContinueResourceRegistration } from 'src/components/continueResourceRegistration';
import { DynamicRegistrationSteps } from 'src/components/dynamicRegistrationSteps';
import { MenuLayout } from 'src/components/menuLayout';
import { PageLoading } from 'src/components/pageLoading';
import { ProfileRegistrationStep } from 'src/components/profileRegistrationStep';
import { AdvocateMenuOptions } from 'src/models/advocateMenuOptions';
import { Organization } from 'src/models/organization';
import { ResourceType } from 'src/models/resource';
import {
  EmptyRegistrationDetails,
  RegistrationDetails as RegistrationDetails,
} from 'src/models/registrationDetails';
import { WelcomePage } from 'src/pages/advocate/Welcome';
import { OrganizationRegistrationContinue } from 'src/pages/provider/registration/Continue';
import { OrganizationRegistrationStep1Confirmation } from 'src/pages/provider/registration/Step1Confirmation';
import { OrganizationRegistrationStep2 } from 'src/pages/provider/registration/Step2';
import { apiRoutes, useApi } from 'src/services/swr';
import { Routes, UrlParamNames } from 'src/utils/routes';
import { InvalidId } from 'src/utils/helpers';

enum Page {
  Loading,
  Welcome,
  OrganizationRegistrationNotVerified,
  OrganizationRegistrationContinue,
  OrganizationRegistrationStep2,
  ClientRegistrationStep1,
  ClientRegistrationContinue,
  ClientRegistrationStep2,
}

export const AdvocateDashboard = (): JSX.Element => {
  const { data: organization, isValidating: isLoadingOrganization } =
    useApi<Organization>(apiRoutes.organization.get);
  const { data: incompleteClient, isValidating: isLoadingProgress } =
    useApi<RegistrationDetails>(apiRoutes.clientRegistration.incompleteClient, {
      dedupingInterval: 0,
    });

  const [content, setContent] = useState<JSX.Element>(<></>);

  const updatePageContent = useCallback(
    (
      newPage: Page,
      registrationDetails: RegistrationDetails = EmptyRegistrationDetails
    ) => {
      switch (newPage) {
        case Page.Loading:
          setContent(<PageLoading />);
          break;
        case Page.OrganizationRegistrationNotVerified:
          setContent(<OrganizationRegistrationStep1Confirmation />);
          break;
        case Page.OrganizationRegistrationContinue:
          setContent(
            <OrganizationRegistrationContinue
              onComplete={(): void =>
                updatePageContent(Page.OrganizationRegistrationStep2)
              }
            />
          );
          break;
        case Page.OrganizationRegistrationStep2:
          setContent(
            <OrganizationRegistrationStep2
              onComplete={(): void => updatePageContent(Page.Welcome)}
            />
          );
          break;
        case Page.ClientRegistrationStep1:
          setContent(
            <ProfileRegistrationStep
              resourceType={ResourceType.Client}
              title="Client Registration"
              description="Let's register a client profile! You will
                be able to manage and update information about your clients at any time.
                Progress will be saved after each page so that you can return to complete
                your client registration. Please be thorough in answering the following
                questions so that TIRA can assist with making quality referrals for your
                clients."
              submitRoute={apiRoutes.clientRegistration.submitProfile}
              onCancel={(): void => updatePageContent(Page.Welcome)}
              onComplete={(resourceId): void =>
                updatePageContent(Page.ClientRegistrationStep2, {
                  id: resourceId,
                  name: '',
                })
              }
            />
          );
          break;
        case Page.ClientRegistrationContinue:
          setContent(
            <ContinueResourceRegistration
              registration={registrationDetails}
              title="Client Registration"
              description={`Let's complete the client registration that you previously began for '${registrationDetails.name}'`}
              deleteRoute={apiRoutes.client.delete(registrationDetails.id)}
              onCancel={(): void => updatePageContent(Page.Welcome)}
              onComplete={(): void =>
                updatePageContent(
                  Page.ClientRegistrationStep2,
                  registrationDetails
                )
              }
            />
          );
          break;
        case Page.ClientRegistrationStep2:
          setContent(
            <DynamicRegistrationSteps
              title="Client Registration"
              resourcesRoute={apiRoutes.advocate.clients}
              progressRoute={apiRoutes.clientRegistration.incompleteProgress}
              questionPageCountRoute={
                apiRoutes.clientRegistration.questionPageCount
              }
              questionPageRoute={apiRoutes.clientRegistration.questionPage}
              submitQuestionPageRoute={
                apiRoutes.clientRegistration.submitQuestionPage
              }
              deleteRoute={apiRoutes.client.delete(registrationDetails.id)}
              resourcesPageUrl={Routes.AdvocateClientsPath}
              resourceUrlParamName={UrlParamNames.ClientIdUrlParamName}
              onCancel={(): void => updatePageContent(Page.Welcome)}
            />
          );
          break;
        default:
          setContent(
            <WelcomePage
              registerNewClient={(): void =>
                updatePageContent(Page.ClientRegistrationStep1)
              }
            />
          );
          break;
      }
    },
    []
  );

  useEffect(() => {
    if (isLoadingOrganization || isLoadingProgress) {
      updatePageContent(Page.Loading);
    } else if (organization?.id) {
      if (!organization.isVerified) {
        updatePageContent(Page.OrganizationRegistrationNotVerified);
      } else if (!organization.isRegistrationComplete) {
        updatePageContent(Page.OrganizationRegistrationContinue);
      } else {
        // Don't use optional chaining here because undefined !== InvalidId.
        // Don't use optional chaining here because undefined !== InvalidId.
        if (incompleteClient && incompleteClient.id !== InvalidId) {
          updatePageContent(Page.ClientRegistrationContinue, incompleteClient);
        } else {
          updatePageContent(Page.Welcome);
        }
      }
    } else {
      // Not registered as a provider. Should not be able to get here.
      // Should only be able to start organization registration from the provider page.
    }
  }, [
    incompleteClient,
    isLoadingOrganization,
    isLoadingProgress,
    organization,
    updatePageContent,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [content]);

  return (
    <MenuLayout
      pageIndex={AdvocateMenuOptions().findIndex(
        (o) => o.path === Routes.AdvocateDashboardPath
      )}
      menuOptions={AdvocateMenuOptions()}
    >
      {content}
    </MenuLayout>
  );
};
