import { AffiliationOrLicenseType } from './affiliation';

export type OrganizationRegistrationStep1ViewModel = {
  name?: string;
  phoneNumber?: string;
  email?: string;
  address?: string;
  isUndisclosedAddress?: boolean;
  zipCode?: string;
  websiteUrl?: string;
  incorporationYear?: number;
  nonprofitStatus?: number;
  primaryPurpose?: number;
  affiliationOrLicense?: AffiliationOrLicenseType[];
};

export const EmptyOrganizationRegistrationStep1ViewModel: OrganizationRegistrationStep1ViewModel =
  {
    name: '',
    phoneNumber: '',
    email: '',
    address: '',
    isUndisclosedAddress: false,
    zipCode: '',
    websiteUrl: '',
    incorporationYear: 0,
    nonprofitStatus: 0,
    primaryPurpose: 0,
    affiliationOrLicense: [],
  };
