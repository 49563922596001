import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Form, Modal } from 'react-bootstrap';
import { CancelButton } from 'src/components/cancelButton';
import { DirectoryLayout } from 'src/components/directoryLayout';
import { MenuLayout } from 'src/components/menuLayout';
import { PrimaryButton } from 'src/components/primaryButton';
import { AdvocateMenuOptions } from 'src/models/advocateMenuOptions';
import {
  DirectorySearchResult,
  EmptyDirectorySearchResult,
} from 'src/models/directorySearchResult';
import { Referral } from 'src/models/referral';
import { ReferralStat } from 'src/models/referralStat';
import { ReferralViewModel } from 'src/models/referralViewModel';
import { ServiceKind } from 'src/models/resource';
import { ResourceDetails } from 'src/models/resourceDetails';
import { ServiceType } from 'src/models/serviceType';
import { useIdToken } from 'src/services/msal';
import {
  FetchMethod,
  apiRoutes,
  authenticatedFetch,
  useApi,
} from 'src/services/swr';
import { features } from 'src/utils/constants';
import { debounce, showErrorNotification } from 'src/utils/helpers';
import { Routes, UrlParamNames } from 'src/utils/routes';
import styles from './Directory.module.scss';

export const AdvocateDirectory = (): JSX.Element => {
  const history = useHistory();
  const { token } = useIdToken();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const clientIdParam = urlParams.get(UrlParamNames.ClientIdUrlParamName);

  const serviceTypes = useApi<ServiceType[]>(apiRoutes.api.serviceTypes);

  const [canMakeReferrals, setCanMakeReferrals] = useState(false);
  const [referralService, setReferralService] = useState(
    EmptyDirectorySearchResult
  );
  const [referralConsent, setReferralConsent] = useState(false);

  const [isSubmittingReferral, setIsSubmittingReferral] = useState(false);
  const [isSubmittingReferralComplete, setIsSubmittingReferralComplete] =
    useState(false);

  const { data: client, isValidating: isLoadingClient } =
    useApi<ResourceDetails>(
      clientIdParam
        ? // Select resource from URL
          apiRoutes.client.details(Number(clientIdParam))
        : undefined
    );

  const { mutate: updateAdvocateReferralStats } = useApi<ReferralStat[]>(
    apiRoutes.organization.referralStats.advocate
  );

  const { mutate: updateReferrals } = useApi<Referral[]>(
    features.referrals && clientIdParam
      ? apiRoutes.client.referrals(Number(clientIdParam))
      : undefined
  );

  const onResultClicked = (result: DirectorySearchResult): void => {
    history.push(
      `${Routes.AdvocateDirectoryResultPath}?${UrlParamNames.ServiceIdUrlParamName}=${result.id}`
    );
  };

  const onReferralClicked = (result: DirectorySearchResult): void => {
    setReferralService(result);
  };

  const closeReferral = (): void => {
    setReferralService(EmptyDirectorySearchResult);
    setReferralConsent(false);

    // Reset when the modal is closed instead of in the request flow so that
    // the modal can display more info once the referral is submitted.
    setIsSubmittingReferralComplete(false);
  };

  const getServiceKindName = useCallback(
    (kind: ServiceKind): string => {
      const match = serviceTypes.data?.find((t) => t.id === kind);
      return match?.name ?? 'N/A';
    },
    [serviceTypes]
  );

  const onSubmitReferral = async (): Promise<void> => {
    if (
      isSubmittingReferral ||
      !clientIdParam ||
      referralService === EmptyDirectorySearchResult
    ) {
      return;
    }

    setIsSubmittingReferral(true);

    const referralModel: ReferralViewModel = {
      serviceId: referralService.id,
    };

    try {
      await authenticatedFetch<Referral>(
        apiRoutes.client.referrals(Number(clientIdParam)),
        FetchMethod.POST,
        token,
        JSON.stringify(referralModel)
      );

      setIsSubmittingReferralComplete(true);
      await debounce();

      // Refresh referrals and stats for the next time they are viewed.
      updateReferrals();
      updateAdvocateReferralStats();

      setIsSubmittingReferral(false);
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
      setIsSubmittingReferral(false);
    }
  };

  useEffect(() => {
    // Referrals can only be made if there is a client ID available.
    setCanMakeReferrals(!!clientIdParam && !isLoadingClient);
  }, [clientIdParam, isLoadingClient]);

  return (
    <>
      <MenuLayout
        pageIndex={AdvocateMenuOptions().findIndex(
          (o) => o.path === Routes.AdvocateDirectoryPath
        )}
        menuOptions={AdvocateMenuOptions()}
      >
        <div className={styles.pageTitle}>Search for Services</div>
        <div className="mt-3">
          <DirectoryLayout
            showAvailability={true}
            showReferral={true}
            canMakeReferral={canMakeReferrals}
            onResultClick={onResultClicked}
            onReferralClick={onReferralClicked}
          />
        </div>
      </MenuLayout>

      <Modal
        centered
        scrollable={true}
        show={referralService !== EmptyDirectorySearchResult}
        onHide={closeReferral}
      >
        <Modal.Header closeButton closeLabel="close">
          <b>
            Make a Referral for{' '}
            <b className={styles.modalIdentifier}>{client?.profile.name}</b>
          </b>
        </Modal.Header>
        <Modal.Body>
          <div>
            <b>Organization: </b>
            <b className={styles.modalIdentifier}>
              {referralService?.serviceProvider?.name}
            </b>
          </div>
          <div>
            <b>Service: </b>
            <b className={styles.modalIdentifier}>{referralService?.name}</b>
          </div>
          <div>
            <b>Service Type: </b>
            <b className={styles.modalIdentifier}>
              {getServiceKindName(referralService?.type)}
            </b>
          </div>
          <div className="mt-3">
            {!isSubmittingReferralComplete ? (
              <Form.Check
                label="I verify that I have obtained the consent of the individual for
              whom this referral is being made to the service provider"
                onChange={(e): void => setReferralConsent(e.target.checked)}
                disabled={isSubmittingReferral}
                required
              />
            ) : (
              <>
                <Alert variant="primary" className="text-center">
                  <div>Referral submitted!</div>
                  <div>
                    {`Navigate to the `}
                    <Link
                      to={`${Routes.AdvocateClientsPath}?${UrlParamNames.ClientIdUrlParamName}=${clientIdParam}`}
                    >
                      client profile
                    </Link>
                    {` to view all referrals`}
                  </div>
                </Alert>
              </>
            )}
          </div>
        </Modal.Body>
        {!isSubmittingReferralComplete && (
          <Modal.Footer>
            <PrimaryButton
              content="Submit"
              isLoading={isSubmittingReferral}
              isComplete={isSubmittingReferralComplete}
              isDisabled={!referralConsent}
              onClick={onSubmitReferral}
            />
            <CancelButton
              content="Cancel"
              isDisabled={isSubmittingReferral}
              onClick={closeReferral}
            />
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
