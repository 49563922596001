import { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import { faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { CancelButton } from 'src/components/cancelButton';
import { PrimaryButton } from 'src/components/primaryButton';
import { PseudoButton } from 'src/components/pseudoButton';
import { ReferralStats } from 'src/models/referralStats';
import { ReferralStatsCardLayout } from 'src/components/referralStatsCardLayout';
import { Client } from 'src/models/resource';
import { apiRoutes, useApi } from 'src/services/swr';
import { Routes, UrlParamNames } from 'src/utils/routes';
import {
  cardHeaderText,
  cardInfoText,
  cardInfoTitle,
  noWrap,
} from 'src/utils/styles';
import { CalendarStrings, Locale } from 'src/utils/helpers';

interface Props {
  client: Client;
  iconSize?: SizeProp;
  isInactive?: boolean;
  isDisabled?: boolean;
  onDelete?: (resourceId: number) => void;
}

export const ClientCard = (props: Props): JSX.Element => {
  const history = useHistory();

  const { data: referralStats, isValidating: isLoadingReferralStats } =
    useApi<ReferralStats>(apiRoutes.client.referralStats(props.client.id));

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleConfirmDelete = (): void => {
    setShowConfirmDelete(false);
    props.onDelete?.(props.client.id);
  };

  const onClick = (): void => {
    if (!props.isDisabled) {
      history.push(
        `${Routes.AdvocateClientsPath}?${UrlParamNames.ClientIdUrlParamName}=${props.client.id}`
      );
    }
  };

  return (
    <>
      <ReferralStatsCardLayout
        onClick={onClick}
        icon={faUser}
        iconSize={props.iconSize}
        grayscale={props.isInactive === true || props.isDisabled === true}
        headerRow={
          <Row className={cardHeaderText}>
            <Col xs={props.onDelete ? 10 : 12}>{props.client.name}</Col>
            {props.onDelete && (
              <Col xs={2} className="d-flex flex-row-reverse">
                <PseudoButton
                  id={`deleteClient${props.client.id}`}
                  content={<FontAwesomeIcon icon={faTrashAlt} title="Delete" />}
                  onClick={(): void => setShowConfirmDelete(true)}
                  isDisabled={props.isInactive || props.isDisabled}
                />
              </Col>
            )}
          </Row>
        }
        contentRow={
          <>
            <Row>
              <Col xs={12} sm={5}>
                <div className="d-flex flex-column">
                  <div className={`${cardInfoTitle} ${noWrap}`}>ZIP Code</div>
                  <div className={`${cardInfoText} ${noWrap}`}>
                    {props.client.zipCode}
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={7}>
                <div className="d-flex flex-column">
                  <div className={`${cardInfoTitle} ${noWrap}`}>
                    Last Updated
                  </div>
                  <div className={cardInfoText}>
                    <>
                      <Moment calendar={CalendarStrings} locale={Locale} local>
                        {props.client.lastUpdatedOn}
                      </Moment>
                      {props.client.lastUpdatedBy &&
                        ` by ${props.client.lastUpdatedBy}`}
                    </>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        }
        statsIsLoading={isLoadingReferralStats && !referralStats}
        statsReadyCount={referralStats?.waitingOnAdvocate ?? 0}
        statsWaitingCount={referralStats?.waitingOnProvider ?? 0}
      />

      <Modal
        centered
        show={showConfirmDelete}
        onHide={(): void => setShowConfirmDelete(false)}
      >
        <Modal.Header closeButton closeLabel="close">
          <Modal.Title>
            Delete {props.client.name} and all associated data
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <CancelButton
            content="Cancel"
            onClick={(): void => setShowConfirmDelete(false)}
          />
          <PrimaryButton content="Delete" onClick={handleConfirmDelete} />
        </Modal.Footer>
      </Modal>
    </>
  );
};
