import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Col, Dropdown, Row } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Moment from 'react-moment';
import { BlockNavigation } from 'src/components/blockNavigation';
import { ClientCard } from 'src/components/clientCard';
import { CustomTooltip } from 'src/components/customTooltip';
import { PageLoading } from 'src/components/pageLoading';
import { ResourceLayout } from 'src/components/resourceLayout';
import { ServiceCard } from 'src/components/serviceCard';
import { Answer } from 'src/models/answer';
import { Note } from 'src/models/note';
import { NoteViewModel } from 'src/models/noteViewModel';
import { Referral } from 'src/models/referral';
import {
  Client,
  EmptyResource,
  Resource,
  ResourceType,
  Service,
} from 'src/models/resource';
import {
  EmptyResourceDetails,
  ResourceDetails,
} from 'src/models/resourceDetails';
import { ResourceProfileViewModel } from 'src/models/resourceProfileViewModel';
import { useIdToken } from 'src/services/msal';
import { authenticatedFetch, FetchMethod, useApi } from 'src/services/swr';
import { features } from 'src/utils/constants';
import {
  CalendarStrings,
  capitalize,
  debounce,
  InvalidId,
  Locale,
  plural,
  resourceTypeToName,
  showErrorNotification,
  showInformationSavedNotification,
  showSuccessNotification,
} from 'src/utils/helpers';
import styles from './resourcesLayout.module.scss';

// The resources and details routes are passed in because this component is shared
// between organizations and admins. The backend routes need to be kept separate for
// security, so the routes get provided by the parent component.
interface Props {
  resourceType: ResourceType;
  resourcesRoute?: string;
  detailsRoute: (resourceId: number) => string | undefined;
  referralsRoute?: (resourceId: number) => string | undefined;
  notesRoute?: (resourceId: number) => string | undefined;
  deleteRoute: (resourceId: number) => string | undefined;
  dashboardPath: string;
  resourcesPageUrl: string;
  resourceIdUrlParamName: string;
  hideCards?: boolean;
  hideNoResourcesLink?: boolean;
}

export const ResourcesLayout = (props: Props): JSX.Element => {
  const { token } = useIdToken();
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const resourceIdParam = urlParams.get(props.resourceIdUrlParamName);

  const [selectedResource, setSelectedResource] = useState(EmptyResource);
  const [currentDetails, setCurrentDetails] = useState(EmptyResourceDetails);
  const [detailsNeedSave, setDetailsNeedSave] = useState(false);
  const [isDetailsValidated, setIsDetailsValidated] = useState(false);
  const [isSavingDetails, setIsSavingDetails] = useState(false);
  const [isDeletingResourceId, setIsDeletingResourceId] =
    useState<number>(InvalidId);

  const [currentReferrals, setCurrentReferrals] = useState<Referral[]>([]);

  const [currentNotes, setCurrentNotes] = useState<Note[]>([]);
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [isSavingNoteComplete, setIsSavingNoteComplete] = useState(false);

  // Load all resources
  const {
    data: resources,
    isValidating: isLoadingResources,
    mutate: refreshResources,
  } = useApi<Resource[]>(props.resourcesRoute);

  // Load the current resource details if URL param exists
  const {
    data: resourceDetails,
    isValidating: isLoadingDetails,
    mutate: refreshResourceDetails,
  } = useApi<ResourceDetails>(
    resourceIdParam
      ? // Select resource from URL
        props.detailsRoute(Number(resourceIdParam))
      : resources?.[0]
      ? // Select first resource
        props.detailsRoute(resources[0].id)
      : undefined
  );

  const {
    data: resourceReferrals,
    isValidating: isLoadingReferrals,
    mutate: updateReferrals,
  } = useApi<Referral[]>(
    features.referrals
      ? resourceIdParam
        ? // Select resource from URL
          props.referralsRoute?.(Number(resourceIdParam))
        : resources?.[0]
        ? // Select first resource
          props.referralsRoute?.(resources[0].id)
        : undefined
      : undefined
  );

  const { data: resourceNotes, isValidating: isLoadingNotes } = useApi<Note[]>(
    resourceIdParam
      ? // Select resource from URL
        props.notesRoute?.(Number(resourceIdParam))
      : resources?.[0]
      ? // Select first resource
        props.notesRoute?.(resources[0].id)
      : undefined
  );

  const onReferralUpdated = (referral: Referral): void => {
    setCurrentReferrals(
      currentReferrals.map((r) => (r.id !== referral.id ? r : referral))
    );
  };

  const onReferralArchived = (): void => {
    updateReferrals();
  };

  const resourceName = useMemo(() => {
    return resourceTypeToName(props.resourceType);
  }, [props.resourceType]);

  const onProfileChanged = (profile: ResourceProfileViewModel): void => {
    setCurrentDetails({
      ...currentDetails,
      profile: profile,
    });
    setDetailsNeedSave(true);
  };

  const onDetailsChanged = (pageIndex: number, answers: Answer[]): void => {
    if (currentDetails) {
      const updatedDetails = { ...currentDetails };
      setCurrentDetails(updatedDetails);
      setDetailsNeedSave(true);
      updatedDetails.pages[pageIndex].answers = answers;
    }
  };

  const onSaveDetails = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (isSavingDetails) {
      return;
    }

    if (e.currentTarget.checkValidity() && resourceIdParam) {
      const resourceIdNum = Number(resourceIdParam);
      const route = props.detailsRoute(resourceIdNum);

      if (!route) {
        return;
      }

      setIsDetailsValidated(true);
      setIsSavingDetails(true);

      try {
        await authenticatedFetch(
          route,
          FetchMethod.POST,
          token,
          JSON.stringify(currentDetails)
        );

        await debounce();
        setDetailsNeedSave(false);

        // Fetch updated resources
        await refreshResources();
        await refreshResourceDetails();

        setIsDetailsValidated(false);
        setIsSavingDetails(false);

        showInformationSavedNotification();
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsSavingDetails(false);
      }
    }
  };

  const onSaveNote = async (text: string): Promise<void> => {
    if (isSavingNote) {
      return;
    }

    if (resourceIdParam) {
      const resourceIdNum = Number(resourceIdParam);
      const route = props.notesRoute?.(resourceIdNum);

      if (!route) {
        return;
      }

      setIsSavingNote(true);

      const noteModel: NoteViewModel = {
        text: text,
      };

      try {
        const newNote = await authenticatedFetch<Note>(
          route,
          FetchMethod.POST,
          token,
          JSON.stringify(noteModel)
        );

        if (newNote) {
          // Add the new note to the list.
          setCurrentNotes([newNote, ...currentNotes]);
        }

        setIsSavingNoteComplete(true);
        await debounce();

        setIsSavingNote(false);
        setIsSavingNoteComplete(false);

        showSuccessNotification('Note saved!');
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsSavingNote(false);
      }
    }
  };

  const deleteResource = async (resourceId: number): Promise<void> => {
    try {
      setIsDeletingResourceId(resourceId);
      await authenticatedFetch(
        props.deleteRoute(resourceId) || '',
        FetchMethod.DELETE,
        token,
        JSON.stringify(currentDetails)
      );
      await refreshResources();

      showSuccessNotification(`${capitalize(resourceName)} deleted!`);

      // Remove ID from URL. A new resource will get selected when the params change is detected.
      history.push(props.resourcesPageUrl);
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
    } finally {
      setIsDeletingResourceId(InvalidId);
    }
  };

  const setSelectedResourceFromUrl = useCallback(() => {
    if (resources && resources.length > 0) {
      setSelectedResource(
        resourceIdParam
          ? resources.find(
              (resource: Resource) => resource.id === Number(resourceIdParam)
            ) ?? resources[0]
          : resources[0]
      );
    } else {
      setSelectedResource(EmptyResource);
    }
  }, [resourceIdParam, resources]);

  useEffect(() => {
    // When there is no ID in the URL and a default resource is selected, update the URL
    if (selectedResource?.id > 0 && !resourceIdParam) {
      urlParams.set(
        props.resourceIdUrlParamName,
        selectedResource.id.toString()
      );
      history.replace(window.location.pathname + '?' + urlParams.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, selectedResource]);

  useEffect(() => {
    // Update the selected resource when the resources change because the param may no longer be valid.
    if (resources) {
      setSelectedResourceFromUrl();
    }
  }, [resources, setSelectedResourceFromUrl]);

  useEffect(() => {
    // Update the selected resource when the URL param changes.
    setSelectedResourceFromUrl();
  }, [resourceIdParam, setSelectedResourceFromUrl]);

  useEffect(() => {
    setCurrentDetails(resourceDetails ?? EmptyResourceDetails);
  }, [resourceDetails]);

  useEffect(() => {
    setCurrentReferrals(resourceReferrals ?? []);
  }, [resourceReferrals]);

  useEffect(() => {
    setCurrentNotes(resourceNotes ?? []);
  }, [resourceNotes]);

  return (
    <BlockNavigation needsSave={detailsNeedSave}>
      <Row className={styles.header}>
        <Col
          xs={12}
          lg={!resources?.length ? 12 : !props.hideCards ? 8 : 12}
          className="d-flex justify-content-center align-items-center"
        >
          <div className={styles.pageTitle}>
            {isLoadingResources && !resources?.length ? (
              <PageLoading showIcon={false} />
            ) : resources?.length === 0 ? (
              !props.hideNoResourcesLink ? (
                <Alert variant="primary">
                  {`Add ${plural(resourceName)} from your `}
                  <Link to={props.dashboardPath}>Dashboard</Link>
                </Alert>
              ) : (
                <div>No {plural(resourceName)}</div>
              )
            ) : (
              <div>
                <div className="d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center">
                    <span>
                      {selectedResource.name ?? `Select a ${resourceName}`}
                    </span>

                    <Dropdown alignRight={true}>
                      <CustomTooltip
                        popup={`Change ${resourceName}`}
                        content={
                          <Dropdown.Toggle
                            aria-label={`Select ${resourceName}`}
                            className={styles.pageTitleButton}
                            variant="outline-dark"
                          />
                        }
                      />
                      <Dropdown.Menu>
                        {resources?.map((resource: Resource) => (
                          <Dropdown.Item
                            key={resource.id}
                            active={resource.id === selectedResource.id}
                            onClick={(): void => {
                              urlParams.set(
                                props.resourceIdUrlParamName,
                                resource.id.toString()
                              );
                              history.push(
                                window.location.pathname +
                                  '?' +
                                  urlParams.toString()
                              );
                            }}
                          >
                            {resource.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {selectedResource.lastUpdatedBy &&
                    selectedResource.lastUpdatedOn && (
                      <div className={styles.lastUpdated}>
                        <>
                          {`Updated `}
                          <Moment
                            calendar={CalendarStrings}
                            locale={Locale}
                            local
                          >
                            {selectedResource.lastUpdatedOn}
                          </Moment>
                          {` by ${selectedResource.lastUpdatedBy}`}
                        </>
                      </div>
                    )}
                </div>
                <div className={styles.line} />
              </div>
            )}
          </div>
        </Col>
      </Row>

      {selectedResource !== EmptyResource && (
        <Row>
          <Col xs={12} lg={!props.hideCards ? 8 : 12}>
            <ResourceLayout
              resourceType={props.resourceType}
              isLoadingDetails={isLoadingDetails}
              resourceId={selectedResource.id}
              resourceDetails={currentDetails}
              needsSave={detailsNeedSave}
              isValidated={isDetailsValidated}
              isSavingDetails={isSavingDetails}
              onProfileChanged={onProfileChanged}
              onDetailsChanged={onDetailsChanged}
              onSaveDetails={onSaveDetails}
              resourceReferrals={currentReferrals}
              isLoadingReferrals={isLoadingReferrals}
              onReferralUpdated={onReferralUpdated}
              onReferralArchived={onReferralArchived}
              resourceNotes={currentNotes}
              isLoadingNotes={isLoadingNotes}
              isSavingNote={isSavingNote}
              isSavingNoteComplete={isSavingNoteComplete}
              onSaveNote={onSaveNote}
            />
          </Col>

          {!props.hideCards && (
            <Col lg={4} className="d-none d-lg-block">
              {resources?.map((resource: Resource) => (
                <div key={resource.id} className="mb-3">
                  {props.resourceType === ResourceType.Client ? (
                    <ClientCard
                      client={resource as Client}
                      iconSize="2x"
                      isInactive={resource.id !== selectedResource.id}
                      isDisabled={resource.id === isDeletingResourceId}
                      onDelete={deleteResource}
                    />
                  ) : props.resourceType === ResourceType.Service ? (
                    <ServiceCard
                      service={resource as Service}
                      iconSize="2x"
                      isInactive={resource.id !== selectedResource.id}
                      isDisabled={resource.id === isDeletingResourceId}
                      onDelete={deleteResource}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </Col>
          )}
        </Row>
      )}
    </BlockNavigation>
  );
};
