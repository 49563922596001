import {
  faBuilding,
  faCalendar,
  faCog,
  faHandshake,
  faHome,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { CheckMenuOptions, MenuOption } from 'src/models/menuOption';
import { RoleType, User } from 'src/models/user';
import { Routes } from 'src/utils/routes';

const providerMenuOptions: MenuOption[] = [
  {
    name: 'Home',
    icon: faHome,
    path: Routes.HomePath,
  },
  {
    name: 'Dashboard',
    icon: faCalendar,
    path: Routes.ProviderDashboardPath,
    requiresRole: [RoleType.OrganizationAdmin, RoleType.ServiceContributor],
  },
  {
    name: 'Organization',
    icon: faBuilding,
    path: Routes.ProviderOrganizationPath,
    requiresRole: [RoleType.OrganizationAdmin],
    requiresRegistration: true,
  },
  {
    name: 'Services',
    icon: faHandshake,
    path: Routes.ProviderServicesPath,
    requiresRole: [RoleType.OrganizationAdmin, RoleType.ServiceContributor],
    requiresRegistration: true,
  },
  {
    name: 'Settings',
    icon: faCog,
    path: Routes.ProviderSettingsPath,
    requiresRole: [RoleType.OrganizationAdmin, RoleType.ServiceContributor],
    requiresRegistration: true,
  },
  {
    name: 'Logout',
    icon: faSignOutAlt,
  },
];

export const ProviderMenuOptions = (): MenuOption[] => {
  return CheckMenuOptions(providerMenuOptions);
};
