import { AffiliationOrLicenseType } from './affiliation';
import { ServiceKind } from './resource';

export interface DirectorySearchResult {
  availability: DirectorySearchAvailability;
  city: string;
  countrySubdivision: string;
  id: number;
  isUndisclosedAddress: boolean;
  emailAddress: string;
  name: string;
  phoneNumber: string;
  serviceProvider: DirectorySearchServiceProvider;
  streetAddress: string;
  type: ServiceKind;
  zipCode: string;
}

export interface DirectorySearchAvailability {
  totalUnits: number;
  hasWaitlist: boolean;
  unit: string;
  availableUnits: number;
  isWaitlistOpen: boolean;
  updatedDate?: Date;
}

export interface DirectorySearchServiceProvider {
  affiliations: DirectorySearchAffiliation[];
  city: string;
  emailAddress: string;
  id: number;
  name: string;
  phoneNumber: string;
  registrationDate: Date;
  state: string;
  url: string;
  streetAddress: string;
  zipCode: string;
}

export interface DirectorySearchAffiliation {
  label: string;
  type: AffiliationOrLicenseType;
}

export const EmptyDirectorySearchResult: DirectorySearchResult =
  {} as DirectorySearchResult;
