import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { LoadingSpinner } from 'src/components/loadingSpinner';
import { UserCard } from 'src/components/userCard';
import { RoleType, User } from 'src/models/user';
import { useIdToken } from 'src/services/msal';
import {
  apiRoutes,
  authenticatedFetch,
  FetchMethod,
  useApi,
} from 'src/services/swr';
import {
  debounce,
  InvalidId,
  showErrorNotification,
  showSuccessNotification,
} from 'src/utils/helpers';

// The routes are passed in because this component is shared
// between organizations and admins. The backend routes need to be kept separate for
// security, so the routes get provided by the parent component.
interface Props {
  usersRoute?: string;
  deleteUserRoute: (userId: number) => string | undefined;
  deleteRoleRoute: (userId: number, role: RoleType) => string | undefined;
}

export const UsersLayout = (props: Props): JSX.Element => {
  const { token } = useIdToken();

  const { mutate: getUser } = useApi<User>(apiRoutes.user.get);

  const {
    data: users,
    isValidating: isLoadingUsers,
    mutate: getUsers,
  } = useApi<User[]>(props.usersRoute);

  const [deletingRoleUserId, setDeletingRoleUserId] = useState(InvalidId);
  const [deletingUserId, setDeletingUserId] = useState(InvalidId);

  const deleteUserRole = async (
    userId: number,
    role: RoleType
  ): Promise<void> => {
    setDeletingRoleUserId(userId);

    try {
      await authenticatedFetch(
        props.deleteRoleRoute(userId, role) || '',
        FetchMethod.DELETE,
        token
      );

      await getUsers();
      await debounce();
      setDeletingRoleUserId(InvalidId);
      showSuccessNotification('Role deleted!');

      // Refresh the user in case it was their role that was deleted.
      // Do after the other work and notification since it is a side-effect.
      await getUser();
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
      setDeletingRoleUserId(InvalidId);
    }
  };

  const deleteUser = async (userId: number): Promise<void> => {
    setDeletingUserId(userId);

    try {
      await authenticatedFetch(
        props.deleteUserRoute(userId) || '',
        FetchMethod.DELETE,
        token
      );

      await getUsers();
      await debounce();
      setDeletingUserId(InvalidId);

      showSuccessNotification('User deleted!');
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
      setDeletingUserId(InvalidId);
    }
  };

  return (
    <>
      {isLoadingUsers && !users ? (
        <div className="d-flex justify-content-center">
          <LoadingSpinner />
        </div>
      ) : users?.length ? (
        <Row>
          {users?.map((user) => (
            <Col key={user.id} xs={12} md={6}>
              <UserCard
                user={user}
                isDeletingRole={deletingRoleUserId === user.id}
                isDeletingUser={deletingUserId === user.id}
                onDeleteRole={(role: RoleType): Promise<void> =>
                  deleteUserRole(user.id, role)
                }
                onDeleteUser={(): Promise<void> => deleteUser(user.id)}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};
