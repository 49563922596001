import moment from 'moment';
import { toast, Id } from 'react-toastify';
import {
  faBalanceScale,
  faBook,
  faBriefcase,
  faClinicMedical,
  faHeart,
  faHouseUser,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Answer } from 'src/models/answer';
import { IdValuePair } from 'src/models/idValuePair';
import { ResourceType, ServiceKind } from 'src/models/resource';
import {
  Question,
  QuestionConditionComparison,
  QuestionKind,
} from 'src/models/question';
import { ReferralStatus } from 'src/models/referral';
import { RoleType } from 'src/models/user';

export const NotificationAutoCloseDefault = 10000;
export const NotificationAutoCloseLong = 60000;

export const InvalidId = -1;
export const Delimiter = ',';
export const DateFormat = 'L';
export const Locale = window.navigator.language || 'en-us';

export const BooleanOptions: IdValuePair[] = [
  { id: 0, value: 'No' },
  { id: 1, value: 'Yes' },
];

export const BooleanDefault = BooleanOptions[0];

export const CalendarStrings = {
  lastDay: '[Yesterday at] LT',
  sameDay: '[Today at] LT',
  nextDay: '[Tomorrow at] LT',
  lastWeek: '[Last] dddd',
  nextWeek: 'dddd [at] LT',
  sameElse: 'l',
};

export const showInformationSavedNotification = (): void => {
  showSuccessNotification('Information saved!');
};

export const showSuccessNotification = (
  message: string,
  autoClose: number = NotificationAutoCloseDefault
): void => {
  toast.success(message, { autoClose: autoClose });
};

export const showErrorNotification = (message: string): void => {
  toast.error(message, { autoClose: false });
};

export const showErrorNotificationWithId = (message: string, id: Id): void => {
  toast.error(message, { autoClose: false, toastId: id });
};

export const plural = (input: string): string => {
  return input + (input.toLowerCase().endsWith('s') ? 'es' : 's');
};

export const pluralize = (input: string, count: number): string => {
  if (count === 1) {
    return count + ' ' + input;
  }

  return count + ' ' + plural(input);
};

export const pluralizeWithoutCount = (input: string, count: number): string => {
  return count === 1 ? input : plural(input);
};

export const capitalize = (input: string): string => {
  return input?.length ? input.charAt(0).toUpperCase() + input.slice(1) : '';
};

export const debounce = async (): Promise<unknown> =>
  await new Promise((resolve) => setTimeout(resolve, 500));

export const getServiceIcon = (kind: ServiceKind): IconDefinition => {
  switch (kind) {
    case ServiceKind.Advocacy:
      return faBriefcase;
    case ServiceKind.Education:
      return faBook;
    case ServiceKind.Legal:
      return faBalanceScale;
    case ServiceKind.MentalHealth:
      return faHeart;
    case ServiceKind.SubstanceUse:
      return faClinicMedical;
    default:
      return faHouseUser;
  }
};

export const formatPhoneNumber = (text: string): string => {
  // If the input is falsy (the user deletes the input) then just return.
  if (!text) {
    return text;
  }

  // Clean the input for any non-numeric values.
  let phoneNumber = text.replace(/[^\d]/g, '');

  // Return the value with no formatting if less than four digits in order
  // to avoid weird behavior that occurs if you format the area code too early.
  if (phoneNumber.length < 4) {
    return phoneNumber;
  }

  // If length is between 4 and 7 then start to return the formatted number.
  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // Remove the country code if set.
  if (phoneNumber.length === 11 && phoneNumber.startsWith('1')) {
    phoneNumber = phoneNumber.substring(1);
  }

  // If length is greater than 7 then add the last bit of formatting.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const getRandomNumberString = (numDigits: number): string => {
  return Math.floor(Math.random() * Math.pow(10, numDigits))
    .toString()
    .padStart(numDigits, '0');
};

export const isQuestionConditionSatisfied = (
  question: Question,
  allQuestions: Question[],
  allAnswers: Answer[]
): boolean => {
  if (!question?.condition) {
    // No condition - show the question.
    return true;
  }

  const referenceQuestion = allQuestions.find(
    (q) => q.id === question.condition?.questionId
  );
  if (!referenceQuestion) {
    // Should always have the reference question, so unexpected to land here.
    return true;
  }

  const referenceAnswer = allAnswers.find(
    (a) => a.questionId === referenceQuestion.id
  );
  if (!referenceAnswer) {
    // No answer to the reference question yet - hide the question.
    return false;
  } else {
    // Compare the answer based on the comparison type.
    switch (question.condition.comparison) {
      case QuestionConditionComparison.Equals:
        return referenceAnswer.value === question.condition.value;
      case QuestionConditionComparison.EqualOrLess:
        if (referenceQuestion.questionKind === QuestionKind.Date) {
          return (
            moment().diff(moment(referenceAnswer.value), 'years') <=
            Number(question.condition.value)
          );
        } else {
          return (
            Number(referenceAnswer.value) <= Number(question.condition.value)
          );
        }
      case QuestionConditionComparison.EqualOrGreater:
        if (referenceQuestion.questionKind === QuestionKind.Date) {
          return (
            moment().diff(moment(referenceAnswer.value), 'years') >=
            Number(question.condition.value)
          );
        } else {
          return (
            Number(referenceAnswer.value) >= Number(question.condition.value)
          );
        }
      case QuestionConditionComparison.Or:
        return referenceAnswer.value
          .split(Delimiter)
          .some((v1) =>
            question.condition?.value.split(Delimiter).some((v2) => v1 === v2)
          );
      default:
        return false;
    }
  }
};

export const getQuestionDefaultAnswer = (
  questionKind: QuestionKind,
  options: IdValuePair[]
): string => {
  switch (questionKind) {
    case QuestionKind.Text:
      return '';
    case QuestionKind.Boolean:
      return BooleanDefault.value;
    case QuestionKind.SingleOption:
      return String(options?.[0]?.id ?? '');
    case QuestionKind.MultiSelect:
      return '';
    case QuestionKind.Number:
      return '0';
    case QuestionKind.Date:
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      return date.toDateString();
    default:
      return '';
  }
};

export const resourceTypeToName = (type: ResourceType): string => {
  switch (type) {
    case ResourceType.Client:
      return 'client';
    case ResourceType.Service:
      return 'service';
    default:
      return '';
  }
};

export const roleToName = (role: RoleType): string => {
  switch (role) {
    case RoleType.Advocate:
      return 'Advocate';
    case RoleType.OrganizationAdmin:
      return 'Organization Admin';
    case RoleType.ServiceContributor:
      return 'Service Contributor';
    case RoleType.SystemAdmin:
      return 'System Admin';
    default:
      return '';
  }
};

export const roleToDescription = (role: RoleType): string => {
  const name = roleToName(role);

  switch (role) {
    case RoleType.Advocate:
      return `${plural(name)} can manage clients and create referrals`;
    case RoleType.OrganizationAdmin:
      return `${plural(
        name
      )} can manage services, clients, and users for your organization`;
    case RoleType.ServiceContributor:
      return `${plural(
        name
      )} can manage services and availability for your organization`;
    case RoleType.SystemAdmin:
      return `${plural(
        name
      )} can manage all organizations that are part of TIRA`;
    default:
      return '';
  }
};

export const referralStatusToName = (type: ReferralStatus): string => {
  switch (type) {
    case ReferralStatus.Pending:
      return 'Pending Provider Review';
    case ReferralStatus.AcceptedByProvider:
      return 'Offered by Provider';
    case ReferralStatus.WaitlistedByProvider:
      return 'Waitlisted by Provider';
    case ReferralStatus.DeclinedByProvider:
      return 'Declined by Provider';
    case ReferralStatus.AcceptedByClient:
      return 'Accepted by Client';
    case ReferralStatus.Placed:
      return 'Placed in Service';
    case ReferralStatus.CanceledByClient:
      return 'Canceled by Client';
    case ReferralStatus.CanceledByProvider:
      return 'Canceled by Provider';
    default:
      return 'Status Unknown';
  }
};

export const referralStatusToAdvocateDescription = (
  type: ReferralStatus
): string => {
  switch (type) {
    case ReferralStatus.Pending:
      return 'The next step in the process is for the provider to review the referral and accept, decline, or waitlist it. You will notified of their decision';
    case ReferralStatus.AcceptedByProvider:
      return 'The next step in the process is for you to accept or decline the referral. The provider will be notified of their decision';
    case ReferralStatus.WaitlistedByProvider:
      return 'The next step in the process is for the provider to accept or decline the referral. You will notified of their decision';
    case ReferralStatus.DeclinedByProvider:
      return 'This referral is no longer active and can be archived';
    case ReferralStatus.AcceptedByClient:
      return 'The client has accepted the referral. Once the client is placed in the service the provider can mark the referral as Placed';
    case ReferralStatus.Placed:
      return 'The provider has indicated that the client has been placed in the service and the referral is complete';
    case ReferralStatus.CanceledByClient:
      return 'You have canceled the referral and it is no longer active';
    case ReferralStatus.CanceledByProvider:
      return 'The provider has canceled the referral and it is no longer active';
    default:
      return 'Status Unknown';
  }
};

export const referralStatusToProviderDescription = (
  type: ReferralStatus
): string => {
  switch (type) {
    case ReferralStatus.Pending:
      return 'The next step in the process is for you to review the referral and accept, decline, or waitlist it. The client will notified of your decision';
    case ReferralStatus.AcceptedByProvider:
      return 'The next step in the process is for the client to accept or decline the referral. You will be notified of their decision';
    case ReferralStatus.WaitlistedByProvider:
      return 'The next step in the process is for you to accept or decline the referral. The client will notified of your decision';
    case ReferralStatus.DeclinedByProvider:
      return 'This referral is no longer active and can be archived';
    case ReferralStatus.AcceptedByClient:
      return 'This referral has been mutually accepted by the client. Once the client is placed in the service you can mark the referral as Placed. If they are not placed in the service you can mark the referral as Canceled';
    case ReferralStatus.Placed:
      return 'The client has been placed in the service and the referral is complete';
    case ReferralStatus.CanceledByClient:
      return 'The client has canceled the referral and it is no longer active';
    case ReferralStatus.CanceledByProvider:
      return 'You have canceled the referral and it is no longer active';
    default:
      return 'Status Unknown';
  }
};
