import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { PrimaryButton } from 'src/components/primaryButton';
import { TiraBrand } from 'src/components/tiraBrand';
import { Routes } from 'src/utils/routes';
import styles from './brandLayout.module.scss';

interface Props {
  title?: string | JSX.Element;
  content?: string | JSX.Element;
  showHomeButton?: boolean;
}

export const BrandLayout = (props: Props): JSX.Element => {
  const history = useHistory();

  return (
    <Container fluid className={styles.appBackground}>
      <div className={styles.header}>
        <div
          className={`${styles.clickable} mb-5`}
          onClick={(): void => history.push(Routes.HomePath)}
        >
          <TiraBrand fontSize={60} />
        </div>
      </div>
      <div className={styles.content}>
        <div className="w-100">
          {props.title && (
            <Row>
              <Col xs={{ offset: 1, span: 10 }} md={{ offset: 2, span: 8 }}>
                <h4 className="text-center">{props.title}</h4>
              </Col>
            </Row>
          )}
          {props.showHomeButton && (
            <Row className="mt-2">
              <Col xs={{ offset: 3, span: 6 }} md={{ offset: 5, span: 2 }}>
                <PrimaryButton
                  content="Home"
                  size="lg"
                  onClick={(): void => history.push(Routes.HomePath)}
                />
              </Col>
            </Row>
          )}
          {props.content && (
            <Row className="mt-5">
              <Col>{props.content}</Col>
            </Row>
          )}
        </div>
      </div>
    </Container>
  );
};
