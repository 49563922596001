import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import { LoadingSpinner } from 'src/components/loadingSpinner';
import styles from './registrationHeader.module.scss';

interface Props {
  title: string;
  description: string;
  isPageLoading?: boolean;
}

export const RegistrationHeader = (props: Props): JSX.Element => {
  return (
    <Container>
      <Row>
        <Col>
          <Jumbotron fluid className="pt-4 pb-1 bg-transparent">
            <Container className="text-center">
              <h1>{props.title}</h1>
              <Row>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
                  <div className={styles.line} />
                </Col>
              </Row>
              <Row>
                {props.isPageLoading ? (
                  <Col>
                    <LoadingSpinner />
                  </Col>
                ) : (
                  <Col xs={12} lg={{ span: 8, offset: 2 }}>
                    <p>{props.description}</p>
                  </Col>
                )}
              </Row>
            </Container>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
};
