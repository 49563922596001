import { FormEvent, useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { BlockNavigation } from 'src/components/blockNavigation';
import { PageLoading } from 'src/components/pageLoading';
import { PrimaryButton } from 'src/components/primaryButton';
import { OrganizationRegistrationStep1Inputs } from 'src/components/organizationRegistrationStep1Inputs';
import { OrganizationRegistrationStep2Inputs } from 'src/components/organizationRegistrationStep2Inputs';
import { IdValuePair } from 'src/models/idValuePair';
import {
  EmptyOrganizationDetails,
  OrganizationDetails,
} from 'src/models/organizationDetails';
import { useIdToken } from 'src/services/msal';
import {
  apiRoutes,
  authenticatedFetch,
  FetchMethod,
  useApi,
} from 'src/services/swr';
import {
  showErrorNotification,
  showInformationSavedNotification,
} from 'src/utils/helpers';
import styles from './organizationLayout.module.scss';

// The details route is passed in because this component is shared between
// organizations and admins. The backend routes need to be kept separate for
// security, so the route gets provided by the parent component.
interface Props {
  detailsRoute?: string;
  hideName?: boolean;
  onNameChanged: (name: string) => void;
}

export const OrganizationLayout = ({
  detailsRoute,
  hideName,
  onNameChanged,
}: Props): JSX.Element => {
  const { token } = useIdToken();

  const {
    data: providerDetails,
    isValidating: isLoadingDetails,
    mutate: getProviderDetails,
  } = useApi<OrganizationDetails>(detailsRoute);

  const { isValidating: isLoadingNonprofitStatuses } = useApi<IdValuePair[]>(
    apiRoutes.organizationRegistrationStep1.nonProfitStatuses
  );

  const { isValidating: isLoadingPrimaryPurposes } = useApi<IdValuePair[]>(
    apiRoutes.organizationRegistrationStep1.primaryPurposes
  );

  const { isValidating: isLoadingAffiliationsOrLicenses } = useApi<
    IdValuePair[]
  >(apiRoutes.organizationRegistrationStep1.affiliationOrLicenses);

  const { isValidating: isLoadingTraits } = useApi<IdValuePair[]>(
    apiRoutes.organizationRegistrationStep2.traits
  );

  const [currentProviderDetails, setCurrentProviderDetails] = useState(
    EmptyOrganizationDetails
  );

  const [needsSave, setNeedsSave] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingComplete, setIsSavingComplete] = useState(false);

  const isLoading =
    isLoadingDetails ||
    isLoadingNonprofitStatuses ||
    isLoadingPrimaryPurposes ||
    isLoadingAffiliationsOrLicenses ||
    isLoadingTraits;

  const saveDetails = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (isSaving || !detailsRoute) {
      return;
    }

    if (e.currentTarget.checkValidity()) {
      setIsValidated(true);
      setIsSaving(true);

      try {
        await authenticatedFetch(
          detailsRoute,
          FetchMethod.POST,
          token,
          JSON.stringify(currentProviderDetails)
        );

        setIsSavingComplete(true);

        if (currentProviderDetails.step1.name !== providerDetails?.step1.name) {
          onNameChanged(currentProviderDetails.step1.name || '');
        }

        // Fetch updated data
        await getProviderDetails();

        setNeedsSave(false);
        setIsValidated(false);
        setIsSaving(false);
        setIsSavingComplete(false);

        showInformationSavedNotification();
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsSaving(false);
      }
    }
  };

  useEffect(() => {
    // Set the current state when the details load.
    setCurrentProviderDetails(providerDetails ?? EmptyOrganizationDetails);
  }, [providerDetails]);

  return (
    <BlockNavigation needsSave={needsSave}>
      {!hideName && (
        <Row className={styles.header}>
          <Col className="d-flex justify-content-center align-items-center">
            <div>
              <div className={`${styles.pageTitle} d-flex align-items-center`}>
                {isLoading && !providerDetails ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <PageLoading showIcon={false} />
                  </div>
                ) : (
                  <span>{providerDetails?.step1.name}</span>
                )}
              </div>
              <div className={styles.line} />
            </div>
          </Col>
        </Row>
      )}

      {providerDetails && (
        <Row className="m-3 py-3">
          <Col>
            <div>
              <Form validated={isValidated} onSubmit={saveDetails}>
                <Form.Row>
                  <OrganizationRegistrationStep1Inputs
                    state={currentProviderDetails.step1}
                    onStateChanged={(newStep1): void => {
                      setCurrentProviderDetails({
                        ...currentProviderDetails,
                        step1: newStep1,
                      });
                      setNeedsSave(true);
                    }}
                    disableInputs={isSaving}
                  />
                  <OrganizationRegistrationStep2Inputs
                    state={currentProviderDetails.step2}
                    onStateChanged={(newStep2): void => {
                      setCurrentProviderDetails({
                        ...currentProviderDetails,
                        step2: newStep2,
                      });
                      setNeedsSave(true);
                    }}
                    disableInputs={isSaving}
                  />
                </Form.Row>

                {needsSave && (
                  <Form.Row className="my-2">
                    <Col
                      xs={12}
                      sm={{
                        span: 2,
                        offset: 10,
                      }}
                    >
                      <PrimaryButton
                        content="Save"
                        isLoading={isSaving}
                        isComplete={isSavingComplete}
                      />
                    </Col>
                  </Form.Row>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      )}
    </BlockNavigation>
  );
};
