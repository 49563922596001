import styles from './privacyPolicyLink.module.scss';
import { PrivacyLink } from 'src/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

export const PrivacyPolicyLink = (): JSX.Element => {
  return (
    <a
      className={styles.link}
      href={PrivacyLink}
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy&ensp;
      <FontAwesomeIcon icon={faExternalLink} />
    </a>
  );
};
