import { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { CustomTooltip } from 'src/components/customTooltip';
import { SingleDatePicker } from 'src/components/singleDatePicker';
import { IdValuePair } from 'src/models/idValuePair';
import { ServiceProfileViewModel } from 'src/models/resourceProfileViewModel';
import { ServiceType } from 'src/models/serviceType';
import { apiRoutes, useApi } from 'src/services/swr';
import { BooleanOptions, formatPhoneNumber, plural } from 'src/utils/helpers';

interface IProps {
  state: ServiceProfileViewModel;
  onStateChanged: (state: ServiceProfileViewModel) => void;
  isEditMode?: boolean;
  disableInputs?: boolean;
  readOnly?: boolean;
}

export const ServiceProfileInputs = ({
  state,
  onStateChanged,
  isEditMode,
  disableInputs,
  readOnly,
}: IProps): JSX.Element => {
  const { data: serviceTypes } = useApi<ServiceType[]>(
    apiRoutes.api.serviceTypes
  );

  const { data: serviceDurationUnits } = useApi<IdValuePair[]>(
    apiRoutes.api.serviceDurationUnits
  );

  const selectedServiceTypeUnit = (): string =>
    serviceTypes?.find((t) => t.id === state.type)?.unit ?? 'Slot';

  // Set default values after loading if needed.
  useEffect(() => {
    if (isEditMode) {
      return;
    }

    if (!state?.type && serviceTypes?.[0].id) {
      onStateChanged({
        ...state,
        type: serviceTypes?.[0].id,
      });
    }

    if (!state.durationUnits && serviceDurationUnits?.[0].id) {
      onStateChanged({
        ...state,
        durationUnits: serviceDurationUnits?.[0].id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTypes, serviceDurationUnits]);

  return (
    <>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formServiceType">
          <Form.Label className="font-weight-bold">Service Type</Form.Label>
          {readOnly ? (
            <p className="font-italic">
              {serviceTypes?.find((t) => t.id === state.type)?.name}
            </p>
          ) : (
            <Form.Control
              size="sm"
              as="select"
              value={state.type}
              onChange={(e): void =>
                onStateChanged({
                  ...state,
                  type: Number(e.target.value),
                })
              }
              disabled={disableInputs || isEditMode}
              required
            >
              {serviceTypes?.map((opt) => (
                <option key={opt.id} value={opt.id}>
                  {opt.name}
                </option>
              ))}
            </Form.Control>
          )}
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formServiceName">
          <Form.Label className="font-weight-bold">Service Name</Form.Label>
          {readOnly ? (
            <p className="font-italic">{state.name}</p>
          ) : (
            <Form.Control
              size="sm"
              type="text"
              placeholder="Service Name"
              value={state.name}
              onChange={(e): void =>
                onStateChanged({
                  ...state,
                  name: e.target.value,
                })
              }
              disabled={disableInputs}
              required
            />
          )}
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formServiceEmail">
          <Form.Label className="font-weight-bold">Service Email</Form.Label>
          {readOnly ? (
            <p className="font-italic">{state.emailAddress || 'N/A'}</p>
          ) : (
            <Form.Control
              size="sm"
              type="email"
              placeholder="Service Email (optional)"
              value={state.emailAddress}
              onChange={(e): void =>
                onStateChanged({
                  ...state,
                  emailAddress: e.target.value,
                })
              }
              disabled={disableInputs}
            />
          )}
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formServicePhoneNumber">
          <Form.Label className="font-weight-bold">Service Phone</Form.Label>
          {readOnly ? (
            <p className="font-italic">
              {formatPhoneNumber(state.phoneNumber) || 'N/A'}
            </p>
          ) : (
            <Form.Control
              size="sm"
              type="tel"
              pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
              title="Phone number must be 10 digits with area code"
              placeholder="Service Phone (optional)"
              value={formatPhoneNumber(state.phoneNumber || '')}
              onChange={(e): void =>
                onStateChanged({
                  ...state,
                  phoneNumber: formatPhoneNumber(e.target.value),
                })
              }
              disabled={disableInputs}
            />
          )}
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formAddress">
          <Form.Label className="font-weight-bold">
            Service Street Address
          </Form.Label>
          {readOnly ? (
            <p className="font-italic">{state.address}</p>
          ) : (
            <>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Address"
                value={state.address}
                onChange={(e): void =>
                  onStateChanged({
                    ...state,
                    address: e.target.value,
                  })
                }
                disabled={disableInputs || state.useOrganizationAddress}
                required={!state.useOrganizationAddress}
              />

              <div className="d-flex mt-2">
                <CustomTooltip
                  popup="If checked, this service will be registered with the same address as your organization headquarters"
                  content={
                    <Form.Check
                      id="useOrganizationAddress"
                      label="Use organization address"
                      checked={state.useOrganizationAddress}
                      onChange={(e): void =>
                        onStateChanged({
                          ...state,
                          useOrganizationAddress: e.target.checked,
                          address: e.target.checked ? '' : state.address,
                          zipCode: e.target.checked ? '' : state.zipCode,
                        })
                      }
                      disabled={disableInputs}
                    />
                  }
                />
                <CustomTooltip
                  popup="If checked, TIRA will keep this address hidden and will only use it for searching for services within a relevant distance"
                  content={
                    <Form.Check
                      id="dontDiscloseAddress"
                      className="ml-3"
                      label="Do not disclose address"
                      checked={state.isUndisclosedAddress}
                      onChange={(e): void =>
                        onStateChanged({
                          ...state,
                          isUndisclosedAddress: e.target.checked,
                        })
                      }
                      disabled={disableInputs}
                    />
                  }
                />
              </div>
            </>
          )}
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formZipCode">
          <Form.Label className="font-weight-bold">Service ZIP Code</Form.Label>
          {readOnly ? (
            <p className="font-italic">{state.zipCode}</p>
          ) : (
            <Form.Control
              size="sm"
              type="text"
              pattern="[0-9]{5}"
              maxLength={5}
              placeholder="Service ZIP Code"
              title="ZIP Code must be 5 digits"
              value={state.zipCode || ''}
              onChange={(e): void =>
                onStateChanged({
                  ...state,
                  zipCode: e.target.value,
                })
              }
              disabled={disableInputs || state.useOrganizationAddress}
              required={!state.useOrganizationAddress}
            />
          )}
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formTotalUnits">
          <Form.Label className="font-weight-bold">{`Total ${plural(
            selectedServiceTypeUnit()
          )}`}</Form.Label>
          {readOnly ? (
            <p className="font-italic">{state.totalUnits}</p>
          ) : (
            <Form.Control
              size="sm"
              type="number"
              min="0"
              placeholder={`Total ${plural(selectedServiceTypeUnit())}`}
              value={state.totalUnits}
              onChange={(e): void =>
                onStateChanged({
                  ...state,
                  totalUnits: Number(e.target.value),
                })
              }
              disabled={disableInputs}
              required
            />
          )}
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formServiceWaitlist">
          <Form.Label className="font-weight-bold">
            Does this service maintain a waitlist?
          </Form.Label>
          {readOnly ? (
            <p className="font-italic">
              {
                BooleanOptions.find((b) => Boolean(b.id) === state.hasWaitlist)
                  ?.value
              }
            </p>
          ) : (
            <Form.Control
              size="sm"
              as="select"
              value={Number(state.hasWaitlist) || undefined}
              onChange={(e): void =>
                onStateChanged({
                  ...state,
                  hasWaitlist: Boolean(Number(e.target.value)),
                })
              }
              disabled={disableInputs}
              required
            >
              {BooleanOptions.map((opt) => {
                return (
                  <option key={opt.id} value={opt.id}>
                    {opt.value}
                  </option>
                );
              })}
            </Form.Control>
          )}
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formBeginDate">
          <Form.Label className="font-weight-bold">
            When did, or will, you begin providing this service?
          </Form.Label>
          <SingleDatePicker
            date={state.startDate}
            showYearDropdown={true}
            readOnly={readOnly}
            disabled={disableInputs}
            size="sm"
            onChanged={(date): void =>
              onStateChanged({
                ...state,
                startDate: date,
              })
            }
          />
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formServiceTimeLimited">
          <Form.Label className="font-weight-bold">
            Is this service time limited?
          </Form.Label>
          {readOnly ? (
            <p className="font-italic">
              {
                BooleanOptions.find((b) => Boolean(b.id) === state.timeLimited)
                  ?.value
              }
            </p>
          ) : (
            <Form.Control
              size="sm"
              as="select"
              value={Number(state.timeLimited) || undefined}
              onChange={(e): void =>
                onStateChanged({
                  ...state,
                  timeLimited: Boolean(Number(e.target.value)),
                })
              }
              disabled={disableInputs}
              required
            >
              {BooleanOptions.map((opt) => {
                return (
                  <option key={opt.id} value={opt.id}>
                    {opt.value}
                  </option>
                );
              })}
            </Form.Control>
          )}
        </Form.Group>
      </Col>

      {state.timeLimited && (
        <>
          <Col xs={12} lg={6}>
            <Form.Group controlId="formEnrollTime">
              <Form.Label className="font-weight-bold">
                How long can a participant be enrolled in this service?
              </Form.Label>
              {readOnly ? (
                <p className="font-italic">
                  {`${state.duration} ${
                    serviceDurationUnits?.find(
                      (u) => u.id === state.durationUnits
                    )?.value
                  }`}
                </p>
              ) : (
                <>
                  <Form.Control
                    id="formEnrollTimeNum"
                    size="sm"
                    type="number"
                    min="0"
                    defaultValue="0"
                    value={state.duration}
                    onChange={(e): void =>
                      onStateChanged({
                        ...state,
                        duration: parseInt(e.target.value),
                      })
                    }
                    disabled={disableInputs}
                    required
                  />
                  <Form.Control
                    id="formEnrollTimeUnit"
                    size="sm"
                    as="select"
                    value={state.durationUnits}
                    onChange={(e): void =>
                      onStateChanged({
                        ...state,
                        durationUnits: parseInt(e.target.value),
                      })
                    }
                    disabled={disableInputs}
                    required
                  >
                    {serviceDurationUnits?.map((opt) => (
                      <option key={opt.id} value={opt.id}>
                        {opt.value}
                      </option>
                    ))}
                  </Form.Control>
                </>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formExtension">
              <Form.Label className="font-weight-bold">
                Does this service allow for extensions?
              </Form.Label>
              {readOnly ? (
                <p className="font-italic">
                  {
                    BooleanOptions.find(
                      (b) => Boolean(b.id) === state.allowExtensions
                    )?.value
                  }
                </p>
              ) : (
                <Form.Control
                  size="sm"
                  as="select"
                  value={Number(state.allowExtensions)}
                  onChange={(e): void =>
                    onStateChanged({
                      ...state,
                      allowExtensions: Boolean(Number(e.target.value)),
                    })
                  }
                  disabled={disableInputs}
                  required
                >
                  {BooleanOptions.map((opt) => {
                    return (
                      <option key={opt.id} value={opt.id}>
                        {opt.value}
                      </option>
                    );
                  })}
                </Form.Control>
              )}
            </Form.Group>
          </Col>
        </>
      )}

      <Col xs={12} lg={6}>
        <Form.Group controlId="formServiceDescription">
          <Form.Label className="font-weight-bold">
            Please provide a brief description of this service
          </Form.Label>
          {readOnly ? (
            <p className="font-italic">{state.description}</p>
          ) : (
            <Form.Control
              size="sm"
              type="text"
              placeholder="Description"
              as="textarea"
              rows={3}
              value={state.description}
              onChange={(e): void =>
                onStateChanged({
                  ...state,
                  description: e.target.value,
                })
              }
              disabled={disableInputs}
              required
            />
          )}
        </Form.Group>
      </Col>
    </>
  );
};
