export type OrganizationRegistrationStep2ViewModel = {
  traits: number[];
  description: string;
  religiousAffiliation: string;
  logo: string | null;
};

export const EmptyOrganizationRegistrationStep2ViewModel: OrganizationRegistrationStep2ViewModel =
  {
    traits: [],
    description: '',
    religiousAffiliation: '',
    logo: null,
  };
