import {
  faCalendar,
  faBuilding,
  faHome,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Routes } from 'src/utils/routes';
import { MenuOption } from './menuOption';

export const AdminMenuOptions: MenuOption[] = [
  {
    name: 'Home',
    icon: faHome,
    path: Routes.HomePath,
  },
  {
    name: 'Dashboard',
    icon: faCalendar,
    path: Routes.AdminDashboardPath,
  },
  {
    name: 'Organizations',
    icon: faBuilding,
    path: Routes.AdminOrganizationsPath,
  },
  {
    name: 'Logout',
    icon: faSignOutAlt,
  },
];
