export const cardContainer = 'shadow rounded p-0 mt-2 mb-2 bg-white';
export const cardHeader = 'mt-2';
export const cardHeaderText = 'h5 text-muted';
export const cardBodyWrapper = 'd-flex';
export const cardFooter = 'font-weight-light text-muted small';
export const cardInfo = 'm-2';
export const cardInfoTitle = 'text-muted mt-2';
export const cardInfoText = 'font-weight-light text-muted small';
export const cardIconWrapper =
  'bg-primary rounded w-100 h-100 p-0 d-flex align-items-center justify-content-center';

export const noWrap = 'text-truncate';
