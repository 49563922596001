import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
} from '@fluentui/react/lib/ComboBox';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { faCopy, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { CancelButton } from 'src/components/cancelButton';
import { CustomTooltip } from 'src/components/customTooltip';
import { DirectoryCard } from 'src/components/directoryCard';
import { FilterForm } from 'src/components/filterForm';
import { LoadingSpinner } from 'src/components/loadingSpinner';
import { PrimaryButton } from 'src/components/primaryButton';
import { Answer } from 'src/models/answer';
import { DirectorySearchResult } from 'src/models/directorySearchResult';
import { DirectorySearchViewModel } from 'src/models/directorySearchViewModel';
import { IdValuePair } from 'src/models/idValuePair';
import { QuestionFilter, QuestionFilterType } from 'src/models/questionFilter';
import { ServiceType } from 'src/models/serviceType';
import { apiRoutes, useApi } from 'src/services/swr';
import { states } from 'src/utils/constants';
import { UrlParamNames } from 'src/utils/routes';
import {
  Delimiter,
  pluralize,
  showSuccessNotification,
} from 'src/utils/helpers';
import styles from './directoryLayout.module.scss';
import { SelectableOptionMenuItemType } from '@fluentui/react';

interface Props {
  scopedFilters?: QuestionFilterType[];
  showAvailability: boolean;
  showReferral: boolean;
  canMakeReferral: boolean;
  onResultClick?: (result: DirectorySearchResult) => void;
  onReferralClick?: (result: DirectorySearchResult) => void;
}

export const DirectoryLayout = ({
  scopedFilters,
  showAvailability,
  showReferral,
  canMakeReferral,
  onResultClick,
  onReferralClick,
}: Props): JSX.Element => {
  const history = useHistory();
  const { search } = useLocation();

  const [showFilters, setShowFilters] = useState(false);
  const [filterAnswers, setFilterAnswers] = useState<Answer[]>([]);

  const [searchState, setSearchState] = useState<DirectorySearchViewModel>({
    states: [],
    filters: [],
  });

  const serviceTypes = useApi<ServiceType[]>(apiRoutes.api.serviceTypes);
  const filters = useApi<QuestionFilter[]>(apiRoutes.directory.filters);

  const clientFilters = useApi<IdValuePair[]>(
    searchState.clientId
      ? apiRoutes.client.filters(searchState.clientId)
      : undefined
  );

  const services = useApi<DirectorySearchResult[]>(
    searchState.serviceType && searchState.states?.length > 0
      ? apiRoutes.directory.search(searchState)
      : undefined
  );

  const stateOptions: IComboBoxOption[] = [
    {
      key: 'selectAll',
      text: 'Select All',
      itemType: SelectableOptionMenuItemType.SelectAll,
    },
  ].concat(
    states.map(({ abbreviation, name }) => ({
      key: abbreviation,
      text: name,
      itemType: SelectableOptionMenuItemType.Normal,
    }))
  );

  const stateKeys = (): string[] => {
    return searchState.states.length === states.length
      ? ['selectAll'].concat(searchState.states)
      : searchState.states;
  };

  const onStateSelect = (
    _e: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    if (option) {
      if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
        let newStates: string[] = [];
        if (searchState.states.length !== states.length) {
          newStates = states.map((state) => state.abbreviation);
        }
        selectTheseStates(newStates);
        setSearchState({
          ...searchState,
          states: newStates,
        });
      } else {
        let selectedStates = [...searchState.states];
        const stateAlreadySelected = selectedStates.find(
          (state) => state === option?.key
        );

        if (stateAlreadySelected) {
          selectedStates = selectedStates.filter(
            (state) => state !== option?.key
          );
        } else {
          selectedStates.push(option?.key as string);
        }

        selectTheseStates(selectedStates);
      }
    }
  };

  // Selects the states as provided in the string array of state abbreviations.
  // State abbreviations must be two-letter and capitalized.
  const selectTheseStates = (selectedStates: string[]): void => {
    const urlParams = new URLSearchParams(search);

    if (selectedStates.length > 0) {
      urlParams.set(
        UrlParamNames.DirectoryServiceLocationParamName,
        selectedStates.join(Delimiter)
      );
    } else {
      urlParams.delete(UrlParamNames.DirectoryServiceLocationParamName);
    }

    history.push(window.location.pathname + '?' + urlParams.toString());
  };

  const onServiceTypeChanged = (
    _e: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ): void => {
    const urlParams = new URLSearchParams(search);
    urlParams.set(
      UrlParamNames.DirectoryServiceTypeParamName,
      option?.key?.toString() ?? ''
    );
    history.push(window.location.pathname + '?' + urlParams.toString());
  };

  const setFilters = (newAnswers: Answer[], replaceUrl = false): void => {
    // Remove any empty answers.
    newAnswers = newAnswers.filter((answer) => answer.value !== '');

    // Clear URL params for previous filters.
    const urlParams = new URLSearchParams(search);
    filterAnswers.forEach((f) => urlParams.delete(f.questionId.toString()));

    // Set the new filters.
    setFilterAnswers(newAnswers);

    // Set URL params for new filters.
    newAnswers.forEach((f) => urlParams.set(f.questionId.toString(), f.value));

    // Indicate that filters have been edited.
    urlParams.set(UrlParamNames.DirectoryFiltersApplied, 'true');

    // Set the new location.
    const newLocation = window.location.pathname + '?' + urlParams.toString();

    if (replaceUrl) {
      history.replace(newLocation);
    } else {
      history.push(newLocation);
    }
  };

  const onCopyResultEmailAddresses = useCallback((): void => {
    if (services.data?.length) {
      const results = services.data.map((service) => {
        return service.emailAddress || service.serviceProvider.emailAddress;
      });

      // Make sure the results are unique. There are various ways to do it but a conversion to set looks to be fastest.
      // https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
      navigator.clipboard.writeText([...new Set(results)].join('; '));
      showSuccessNotification('Copied email addresses to clipboard!');
    }
  }, [services]);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const serviceTypeParam = urlParams.get(
      UrlParamNames.DirectoryServiceTypeParamName
    );

    // Set the service type if not already.
    if (!serviceTypeParam && serviceTypes.data) {
      urlParams.set(
        UrlParamNames.DirectoryServiceTypeParamName,
        serviceTypes.data[0].id.toString()
      );

      // Use history.replace because we're changing the route silently which means that if the
      // user clicks Back they'll still be on the same page and stuck in this service type init loop.
      history.replace(window.location.pathname + '?' + urlParams.toString());
    }
  }, [serviceTypes, search, history]);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const filtersAppliedParam = urlParams.get(
      UrlParamNames.DirectoryFiltersApplied
    );

    // Only set filters if none have already been set, that way
    // reloading the page doesn't override filters that have been modified.
    if (!filtersAppliedParam && clientFilters.data?.length) {
      setFilters(
        clientFilters.data.map((f) => ({ questionId: f.id, value: f.value })),
        true
      );

      showSuccessNotification('Search filters updated for client profile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFilters.data]);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    let clientIdParam = '';
    let serviceTypeParam = '';
    let locationParam = '';
    const filterParams: Answer[] = [];

    urlParams.forEach((value: string, key: string) => {
      switch (key) {
        case UrlParamNames.ClientIdUrlParamName:
          clientIdParam = value;
          break;
        case UrlParamNames.DirectoryServiceTypeParamName:
          serviceTypeParam = value;
          break;
        case UrlParamNames.DirectoryServiceLocationParamName:
          locationParam = value;
          break;
        default:
          {
            // Assume any non-named param is a filter, which is based on the ID of the filter.
            const id = Number(key);
            if (!isNaN(id)) {
              filterParams.push({ questionId: id, value: value });
            }
          }
          break;
      }
    });

    // Set the filters.
    setFilterAnswers(filterParams);

    // Set the search state.
    setSearchState({
      ...searchState,
      clientId: Number(clientIdParam ?? searchState.clientId),
      serviceType: Number(serviceTypeParam ?? searchState.serviceType),
      states: locationParam
        ? locationParam.split(Delimiter)
        : searchState.states,
      filters: filterParams.length
        ? filterParams.map((a) => ({ id: a.questionId, value: a.value }))
        : [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <Row>
        <Col xs={12} md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
          <Form
            className={styles.formWrapper}
            onSubmit={(e): void => {
              e.preventDefault();
              services.mutate();
            }}
          >
            <Form.Row>
              <Col xs={12} sm={6} className={styles.formCol}>
                <Dropdown
                  id="formServiceType"
                  title="Select service type"
                  aria-label="Select service type"
                  styles={{
                    root: {
                      fontFamily: 'inherit',
                    },
                  }}
                  options={
                    serviceTypes.data?.map(({ id, name }) => ({
                      key: id,
                      text: name,
                    })) ?? []
                  }
                  onChange={onServiceTypeChanged}
                  defaultSelectedKey={searchState.serviceType}
                  disabled={services.isValidating || !serviceTypes.data}
                />
              </Col>
              <Col
                xs={services.data?.length ? 12 : 10}
                sm={services.data?.length ? 4 : 5}
                className={styles.formCol}
              >
                <ComboBox
                  id="formStates"
                  styles={{
                    root: {
                      fontFamily: 'inherit',
                    },
                    callout: { maxHeight: 200 },
                  }}
                  placeholder="Select state(s)"
                  title="Select state(s)"
                  options={stateOptions}
                  selectedKey={stateKeys()}
                  disabled={services.isValidating}
                  multiSelect
                  scrollSelectedToTop
                  calloutProps={{ hidden: true }}
                  onChange={onStateSelect}
                />
              </Col>

              <Col
                xs={services.data?.length ? 6 : 2}
                sm={1}
                className={styles.formCol}
              >
                <div className="d-flex">
                  <CustomTooltip
                    popup="Filter"
                    content={
                      <Button
                        variant="primary"
                        aria-label="Filter"
                        onClick={(): void => setShowFilters(true)}
                        size={'sm'}
                        className="w-100"
                      >
                        <FontAwesomeIcon icon={faFilter} />
                      </Button>
                    }
                  />
                  {filterAnswers.length > 0 && (
                    <div className={styles.filterCountWrapper}>
                      <div id="filterCount" className={styles.filterCount}>
                        {filterAnswers.length}
                      </div>
                    </div>
                  )}
                </div>
              </Col>

              {services.data?.length ? (
                <Col xs={6} sm={1} className={styles.formCol}>
                  <CustomTooltip
                    popup="Copy result email addresses"
                    content={
                      <Button
                        variant="light"
                        aria-label="Copy result email addresses"
                        onClick={(): void => onCopyResultEmailAddresses()}
                        size={'sm'}
                        className="w-100"
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    }
                  />
                </Col>
              ) : (
                <></>
              )}
            </Form.Row>
          </Form>
        </Col>
      </Row>

      <Row className="mt-3">
        {services.isValidating ? (
          <Col className="d-flex justify-content-center">
            <LoadingSpinner />
          </Col>
        ) : services.data?.length ? (
          services.data.map((service) => (
            <Col xs={12} sm={6} key={service.id}>
              <DirectoryCard
                service={service}
                serviceTypeName={
                  serviceTypes.data?.find(
                    (s) => s.id === searchState.serviceType
                  )?.name || ''
                }
                showAvailability={showAvailability}
                showReferral={showReferral}
                canMakeReferral={canMakeReferral}
                onResultClick={
                  onResultClick ? (): void => onResultClick(service) : undefined
                }
                onReferralClick={
                  onReferralClick
                    ? (): void => onReferralClick(service)
                    : undefined
                }
              />
            </Col>
          ))
        ) : (
          <Col
            xs={{ offset: 2, span: 8 }}
            md={{ offset: 3, span: 6 }}
            lg={{ offset: 4, span: 4 }}
            className="d-flex justify-content-center shadow rounded bg-white px-5 py-1 text-center text-small"
          >
            <small>
              {services.data ? (
                <>
                  <div>No matching services found.</div>
                  {filterAnswers.length > 0 && (
                    <div>Try changing search filters for more results.</div>
                  )}
                </>
              ) : (
                <>Select options to search services</>
              )}
            </small>
          </Col>
        )}
      </Row>

      <Modal
        centered
        scrollable={true}
        show={showFilters}
        onHide={(): void => setShowFilters(false)}
      >
        <Modal.Header closeButton closeLabel="close">
          <b>Filters (all fields are optional)</b>
        </Modal.Header>
        <Modal.Body>
          <FilterForm
            filters={filters.data}
            scopedFilters={scopedFilters}
            answers={filterAnswers}
            onAnswersUpdated={setFilters}
          />
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton
            content={`Show ${pluralize('result', services.data?.length ?? 0)}`}
            size={'sm'}
            isLoading={services.isValidating}
            isDisabled={services.isValidating || !services.data}
            onClick={(): void => setShowFilters(false)}
          />
          <CancelButton
            content={
              filterAnswers.length === 0
                ? 'Clear filters'
                : `Clear ${pluralize('filter', filterAnswers.length)}`
            }
            size={'sm'}
            isDisabled={filterAnswers.length === 0}
            onClick={(): void => setFilters([])}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
