import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { LoadingSpinner } from 'src/components/loadingSpinner';
import styles from './primaryButton.module.scss';

interface Props {
  content: string | JSX.Element;
  form?: string;
  id?: string;
  className?: string;
  size?: 'sm' | 'lg';
  noBlock?: boolean;
  isLoading?: boolean;
  isComplete?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const PrimaryButton = (props: Props): JSX.Element => {
  const onClickLocal = (e: React.MouseEvent): void => {
    // Prevent bubbling up if the button is inside another element that handles clicks.
    e.stopPropagation();
    props.onClick?.();
  };

  return (
    <Button
      variant="primary"
      id={props.id || ''}
      className={`${styles.button} ${props.className || ''}`}
      onClick={onClickLocal}
      type="submit"
      disabled={props.isDisabled || props.isLoading}
      size={props.size ?? 'sm'}
      form={props.form || undefined}
      block={!props.noBlock}
    >
      {props.isComplete ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : props.isLoading ? (
        <LoadingSpinner />
      ) : (
        props.content
      )}
    </Button>
  );
};
