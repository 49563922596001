import { FormEvent, useEffect, useState } from 'react';
import { Col, Container, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { CancelButton } from 'src/components/cancelButton';
import { DynamicForm } from 'src/components/dynamicForm';
import { PrimaryButton } from 'src/components/primaryButton';
import { RegistrationHeader } from 'src/components/registrationHeader';
import { Answer } from 'src/models/answer';
import { QuestionPage } from 'src/models/questionPage';
import { RegistrationProgress } from 'src/models/registrationProgress';
import { Client, Service } from 'src/models/resource';
import { useIdToken } from 'src/services/msal';
import { authenticatedFetch, FetchMethod, useApi } from 'src/services/swr';
import {
  debounce,
  getQuestionDefaultAnswer,
  showErrorNotification,
  showInformationSavedNotification,
} from 'src/utils/helpers';

interface Props {
  title: string;
  resourcesRoute: string;
  progressRoute: string;
  questionPageCountRoute: string;
  questionPageRoute: (progress: RegistrationProgress) => string;
  submitQuestionPageRoute: (progress: RegistrationProgress) => string;
  deleteRoute: string;
  resourcesPageUrl: string;
  resourceUrlParamName: string;
  onCancel: () => void;
}

export const DynamicRegistrationSteps = (props: Props): JSX.Element => {
  const resources = useApi<Client[] | Service[]>(props.resourcesRoute);

  const {
    data: progressData,
    isValidating: isLoadingRegistration,
    mutate: getProgressData,
  } = useApi<RegistrationProgress>(
    props.progressRoute,
    // Always fetch the latest progress
    { dedupingInterval: 0 }
  );
  const { data: questionPageCount, isValidating: isLoadingQuestionPageCount } =
    useApi<number>(progressData ? props.questionPageCountRoute : undefined);
  const {
    data: questionPage,
    isValidating: isLoadingQuestionPage,
    mutate: getQuestionPage,
  } = useApi<QuestionPage>(
    progressData ? props.questionPageRoute(progressData) : undefined
  );

  const history = useHistory();
  const { token } = useIdToken();

  const [answers, setAnswers] = useState<Answer[]>([]);

  const [isValidated, setIsValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingComplete, setIsSubmittingComplete] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isCancelingComplete, setIsCancelingComplete] = useState(false);

  useEffect(() => {
    if (questionPage) {
      const defaultAnswers = questionPage.questions.map((question) => {
        const answer = answers.find((a) => a.questionId === question.id);
        return answer
          ? answer
          : ({
              questionId: question.id,
              value: getQuestionDefaultAnswer(
                question.questionKind,
                question.options
              ),
            } as Answer);
      });

      setAnswers(defaultAnswers);
    }
    // Don't add answers as a dependency. This shouldn't run when the
    // answers change otherwise it is a circular dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionPage, getQuestionDefaultAnswer]);

  const handleCancel = async (): Promise<void> => {
    if (progressData) {
      setIsCanceling(true);

      try {
        await authenticatedFetch(props.deleteRoute, FetchMethod.DELETE, token);
        setIsCancelingComplete(true);
        props.onCancel();
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsCanceling(false);
      }
    }
  };

  const submitQuestionPage = async (): Promise<void> => {
    setIsSubmitting(true);

    if (progressData && questionPageCount) {
      try {
        await authenticatedFetch<QuestionPage>(
          props.submitQuestionPageRoute(progressData),
          FetchMethod.POST,
          token,
          JSON.stringify({ answers })
        );

        if (progressData.page + 1 > questionPageCount) {
          // Last page is complete
          setIsSubmittingComplete(true);
          await debounce();
          resources.mutate();

          history.push(
            `${props.resourcesPageUrl}?${props.resourceUrlParamName}=${progressData.id}`
          );
        } else {
          // Proceed to next page
          await getProgressData();
          await getNextQuestionPage();
        }

        showInformationSavedNotification();
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsSubmitting(false);
      }
    }
  };

  const getNextQuestionPage = async (): Promise<void> => {
    setIsValidated(false);
    try {
      await getQuestionPage();
      setIsSubmittingComplete(false);
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (isSubmitting) {
      return;
    }

    if (e.currentTarget.checkValidity()) {
      setIsValidated(true);
      await submitQuestionPage();
    }
  };

  return (
    <>
      {/* Add 1 to the numbers because Step1 was the first step*/}
      <RegistrationHeader
        title={props.title}
        description={`${questionPage?.title} (Step ${
          (progressData?.page ?? 0) + 1
        } of ${(questionPageCount ?? 1) + 1})`}
        isPageLoading={
          isLoadingRegistration ||
          isLoadingQuestionPageCount ||
          isLoadingQuestionPage
        }
      />
      <Container>
        <Form validated={isValidated} onSubmit={handleSubmit}>
          {questionPage?.questions && (
            <>
              <DynamicForm
                questions={questionPage.questions}
                answers={answers}
                onAnswersUpdated={(newAnswers): void => setAnswers(newAnswers)}
                isDisabled={isSubmitting}
              />
              <Form.Row>
                <Col xs={12} sm={{ span: 2, offset: 8 }} className="my-2">
                  <CancelButton
                    content="Cancel"
                    shouldConfirm={true}
                    isDisabled={isSubmitting}
                    isLoading={isCanceling}
                    isComplete={isCancelingComplete}
                    onClick={handleCancel}
                  />
                </Col>
                <Col xs={12} sm={{ span: 2 }} className="my-2">
                  <PrimaryButton
                    content="Next"
                    isDisabled={isCanceling}
                    isLoading={isSubmitting}
                    isComplete={isSubmittingComplete}
                  />
                </Col>
              </Form.Row>
            </>
          )}
        </Form>
      </Container>
    </>
  );
};
