import { BrandLayout } from 'src/components/brandLayout';
import { DirectoryLayout } from 'src/components/directoryLayout';
import { QuestionFilterType } from 'src/models/questionFilter';

const availableFilters = [QuestionFilterType.Age, QuestionFilterType.Gender];

export const PublicDirectory = (): JSX.Element => {
  return (
    <BrandLayout
      content={
        <DirectoryLayout
          scopedFilters={availableFilters}
          showAvailability={false}
          showReferral={false}
          canMakeReferral={false}
        />
      }
    />
  );
};
