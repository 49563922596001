import { FormEvent } from 'react';
import { Col, Form } from 'react-bootstrap';
import { CustomTooltip } from 'src/components/customTooltip';
import { IdValuePair } from 'src/models/idValuePair';
import { OrganizationRegistrationStep1ViewModel } from 'src/models/organizationRegistrationStep1ViewModel';
import { apiRoutes, useApi } from 'src/services/swr';
import { formatPhoneNumber } from 'src/utils/helpers';
import { SelectAllButton } from 'src/components/SelectAllButton';
import { AffiliationOrLicenseType } from 'src/models/affiliation';

interface IProps {
  state: OrganizationRegistrationStep1ViewModel;
  onStateChanged: (state: OrganizationRegistrationStep1ViewModel) => void;
  disableInputs: boolean;
}

export const OrganizationRegistrationStep1Inputs = ({
  state,
  onStateChanged,
  disableInputs,
}: IProps): JSX.Element => {
  const { data: nonProfitStatuses } = useApi<IdValuePair[]>(
    apiRoutes.organizationRegistrationStep1.nonProfitStatuses
  );
  const { data: primaryPurposes } = useApi<IdValuePair[]>(
    apiRoutes.organizationRegistrationStep1.primaryPurposes
  );
  const { data: affiliationOrLicenses } = useApi<IdValuePair[]>(
    apiRoutes.organizationRegistrationStep1.affiliationOrLicenses
  );

  const onAffiliationOrLicenseChanged = (
    e: FormEvent<HTMLInputElement>
  ): void => {
    const checkbox = e.currentTarget;
    const optionId = Number(checkbox.value);

    // Add to the array if the ID isn't there, otherwise remove.
    const newAffiliations = state.affiliationOrLicense?.includes(optionId)
      ? state.affiliationOrLicense.filter((x) => x !== optionId)
      : [...(state.affiliationOrLicense || []), optionId];

    onStateChanged({
      ...state,
      affiliationOrLicense: newAffiliations,
    });
  };

  return (
    <>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formOrganizationName">
          <Form.Label className="font-weight-bold">
            Organization Name
          </Form.Label>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Name"
            value={state.name}
            onChange={(e): void =>
              onStateChanged({
                ...state,
                name: e.target.value,
              })
            }
            disabled={disableInputs}
            required
          />
        </Form.Group>
      </Col>
      <Col xs={12} lg={4}>
        <Form.Group controlId="formOrganizationEmail">
          <Form.Label className="font-weight-bold">Email</Form.Label>
          <Form.Control
            size="sm"
            type="email"
            placeholder="Email"
            value={state.email}
            onChange={(e): void =>
              onStateChanged({
                ...state,
                email: e.target.value,
              })
            }
            disabled={disableInputs}
            required
          />
        </Form.Group>
      </Col>
      <Col xs={12} lg={2}>
        <Form.Group controlId="formOrganizationPhoneNumber">
          <Form.Label className="font-weight-bold">Phone</Form.Label>
          <Form.Control
            size="sm"
            type="tel"
            pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
            title="Phone number must be 10 digits with area code"
            placeholder="Phone"
            value={formatPhoneNumber(state.phoneNumber || '')}
            onChange={(e): void =>
              onStateChanged({
                ...state,
                phoneNumber: formatPhoneNumber(e.target.value),
              })
            }
            disabled={disableInputs}
            required
          />
        </Form.Group>
      </Col>
      <Col xs={12} lg={10}>
        <Form.Group controlId="formOrganizationAddress">
          <Form.Label className="font-weight-bold">Street Address</Form.Label>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Address"
            value={state.address}
            onChange={(e): void =>
              onStateChanged({
                ...state,
                address: e.target.value,
              })
            }
            disabled={disableInputs}
            required
          />

          <div className="d-flex mt-2">
            <CustomTooltip
              popup="If checked, TIRA will keep this address hidden and will only use it for searching for organizations within a relevant distance"
              content={
                <Form.Check
                  id="formOrganizationAddressCheck"
                  checked={state.isUndisclosedAddress}
                  label="Do not disclose address"
                  onChange={(e): void =>
                    onStateChanged({
                      ...state,
                      isUndisclosedAddress: e.target.checked,
                    })
                  }
                  disabled={disableInputs}
                />
              }
            />
          </div>
        </Form.Group>
      </Col>
      <Col xs={12} lg={2}>
        <Form.Group controlId="formOrganizationZipCode">
          <Form.Label className="font-weight-bold">ZIP Code</Form.Label>
          <Form.Control
            size="sm"
            type="text"
            pattern="[0-9]{5}"
            maxLength={5}
            placeholder="ZIP Code"
            title="ZIP Code must be 5 digits"
            value={state.zipCode || ''}
            onChange={(e): void =>
              onStateChanged({
                ...state,
                zipCode: e.target.value,
              })
            }
            disabled={disableInputs}
            required
          />
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formOrganizationWebsite">
          <Form.Label className="font-weight-bold">Website URL</Form.Label>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Website URL"
            value={state.websiteUrl}
            pattern={'https://.+[.][a-zA-Z]{2,}'}
            title="URL must begin with 'https://'"
            onChange={(e): void =>
              onStateChanged({
                ...state,
                websiteUrl: e.target.value,
              })
            }
            disabled={disableInputs}
            required
          />
        </Form.Group>
      </Col>
      <Col xs={12} sm={6} lg={4}>
        <Form.Group controlId="formOrganizationNonprofitStatus">
          <Form.Label className="font-weight-bold">501c3</Form.Label>
          <Form.Control
            size="sm"
            as="select"
            value={state.nonprofitStatus}
            onChange={(e): void =>
              onStateChanged({
                ...state,
                nonprofitStatus: Number(e.target.value),
              })
            }
            disabled={disableInputs}
          >
            {nonProfitStatuses?.map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col xs={12} sm={6} lg={2}>
        <Form.Group controlId="formOrganizationIncorporated">
          <Form.Label className="font-weight-bold">
            Year Incorporated
          </Form.Label>
          <Form.Control
            size="sm"
            type="number"
            min="1900"
            max={new Date().getFullYear()}
            placeholder="Year incorporated"
            value={state.incorporationYear}
            onChange={(e): void =>
              onStateChanged({
                ...state,
                incorporationYear: Number(e.target.value.slice(0, 4)),
              })
            }
            disabled={disableInputs}
            required
          />
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formOrganizationPrimaryPurpose">
          <Form.Label className="font-weight-bold">
            What is your organization&apos;s primary purpose?
          </Form.Label>
          <Form.Control
            size="sm"
            as="select"
            value={state.primaryPurpose}
            onChange={(e): void =>
              onStateChanged({
                ...state,
                primaryPurpose: Number(e.target.value),
              })
            }
            disabled={disableInputs}
            required
          >
            {primaryPurposes?.map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formOrganizationAffiliations">
          <Form.Label className="font-weight-bold">
            What are your organization&apos;s affiliations or licenses? (Select
            all that apply)
          </Form.Label>
          {affiliationOrLicenses?.map((opt) => (
            <Form.Check
              key={opt.id}
              id={`affiliation${opt.id}`}
              value={opt.id}
              label={opt.value}
              checked={state.affiliationOrLicense?.some((a) => a === opt.id)}
              onChange={onAffiliationOrLicenseChanged}
              disabled={disableInputs}
            />
          ))}

          <SelectAllButton
            allBoxIds={affiliationOrLicenses?.map((a) => a.id)}
            allAreSelected={(): boolean =>
              state.affiliationOrLicense?.length ===
              affiliationOrLicenses?.length
            }
            onChange={(newAffiliations: AffiliationOrLicenseType[]): void =>
              onStateChanged({
                ...state,
                affiliationOrLicense: newAffiliations,
              })
            }
            disableInputs={disableInputs}
          />
        </Form.Group>
      </Col>
    </>
  );
};
