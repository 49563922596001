import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuOption } from 'src/models/menuOption';
import styles from './menuSidebar.module.scss';
import { useMsal } from '@azure/msal-react';
import { PrivacyPolicyLink } from './privacyPolicyLink';

interface Props {
  menuOptions: MenuOption[];
  selectedIndex: number;
}

export const MenuSidebar = (props: Props): JSX.Element => {
  const history = useHistory();
  const { instance } = useMsal();

  const [hoverIndex, setHoverIndex] = useState(-1);

  const getOptionClassName = (index: number): string => {
    let style = styles.option;

    if (props.menuOptions[index].isDisabled) {
      style += ` ${styles.optionDisabled}`;
    } else if (index === props.selectedIndex) {
      style += ` ${styles.optionSelected} ${styles.optionHover}`;
    } else if (index === hoverIndex) {
      style += ` ${styles.optionHover}`;
    }

    return style;
  };

  const onClick = (index: number): void => {
    if (!props.menuOptions[index].isDisabled) {
      const path = props.menuOptions[index].path;
      if (path) {
        history.push(path);
        return;
      }
      switch (props.menuOptions[index].name) {
        case 'Logout':
          instance.logout();
          return;
        default:
          return;
      }
    }
  };

  return (
    <div
      className={`${styles.container} d-flex flex-column justify-content-around`}
    >
      <div className={styles.options}>
        {props.menuOptions.map((opt, index) => {
          return (
            <div
              key={index}
              className={getOptionClassName(index)}
              onClick={(): void => onClick(index)}
              onMouseEnter={(): void => setHoverIndex(index)}
              onMouseLeave={(): void => setHoverIndex(-1)}
            >
              <Row noGutters>
                <Col xs={2}>
                  <FontAwesomeIcon
                    icon={opt.icon}
                    className={styles.optionIcon}
                  />
                </Col>
                <Col xs={10}>{opt.name}</Col>
              </Row>
            </div>
          );
        })}
      </div>
      <div className={styles.privacyLink}>
        <PrivacyPolicyLink />
      </div>
    </div>
  );
};
