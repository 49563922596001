import {
  EmptyOrganizationRegistrationStep1ViewModel,
  OrganizationRegistrationStep1ViewModel,
} from './organizationRegistrationStep1ViewModel';
import {
  EmptyOrganizationRegistrationStep2ViewModel,
  OrganizationRegistrationStep2ViewModel,
} from './organizationRegistrationStep2ViewModel';

export type OrganizationDetails = {
  step1: OrganizationRegistrationStep1ViewModel;
  step2: OrganizationRegistrationStep2ViewModel;
};

export const EmptyOrganizationDetails: OrganizationDetails = {
  step1: EmptyOrganizationRegistrationStep1ViewModel,
  step2: EmptyOrganizationRegistrationStep2ViewModel,
};
