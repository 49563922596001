import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { Col, Row } from 'react-bootstrap';
import { CancelButton } from 'src/components/cancelButton';
import { CardLayout } from 'src/components/cardLayout';
import { LoadingSpinner } from 'src/components/loadingSpinner';
import { PrimaryButton } from 'src/components/primaryButton';
import { cardInfoTitle, noWrap } from 'src/utils/styles';
import styles from './referralStatsCardLayout.module.scss';

interface Props {
  icon: IconProp;
  iconSize?: SizeProp;
  headerRow: JSX.Element;
  contentRow: JSX.Element;
  grayscale?: boolean;
  onClick?: () => void;

  statsIsLoading: boolean;
  statsReadyCount: number;
  statsWaitingCount: number;
}

export const ReferralStatsCardLayout = ({
  icon,
  iconSize,
  headerRow,
  contentRow,
  grayscale,
  onClick,
  statsIsLoading,
  statsReadyCount,
  statsWaitingCount,
}: Props): JSX.Element => {
  return (
    <>
      <CardLayout
        icon={icon}
        iconSize={iconSize}
        headerRow={headerRow}
        grayscale={grayscale}
        onClick={onClick}
        contentRow={
          <>
            {contentRow}
            <Row>
              <Col xs={12}>
                <div className="d-flex flex-column">
                  <div className={`${cardInfoTitle} ${noWrap}`}>Referrals</div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <ReferralStatsStatus
                  isLoading={statsIsLoading}
                  statCount={statsReadyCount}
                  statDescriptor="ready"
                  onClick={onClick}
                />
              </Col>
              <Col xs={12} sm={6}>
                <ReferralStatsStatus
                  isLoading={statsIsLoading}
                  statCount={statsWaitingCount}
                  statDescriptor="pending"
                  onClick={onClick}
                />
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};

interface StatusProps {
  isLoading: boolean;
  statCount: number;
  statDescriptor: string;
  onClick?: () => void;
}

export const ReferralStatsStatus = ({
  isLoading,
  statCount,
  statDescriptor,
  onClick,
}: StatusProps): JSX.Element => {
  return (
    <>
      {statCount === 0 ? (
        <CancelButton
          className={styles.status}
          content={
            isLoading ? <LoadingSpinner /> : `${statCount} ${statDescriptor}`
          }
          onClick={onClick}
          isDisabled={true}
        />
      ) : (
        <PrimaryButton
          className={styles.status}
          content={
            isLoading ? <LoadingSpinner /> : `${statCount} ${statDescriptor}`
          }
          onClick={onClick}
        />
      )}
    </>
  );
};
