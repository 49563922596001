import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { PseudoButton } from 'src/components/pseudoButton';
import styles from './pill.module.scss';

interface Props {
  content: string | JSX.Element;
  showDelete?: boolean;
  deleteButtonId?: string;
  isDeleting?: boolean;
  onDeleteClicked?: () => void;
}

export const Pill = (props: Props): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{props.content}</div>

      {props.showDelete && (
        <PseudoButton
          id={props.deleteButtonId || ''}
          className={styles.delete}
          content={<FontAwesomeIcon icon={faRemove} />}
          onClick={(): void => props.onDeleteClicked?.()}
          isLoading={props.isDeleting}
        />
      )}
    </div>
  );
};
