import { MenuLayout } from 'src/components/menuLayout';
import { ResourcesLayout } from 'src/components/resourcesLayout';
import { ProviderMenuOptions } from 'src/models/providerMenuOptions';
import { ResourceType } from 'src/models/resource';
import { apiRoutes } from 'src/services/swr';
import { Routes, UrlParamNames } from 'src/utils/routes';

export const ProviderServices = (): JSX.Element => {
  return (
    <MenuLayout
      pageIndex={ProviderMenuOptions().findIndex(
        (o) => o.path === Routes.ProviderServicesPath
      )}
      menuOptions={ProviderMenuOptions()}
    >
      <ResourcesLayout
        resourceType={ResourceType.Service}
        resourcesRoute={apiRoutes.provider.services}
        detailsRoute={(resourceId): string =>
          apiRoutes.service.details(resourceId)
        }
        referralsRoute={(resourceId): string =>
          apiRoutes.service.referrals(resourceId)
        }
        deleteRoute={(resourceId): string =>
          apiRoutes.service.delete(resourceId)
        }
        dashboardPath={Routes.ProviderDashboardPath}
        resourcesPageUrl={Routes.ProviderServicesPath}
        resourceIdUrlParamName={UrlParamNames.ServiceIdUrlParamName}
      />
    </MenuLayout>
  );
};
