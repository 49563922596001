import { BrandLayout } from 'src/components/brandLayout';

export const ErrorPage = (): JSX.Element => {
  return (
    <BrandLayout
      title="The page you are looking for could not be found"
      showHomeButton={true}
    />
  );
};
