import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BrandLayout } from 'src/components/brandLayout';
import { PageLoading } from 'src/components/pageLoading';
import { User, UserInvite } from 'src/models/user';
import { useIdToken } from 'src/services/msal';
import {
  apiRoutes,
  authenticatedFetch,
  FetchMethod,
  useApi,
} from 'src/services/swr';
import {
  InvalidId,
  debounce,
  pluralizeWithoutCount,
  showErrorNotification,
  showSuccessNotification,
} from 'src/utils/helpers';
import { UserInviteCard } from 'src/components/userInviteCard';

export const InvitePage = (): JSX.Element => {
  const { token } = useIdToken();

  const { mutate: getUser } = useApi<User>(apiRoutes.user.get);

  const {
    data: invites,
    isValidating: isLoading,
    mutate: getInvites,
  } = useApi<UserInvite[]>(apiRoutes.user.invites);

  const [acceptingInviteId, setAcceptingInviteId] = useState(InvalidId);
  const [isAcceptingInvite, setIsAcceptingInvite] = useState(false);
  const [isAcceptingInviteComplete, setIsAcceptingInviteComplete] =
    useState(false);

  const [deletingInviteId, setDeletingInviteId] = useState(InvalidId);
  const [isDeletingInvite, setIsDeletingInvite] = useState(false);

  const [hasAcceptedInvite, setHasAcceptedInvite] = useState(false);

  const acceptInvite = async (id: number): Promise<void> => {
    setAcceptingInviteId(id);
    setIsAcceptingInvite(true);

    try {
      await authenticatedFetch(
        apiRoutes.user.invite(id),
        FetchMethod.POST,
        token
      );

      setIsAcceptingInviteComplete(true);
      await debounce();
      await getInvites();

      setHasAcceptedInvite(true);
      setIsAcceptingInvite(false);
      setIsAcceptingInviteComplete(false);
      setAcceptingInviteId(InvalidId);
      showSuccessNotification('Invite accepted!');

      // Refresh the user in case it was their role that was deleted.
      // Do after the other work and notification since it is a side-effect.
      await getUser();
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
      setIsAcceptingInvite(false);
      setAcceptingInviteId(InvalidId);
    }
  };

  const deleteInvite = async (id: number): Promise<void> => {
    setDeletingInviteId(id);
    setIsDeletingInvite(true);

    try {
      await authenticatedFetch(
        apiRoutes.user.invite(id),
        FetchMethod.DELETE,
        token
      );

      await getInvites();
      setIsDeletingInvite(false);
      setDeletingInviteId(InvalidId);

      showSuccessNotification('Invite deleted!');
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
      setIsDeletingInvite(false);
      setDeletingInviteId(InvalidId);
    }
  };

  return (
    <>
      {isLoading && !invites ? (
        <BrandLayout title={<PageLoading />} />
      ) : !invites?.length && !hasAcceptedInvite ? (
        <BrandLayout
          title="No invites found. If you are expecting an invite, check with
                  your organization admin and ask them to re-send it if it is
                  expired"
          showHomeButton={true}
        />
      ) : (
        <BrandLayout
          title={
            hasAcceptedInvite
              ? 'Go to the Home page to access your role dashboards'
              : `You have been invited to the following ${pluralizeWithoutCount(
                  'role',
                  invites?.length || 0
                )}! Click accept to apply the role to your profile or decline to delete the invite`
          }
          showHomeButton={hasAcceptedInvite}
          content={
            invites?.length !== 0 ? (
              <Row>
                <Col xs={12} md={{ offset: 1, span: 10 }}>
                  <Row>
                    {invites?.map((invite) => (
                      <Col key={invite.id} xs={12} md={6}>
                        <UserInviteCard
                          invite={invite}
                          isAccepting={
                            isAcceptingInvite && acceptingInviteId === invite.id
                          }
                          isAcceptingComplete={
                            isAcceptingInviteComplete &&
                            acceptingInviteId === invite.id
                          }
                          isDeleting={
                            isDeletingInvite && deletingInviteId === invite.id
                          }
                          onAccept={(): Promise<void> =>
                            acceptInvite(invite.id)
                          }
                          onDelete={(): Promise<void> =>
                            deleteInvite(invite.id)
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            ) : (
              ''
            )
          }
        />
      )}
    </>
  );
};
