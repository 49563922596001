import { Row, Container } from 'react-bootstrap';
import { MenuSidebar } from 'src/components/menuSidebar';
import { MenuNavbar } from 'src/components/menuNavbar';
import { MenuOption } from 'src/models/menuOption';
import styles from './menuLayout.module.scss';

interface Props {
  pageIndex: number;
  menuOptions: MenuOption[];
}

export const MenuLayout: React.FC<Props> = ({
  pageIndex,
  menuOptions,
  children,
}): JSX.Element => {
  return (
    <Container fluid className={styles.container}>
      <MenuNavbar
        menuOptions={menuOptions}
        selectedIndex={pageIndex}
        navDropdownClassName="d-block d-xl-none"
      />
      <Row className={styles.rowWrapper}>
        <div className={`${styles.sidebarWrapper} d-none d-xl-block`}>
          <MenuSidebar menuOptions={menuOptions} selectedIndex={pageIndex} />
        </div>

        <div className={styles.content}>{children}</div>
      </Row>
    </Container>
  );
};
