import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CancelButton } from 'src/components/cancelButton';
import { PrimaryButton } from 'src/components/primaryButton';
import { RegistrationHeader } from 'src/components/registrationHeader';
import { RegistrationDetails } from 'src/models/registrationDetails';
import { useIdToken } from 'src/services/msal';
import { authenticatedFetch, FetchMethod } from 'src/services/swr';
import { showErrorNotification } from 'src/utils/helpers';

interface Props {
  title: string;
  description: string;
  registration: RegistrationDetails;
  deleteRoute: string;
  onCancel: () => void;
  onComplete: () => void;
}

export const ContinueResourceRegistration = (props: Props): JSX.Element => {
  const [isCanceling, setIsCanceling] = useState(false);
  const { token } = useIdToken();

  const onCancel = async (): Promise<void> => {
    setIsCanceling(true);

    try {
      await authenticatedFetch(props.deleteRoute, FetchMethod.DELETE, token);
      props.onCancel();
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
      setIsCanceling(false);
    }
  };

  return (
    <>
      <RegistrationHeader title={props.title} description={props.description} />

      <Row>
        <Col sm={8} className="mx-auto text-center">
          <CancelButton
            content="Cancel"
            shouldConfirm={true}
            isLoading={isCanceling}
            onClick={onCancel}
          />
          <PrimaryButton
            content="Complete Registration"
            isDisabled={isCanceling}
            onClick={props.onComplete}
          />
        </Col>
      </Row>
    </>
  );
};
