import { useLocation } from 'react-router-dom';
import { MenuLayout } from 'src/components/menuLayout';
import { AdvocateMenuOptions } from 'src/models/advocateMenuOptions';
import { apiRoutes, useApi } from 'src/services/swr';
import { Routes, UrlParamNames } from 'src/utils/routes';
import { ResourceDetails } from 'src/models/resourceDetails';
import { ResourceType } from 'src/models/resource';
import { ResourceResult } from 'src/components/resourceResult';

export const AdvocateDirectoryResult = (): JSX.Element => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const serviceIdParam = urlParams.get(UrlParamNames.ServiceIdUrlParamName);

  const { data: serviceDetails, isValidating: isLoading } =
    useApi<ResourceDetails>(
      serviceIdParam
        ? apiRoutes.api.serviceDetails(Number(serviceIdParam))
        : undefined
    );

  return (
    <MenuLayout
      pageIndex={AdvocateMenuOptions().findIndex(
        (o) => o.path === Routes.AdvocateDirectoryPath
      )}
      menuOptions={AdvocateMenuOptions()}
    >
      <ResourceResult
        resourceType={ResourceType.Service}
        resourceId={serviceIdParam ? Number(serviceIdParam) : 0}
        resourceDetails={serviceDetails}
        isLoading={isLoading}
      />
    </MenuLayout>
  );
};
