import { MouseEvent, useCallback, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { CancelButton } from 'src/components/cancelButton';
import { PrimaryButton } from 'src/components/primaryButton';
import { PseudoButton } from 'src/components/pseudoButton';
import { ReferralStatsCardLayout } from 'src/components/referralStatsCardLayout';
import { ServiceAvailabilityModal } from 'src/components/serviceAvailabilityModal';
import { ReferralStats } from 'src/models/referralStats';
import { Service } from 'src/models/resource';
import { apiRoutes, useApi } from 'src/services/swr';
import {
  CalendarStrings,
  getServiceIcon,
  Locale,
  pluralize,
} from 'src/utils/helpers';
import { Routes, UrlParamNames } from 'src/utils/routes';
import {
  cardInfoText,
  cardInfoTitle,
  noWrap,
  cardHeaderText,
} from 'src/utils/styles';
import styles from './serviceCard.module.scss';

interface Props {
  service: Service;
  iconSize?: SizeProp;
  isInactive?: boolean;
  isDisabled?: boolean;
  showUnits?: boolean;
  onDelete?: (resourceId: number) => void;
}

export const ServiceCard = (props: Props): JSX.Element => {
  const history = useHistory();

  const { data: referralStats, isValidating: isLoadingReferralStats } =
    useApi<ReferralStats>(apiRoutes.service.referralStats(props.service.id));

  const [showAvailability, setShowAvailability] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleShowAvailability = async (
    e: MouseEvent<Element>
  ): Promise<void> => {
    if (props.isInactive || props.isDisabled) {
      return;
    }

    // Don't pass the availability click through to the card.
    e.stopPropagation();
    setShowAvailability(true);
  };

  const handleConfirmDelete = (): void => {
    setShowConfirmDelete(false);
    props.onDelete?.(props.service.id);
  };

  const hasServiceStarted = useCallback((): boolean => {
    const today = moment();
    const start = moment(props.service.startDate);
    return start.isBefore(today, 'day');
  }, [props.service.startDate]);

  const onClick = (): void => {
    if (!props.isDisabled) {
      history.push(
        `${Routes.ProviderServicesPath}?${UrlParamNames.ServiceIdUrlParamName}=${props.service.id}`
      );
    }
  };

  return (
    <>
      <ReferralStatsCardLayout
        onClick={onClick}
        icon={getServiceIcon(props.service.type)}
        iconSize={props.iconSize}
        grayscale={props.isInactive || props.isDisabled}
        headerRow={
          <Row className={cardHeaderText}>
            <Col xs={props.onDelete ? 10 : 12}>{props.service.name}</Col>
            {props.onDelete && (
              <Col xs={2} className="d-flex flex-row-reverse">
                <PseudoButton
                  id={`deleteService${props.service.id}`}
                  content={<FontAwesomeIcon icon={faTrashAlt} title="Delete" />}
                  onClick={(): void => setShowConfirmDelete(true)}
                  isDisabled={props.isInactive || props.isDisabled}
                />
              </Col>
            )}
          </Row>
        }
        contentRow={
          <>
            <Row>
              <Col xs={12} sm={5}>
                <div className="d-flex flex-column">
                  <div className={`${cardInfoTitle} ${noWrap}`}>
                    Availability
                  </div>
                  <div>
                    <Row>
                      <Col className={`${cardInfoText}`}>
                        <div
                          className={`${styles.clickableText} ${noWrap} text-primary`}
                          onClick={handleShowAvailability}
                        >
                          {props.showUnits
                            ? `${pluralize(
                                props.service.unit,
                                props.service.availableUnits
                              )}`
                            : props.service.availableUnits}
                          {' Open'}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={`${cardInfoText} ${noWrap}`}>
                        {props.showUnits
                          ? `${pluralize(
                              props.service.unit,
                              props.service.totalUnits
                            )}`
                          : props.service.totalUnits}
                        {' Total'}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={7}>
                <div className="d-flex flex-column">
                  {hasServiceStarted() ? (
                    <>
                      <div className={`${cardInfoTitle} ${noWrap}`}>As Of</div>
                      <div className={cardInfoText}>
                        {props.service.availabilityLastUpdatedOn ? (
                          <>
                            <Moment
                              calendar={CalendarStrings}
                              locale={Locale}
                              local
                            >
                              {props.service.availabilityLastUpdatedOn}
                            </Moment>
                            {props.service.availabilityLastUpdatedBy &&
                              ` by ${props.service.availabilityLastUpdatedBy}`}
                          </>
                        ) : (
                          'N/A'
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={`${cardInfoTitle} ${noWrap}`}>Begins</div>
                      <div className={cardInfoText}>
                        <Moment format="l" locale={Locale} local>
                          {props.service.startDate}
                        </Moment>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </>
        }
        statsIsLoading={isLoadingReferralStats && !referralStats}
        statsReadyCount={referralStats?.waitingOnProvider ?? 0}
        statsWaitingCount={referralStats?.waitingOnAdvocate ?? 0}
      />

      <Modal
        centered
        show={showConfirmDelete}
        onHide={(): void => setShowConfirmDelete(false)}
      >
        <Modal.Header closeButton closeLabel="close">
          <Modal.Title>
            Delete {props.service.name} and all associated data
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <CancelButton
            content="Cancel"
            onClick={(): void => setShowConfirmDelete(false)}
          />
          <PrimaryButton content="Delete" onClick={handleConfirmDelete} />
        </Modal.Footer>
      </Modal>

      <ServiceAvailabilityModal
        serviceId={props.service.id}
        show={showAvailability}
        onHide={(): void => setShowAvailability(false)}
      />
    </>
  );
};
