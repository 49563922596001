import { FormEvent, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { BlockNavigation } from 'src/components/blockNavigation';
import { CustomTooltip } from 'src/components/customTooltip';
import { MenuLayout } from 'src/components/menuLayout';
import { PrimaryButton } from 'src/components/primaryButton';
import { UsersLayout } from 'src/components/usersLayout';
import { UserInvitesAdminLayout } from 'src/components/userInvitesAdminLayout';
import {
  BotContactSettings,
  EmptyBotContactSettings,
} from 'src/models/botContactSettings';
import { ProviderMenuOptions } from 'src/models/providerMenuOptions';
import { RoleType, User } from 'src/models/user';
import { useIdToken } from 'src/services/msal';
import {
  apiRoutes,
  authenticatedFetch,
  FetchMethod,
  useApi,
} from 'src/services/swr';
import { AppName } from 'src/utils/constants';
import {
  BooleanOptions,
  formatPhoneNumber,
  showErrorNotification,
  showSuccessNotification,
} from 'src/utils/helpers';
import { Routes } from 'src/utils/routes';

export const ProviderSettings = (): JSX.Element => {
  const { token } = useIdToken();

  const { data: user } = useApi<User>(apiRoutes.user.get);

  const {
    data: botContactSettings,
    isValidating: isLoadingBotContactSettings,
    mutate: getBotContactSettings,
  } = useApi<BotContactSettings>(apiRoutes.provider.botContact);

  const [currentBotContactSettings, setCurrentBotContactSettings] = useState(
    EmptyBotContactSettings
  );

  const [botContactSettingsNeedsSave, setBotContactSettingsNeedsSave] =
    useState(false);
  const [botContactSettingsIsValidated, setBotContactSettingsIsValidated] =
    useState(false);
  const [isSubmittingBotContact, setIsSubmittingBotContact] = useState(false);

  const isBotContactDisabled = (): boolean => {
    return isLoadingBotContactSettings || isSubmittingBotContact;
  };

  const handleSubmitBotContactSettings = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (isSubmittingBotContact || !form) {
      return;
    }

    if (form.checkValidity()) {
      setBotContactSettingsIsValidated(true);
      setIsSubmittingBotContact(true);

      try {
        await authenticatedFetch(
          apiRoutes.provider.botContact,
          FetchMethod.POST,
          token,
          JSON.stringify(currentBotContactSettings)
        );
        await getBotContactSettings();
        setBotContactSettingsNeedsSave(false);
        setBotContactSettingsIsValidated(false);
        setIsSubmittingBotContact(false);

        showSuccessNotification('Settings saved!');
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsSubmittingBotContact(false);
      }
    }
  };

  useEffect(() => {
    botContactSettings && setCurrentBotContactSettings(botContactSettings);
  }, [botContactSettings]);

  return (
    <MenuLayout
      pageIndex={ProviderMenuOptions().findIndex(
        (o) => o.path === Routes.ProviderSettingsPath
      )}
      menuOptions={ProviderMenuOptions()}
    >
      <BlockNavigation needsSave={botContactSettingsNeedsSave}>
        <Container>
          <Row>
            <div>
              <div className="pageTitle">SMS SETTINGS</div>
              <div className="line" />
            </div>
          </Row>

          <Form
            validated={botContactSettingsIsValidated}
            onSubmit={handleSubmitBotContactSettings}
          >
            <Form.Row>
              <Col xs={12} lg={6}>
                <Form.Group controlId="formBotContactIsEnabled">
                  <Form.Label className="font-weight-bold">
                    {`Enable ${AppName} to contact you for updates via SMS?`}
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    value={currentBotContactSettings?.isEnabled ? 1 : 0}
                    onChange={(e): void => {
                      setCurrentBotContactSettings({
                        ...currentBotContactSettings,
                        isEnabled: !!Number(e.target.value),
                      });
                      setBotContactSettingsNeedsSave(true);
                    }}
                    disabled={isBotContactDisabled()}
                    required
                  >
                    {BooleanOptions.map((opt) => {
                      return (
                        <option key={opt.id} value={opt.id}>
                          {opt.value}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group controlId="formBotContactPhoneNumber">
                  <Form.Label className="font-weight-bold">
                    Phone number (private, only for SMS updates)
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
                    title="Phone number must be 10 digits with area code"
                    placeholder="Phone number"
                    value={formatPhoneNumber(
                      currentBotContactSettings.phoneNumber || ''
                    )}
                    onChange={(e): void => {
                      setCurrentBotContactSettings({
                        ...currentBotContactSettings,
                        phoneNumber: formatPhoneNumber(e.target.value),
                      });
                      setBotContactSettingsNeedsSave(true);
                    }}
                    required={currentBotContactSettings.isEnabled}
                    disabled={isBotContactDisabled()}
                  />
                </Form.Group>
              </Col>
            </Form.Row>

            {botContactSettingsNeedsSave && (
              <Form.Row>
                <Col xs={12} sm={2} lg={1}>
                  <CustomTooltip
                    popup="Save changes"
                    content={
                      <PrimaryButton
                        content="Save"
                        isDisabled={isSubmittingBotContact}
                      />
                    }
                  />
                </Col>
              </Form.Row>
            )}
          </Form>

          {user?.roles.includes(RoleType.OrganizationAdmin) && (
            <>
              <Row>
                <div>
                  <div className="pageTitle">USER INVITES</div>
                  <div className="line" />
                </div>
              </Row>
              <Row>
                <Col>
                  <UserInvitesAdminLayout
                    invitesRoute={
                      user?.roles.includes(RoleType.OrganizationAdmin)
                        ? apiRoutes.organizationAdmin.invites
                        : undefined
                    }
                    inviteRoute={(inviteId): string | undefined =>
                      user?.roles.includes(RoleType.OrganizationAdmin)
                        ? apiRoutes.organizationAdmin.invite(inviteId)
                        : undefined
                    }
                  />
                </Col>
              </Row>

              <Row>
                <div>
                  <div className="pageTitle">USERS</div>
                  <div className="line" />
                </div>
              </Row>
              <Row>
                <Col>
                  <UsersLayout
                    usersRoute={apiRoutes.organizationAdmin.users}
                    deleteUserRoute={(userId): string =>
                      apiRoutes.organizationAdmin.user(userId)
                    }
                    deleteRoleRoute={(userId, role): string =>
                      apiRoutes.organizationAdmin.userRole(userId, role)
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </BlockNavigation>
    </MenuLayout>
  );
};
