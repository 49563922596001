import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row } from 'react-bootstrap';
import {
  cardHeader,
  cardIconWrapper,
  cardBodyWrapper,
  cardContainer,
  cardInfo,
} from 'src/utils/styles';
import styles from './cardLayout.module.scss';

interface Props {
  icon: IconProp;
  iconSize?: SizeProp;
  headerRow: JSX.Element;
  contentRow: JSX.Element;
  grayscale?: boolean;
  onClick?: () => void;
}

export const CardLayout = (props: Props): JSX.Element => {
  return (
    <>
      <Container
        className={cardContainer + (props.onClick ? ' clickable' : '')}
        style={{
          filter: props.grayscale ? 'grayscale(100%)' : '',
        }}
      >
        <Row className={cardBodyWrapper} onClick={props.onClick}>
          <Col xs={3}>
            <div className={cardIconWrapper}>
              {
                // Use the provided icon size if it exists, otherwise use a loosely responsive size.
                props.iconSize ? (
                  <FontAwesomeIcon
                    className={styles.cardIcon}
                    icon={props.icon}
                    size={props.iconSize}
                  />
                ) : (
                  <>
                    <FontAwesomeIcon
                      className={`${styles.cardIcon} d-sm-none`}
                      icon={props.icon}
                      size="2x"
                    />
                    <FontAwesomeIcon
                      className={`${styles.cardIcon} d-none d-sm-block`}
                      icon={props.icon}
                      size="4x"
                    />
                  </>
                )
              }
            </div>
          </Col>
          <Col xs={9}>
            <div className={cardInfo}>
              <div className={cardHeader}>{props.headerRow}</div>
              <Row>
                <Col xs={4}>
                  <div className={styles.line} />
                </Col>
              </Row>
              {props.contentRow}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
