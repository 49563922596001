import { IdValuePair } from './idValuePair';
import { QuestionKind } from './question';

export enum QuestionFilterType {
  Age,
  Gender,
  Demographic,
  ChildrenMax,
  ChildrenAgeMin,
  ChildrenAgeMax,
  Citizenship,
  Language,
  Relocation,
  ExperiencedTrafficking,
  CriminalLegal,
  Health,
  ServiceModel,
  ServiceStructure,
  Bedrooms,
}

export interface QuestionFilter {
  filterType: QuestionFilterType;
  label: string;
  questionKind: QuestionKind;
  options: IdValuePair[];
}
