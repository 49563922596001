import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Col, Form } from 'react-bootstrap';
import moment from 'moment';
import { SingleDatePicker } from 'src/components/singleDatePicker';
import { Answer } from 'src/models/answer';
import { Question, QuestionKind } from 'src/models/question';
import {
  BooleanOptions,
  DateFormat,
  Delimiter,
  getQuestionDefaultAnswer,
  isQuestionConditionSatisfied,
} from 'src/utils/helpers';
import { SelectAllButton } from 'src/components/SelectAllButton';

interface Props {
  questions: Question[];
  answers: Answer[];
  onAnswersUpdated: (answers: Answer[]) => void;
  isDisabled?: boolean;
  readOnly?: boolean;
}

interface InputProps {
  question: Question;
  answers: Answer[];
  onAnswersUpdated: (state: Answer[]) => void;
  isDisabled?: boolean;
  readOnly?: boolean;
}

export const DynamicForm = ({
  questions,
  answers,
  onAnswersUpdated,
  isDisabled,
  readOnly,
}: Props): JSX.Element => {
  return (
    <Form.Row>
      {questions.map((question): JSX.Element => {
        if (isQuestionConditionSatisfied(question, questions, answers)) {
          switch (question.questionKind) {
            case QuestionKind.Text:
              return (
                <TextInput
                  key={question.id}
                  question={question}
                  answers={answers}
                  onAnswersUpdated={onAnswersUpdated}
                  isDisabled={isDisabled}
                  readOnly={readOnly}
                />
              );
            case QuestionKind.Boolean:
              return (
                <BooleanInput
                  key={question.id}
                  question={question}
                  answers={answers}
                  onAnswersUpdated={onAnswersUpdated}
                  isDisabled={isDisabled}
                  readOnly={readOnly}
                />
              );
            case QuestionKind.SingleOption:
              return (
                <SingleOptionInput
                  key={question.id}
                  question={question}
                  answers={answers}
                  onAnswersUpdated={onAnswersUpdated}
                  isDisabled={isDisabled}
                  readOnly={readOnly}
                />
              );
            case QuestionKind.MultiSelect:
              return (
                <MultipleOptionInput
                  key={question.id}
                  question={question}
                  answers={answers}
                  onAnswersUpdated={onAnswersUpdated}
                  isDisabled={isDisabled}
                  readOnly={readOnly}
                />
              );
            case QuestionKind.Number:
              return (
                <NumberInput
                  key={question.id}
                  question={question}
                  answers={answers}
                  onAnswersUpdated={onAnswersUpdated}
                  isDisabled={isDisabled}
                  readOnly={readOnly}
                />
              );
            case QuestionKind.Date:
              return (
                <DateInput
                  key={question.id}
                  question={question}
                  answers={answers}
                  onAnswersUpdated={onAnswersUpdated}
                  isDisabled={isDisabled}
                  readOnly={readOnly}
                />
              );
            default:
              return <></>;
          }
        } else {
          // Set the answer back to the default value if not already.
          // For example, if the user changes the answer to an invalid value, then changes
          // the condition question so that the condition is no longer satisfied, then the
          // answer to this question no longer matters and should not block validation.
          const defaultAnswer = getQuestionDefaultAnswer(
            question.questionKind,
            question.options
          );

          const answer = answers.find((a) => a.questionId === question.id);

          if (answer?.value !== defaultAnswer) {
            onAnswersUpdated(
              [...answers].map((answer) => {
                if (answer.questionId === question.id) {
                  return {
                    ...answer,
                    value: defaultAnswer,
                  };
                }
                return answer;
              })
            );
          }
        }

        return <></>;
      })}
    </Form.Row>
  );
};

const TextInput = ({
  question,
  answers,
  onAnswersUpdated,
  isDisabled,
  readOnly,
}: InputProps): JSX.Element => {
  const currentValue = useMemo((): string => {
    const answer = answers.find((a) => a.questionId === question.id);

    return (
      answer?.value ??
      getQuestionDefaultAnswer(question.questionKind, question.options)
    );
  }, [question, answers]);

  return (
    <Col xs={12} lg={6}>
      <Form.Group controlId={`question${question.id}`}>
        <Form.Label className="font-weight-bold">{question.label}</Form.Label>
        {readOnly ? (
          <p className="font-italic">{currentValue}</p>
        ) : (
          <Form.Control
            size="sm"
            type="text"
            placeholder={question.label}
            value={currentValue}
            onChange={(e): void =>
              onAnswersUpdated(
                [...answers].map((answer) => {
                  if (answer.questionId === question.id) {
                    return {
                      ...answer,
                      value: e.target.value,
                    };
                  }
                  return answer;
                })
              )
            }
            disabled={isDisabled}
            required
          />
        )}
      </Form.Group>
    </Col>
  );
};

const BooleanInput = ({
  question,
  answers,
  onAnswersUpdated,
  isDisabled,
  readOnly,
}: InputProps): JSX.Element => {
  const currentValue = useMemo((): string => {
    const answer = answers.find((a) => a.questionId === question.id);

    if (!answer?.value) {
      return getQuestionDefaultAnswer(question.questionKind, question.options);
    }

    const option = BooleanOptions.find((o) => o.value === answer.value);
    return option?.id
      ? option.id.toString()
      : getQuestionDefaultAnswer(question.questionKind, question.options);
  }, [question, answers]);

  return (
    <Col xs={12} lg={6}>
      <Form.Group controlId={`question${question.id}`}>
        <Form.Label className="font-weight-bold">{question.label}</Form.Label>
        {readOnly ? (
          <p className="font-italic">
            {BooleanOptions.find((o) => o.id === Number(currentValue))?.value ??
              currentValue}
          </p>
        ) : (
          <Form.Control
            size="sm"
            as="select"
            value={currentValue}
            onChange={(e): void =>
              onAnswersUpdated(
                [...answers].map((answer) => {
                  if (answer.questionId === question.id) {
                    return {
                      ...answer,
                      value:
                        BooleanOptions.find(
                          (o) => o.id === Number(e.target.value)
                        )?.value ?? '',
                    };
                  }
                  return answer;
                })
              )
            }
            disabled={isDisabled}
          >
            {BooleanOptions.map((opt) => {
              return (
                <option key={opt.id} value={opt.id}>
                  {opt.value}
                </option>
              );
            })}
          </Form.Control>
        )}
      </Form.Group>
    </Col>
  );
};

const SingleOptionInput = ({
  question,
  answers,
  onAnswersUpdated,
  isDisabled,
  readOnly,
}: InputProps): JSX.Element => {
  const currentValue = useMemo((): string => {
    const answer = answers.find((a) => a.questionId === question.id);

    if (!answer?.value) {
      return getQuestionDefaultAnswer(question.questionKind, question.options);
    }

    return question.options.some((o) => o.id === Number(answer.value))
      ? answer.value
      : getQuestionDefaultAnswer(question.questionKind, question.options);
  }, [question, answers]);

  const currentValueLabel = useMemo((): string => {
    const answer = answers.find((a) => a.questionId === question.id);

    if (!answer?.value) {
      return '';
    }

    const option = question.options.find((o) => o.id === Number(answer.value));
    return option?.value ?? '';
  }, [question, answers]);

  return (
    <Col xs={12} lg={6}>
      <Form.Group controlId={`question${question.id}`}>
        <Form.Label className="font-weight-bold">{question.label}</Form.Label>
        {readOnly ? (
          <p className="font-italic">{currentValueLabel}</p>
        ) : (
          <Form.Control
            size="sm"
            as="select"
            value={currentValue}
            onChange={(e): void =>
              onAnswersUpdated(
                [...answers].map((answer) => {
                  if (answer.questionId === question.id) {
                    return {
                      ...answer,
                      value: e.target.value,
                    };
                  }
                  return answer;
                })
              )
            }
            disabled={isDisabled}
          >
            {question.options.map((opt) => {
              return (
                <option key={opt.id} value={opt.id}>
                  {opt.value}
                </option>
              );
            })}
          </Form.Control>
        )}
      </Form.Group>
    </Col>
  );
};

const MultipleOptionInput = ({
  question,
  answers,
  onAnswersUpdated,
  isDisabled,
  readOnly,
}: InputProps): JSX.Element => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const getSelectedBoxes = (): string[] => {
    const answer = answers.find((a) => a.questionId === question.id);
    return answer?.value.split(Delimiter) || [];
  };

  const readOnlyValue = useMemo(() => {
    const answer = answers.find((a) => a.questionId === question.id);

    if (!answer?.value) {
      return 'None';
    }

    const selectedIds = answer.value.split(Delimiter);

    return selectedIds.length === 0
      ? 'None'
      : question.options
          .filter((opt) => selectedIds.some((id) => Number(id) === opt.id))
          .map((opt) => opt.value)
          .join(', ');
  }, [question, answers]);

  const onChange = (): void => {
    const checkedIds = inputRefs.current
      .filter((ref) => ref.checked)
      .map((ref) => Number(ref.value));

    updateAnswers(checkedIds.join(Delimiter));
  };

  const updateAnswers = (checkedIds: string): void => {
    onAnswersUpdated(
      [...answers].map((answer) => {
        if (answer.questionId === question.id) {
          return {
            ...answer,
            value: checkedIds,
          };
        }
        return answer;
      })
    );
  };

  const setInitialValue = useCallback((): void => {
    const answer = answers.find((a) => a.questionId === question.id);

    if (!answer?.value) {
      return;
    }

    const ids = answer.value.split(Delimiter);

    inputRefs.current.forEach((ref) => {
      ref.checked = ids.some((id) => id === ref.value);
    });
  }, [question, answers]);

  useEffect(() => {
    setInitialValue();
  }, [setInitialValue]);

  return (
    <Col xs={12} lg={6}>
      <Form.Group controlId={`question${question.id}`}>
        <Form.Label className="font-weight-bold">
          {`${question.label}${readOnly ? '' : ' (select all that apply)'}`}
        </Form.Label>
        {readOnly ? (
          <p className="font-italic">{readOnlyValue}</p>
        ) : (
          <>
            {question.options.map((opt, index) => {
              return (
                <Form.Check
                  key={opt.id}
                  id={opt.id.toString()}
                  value={opt.id}
                  label={opt.value}
                  onChange={onChange}
                  disabled={isDisabled}
                  ref={(e: HTMLInputElement): void => {
                    inputRefs.current[index] = e;
                  }}
                />
              );
            })}
            <SelectAllButton
              allBoxIds={question.options?.map((a) => a.id)}
              allAreSelected={(): boolean =>
                getSelectedBoxes().length === question.options?.length
              }
              onChange={(newBoxes: number[]): void => {
                updateAnswers(newBoxes.join(Delimiter));
                inputRefs.current.forEach((ref) => {
                  ref.checked = newBoxes.length !== 0;
                });
              }}
              disableInputs={isDisabled}
            />
          </>
        )}
      </Form.Group>
    </Col>
  );
};

const NumberInput = ({
  question,
  answers,
  onAnswersUpdated,
  isDisabled,
  readOnly,
}: InputProps): JSX.Element => {
  const currentValue = useMemo((): string => {
    const answer = answers.find((a) => a.questionId === question.id);

    return (
      answer?.value ??
      getQuestionDefaultAnswer(question.questionKind, question.options)
    );
  }, [question, answers]);

  return (
    <Col xs={12} lg={6}>
      <Form.Group controlId={`question${question.id}`}>
        <Form.Label className="font-weight-bold">{question.label}</Form.Label>
        {readOnly ? (
          <p className="font-italic">{currentValue}</p>
        ) : (
          <Form.Control
            size="sm"
            type="number"
            min={0}
            placeholder={question.label}
            value={currentValue}
            onChange={(e): void =>
              onAnswersUpdated(
                [...answers].map((answer) => {
                  if (answer.questionId === question.id) {
                    return {
                      ...answer,
                      value: e.target.value,
                    };
                  }
                  return answer;
                })
              )
            }
            disabled={isDisabled}
            required
          />
        )}
      </Form.Group>
    </Col>
  );
};

const DateInput = ({
  question,
  answers,
  onAnswersUpdated,
  isDisabled,
  readOnly,
}: InputProps): JSX.Element => {
  const currentValue = useMemo((): Date => {
    const answer = answers.find((a) => a.questionId === question.id);

    const value =
      answer?.value ??
      getQuestionDefaultAnswer(question.questionKind, question.options);

    return value ? new Date(value) : new Date();
  }, [question, answers]);

  const updateValue = useCallback(
    (date: Date) => {
      onAnswersUpdated(
        [...answers].map((answer) => {
          if (answer.questionId === question.id) {
            return {
              ...answer,
              value: moment(date).format(DateFormat),
            };
          }
          return answer;
        })
      );
    },
    [question, answers, onAnswersUpdated]
  );

  const setInitialValue = useCallback(() => {
    const answer = answers.find((a) => a.questionId === question.id);

    if (answer?.value) {
      return;
    }

    updateValue(
      moment(
        getQuestionDefaultAnswer(question.questionKind, question.options),
        DateFormat
      ).toDate()
    );
  }, [question, answers, updateValue]);

  useEffect(() => {
    setInitialValue();
  }, [setInitialValue]);

  return (
    <Col xs={12} lg={6}>
      <Form.Group controlId={`question${question.id}`}>
        <Form.Label className="font-weight-bold">{question.label}</Form.Label>
        <SingleDatePicker
          date={currentValue}
          showYearDropdown={true}
          readOnly={readOnly}
          disabled={isDisabled}
          size="sm"
          onChanged={(date): void => updateValue(date)}
        />
      </Form.Group>
    </Col>
  );
};
