export enum RoleType {
  SystemAdmin,
  OrganizationAdmin,
  Advocate,
  ServiceContributor,
}

export interface User {
  emailAddress?: string;
  id: number;
  name?: string;
  roles: RoleType[];
}

export interface UserInvite {
  id: number;
  organizationName: string;
  emailAddress: string;
  role: RoleType;
  expiresOn: Date;
  invitedBy: string;
}

export interface NewUserInvite {
  emailAddress: string;
  role: RoleType;
}
