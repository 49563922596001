import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelopeSquare,
  faExternalLinkSquare,
  faLocationDot,
  faPhoneSquare,
} from '@fortawesome/free-solid-svg-icons';
import { CustomTooltip } from 'src/components/customTooltip';
import { PrimaryButton } from 'src/components/primaryButton';
import { DirectorySearchResult } from 'src/models/directorySearchResult';
import { AffiliationOrLicenseType } from 'src/models/affiliation';
import {
  getServiceIcon,
  CalendarStrings,
  Locale,
  pluralizeWithoutCount,
} from 'src/utils/helpers';
import ntsaAccredited from 'src/images/ntsa_accredited.png';
import styles from './directoryCard.module.scss';
import { features } from 'src/utils/constants';
import { Routes } from 'src/utils/routes';
import { cardInfoText } from 'src/utils/styles';

interface DirectoryCardProps {
  service: DirectorySearchResult;
  serviceTypeName: string;
  showAvailability: boolean;
  showReferral: boolean;
  canMakeReferral: boolean;
  onResultClick?: () => void;
  onReferralClick?: () => void;
}

export const DirectoryCard = ({
  service,
  serviceTypeName,
  showAvailability,
  showReferral,
  canMakeReferral,
  onResultClick,
  onReferralClick,
}: DirectoryCardProps): JSX.Element => {
  const isNtsaAccredited = useMemo(
    () =>
      service.serviceProvider?.affiliations?.find(
        (affiliation) =>
          affiliation.type === AffiliationOrLicenseType.NtsaAccreditedMember
      ),
    [service]
  );

  const mapLink = (address: string, zipCode: string): string =>
    encodeURI(`https://www.google.com/maps/place/${address}, ${zipCode}`);

  const onClientLinkClick = (e: React.MouseEvent): void => {
    // Prevent bubbling up as a click on the result itself.
    e.stopPropagation();
  };

  return (
    <Card
      className={`shadow rounded bg-white m-2 ${
        onResultClick ? styles.clickable : ''
      }`}
      onClick={onResultClick}
    >
      <Card.Body>
        <Row>
          <Col>
            <div className="d-flex align-items-center">
              <div className={styles.titleText}>{service.name}</div>

              <div className="d-flex align-items-center ml-auto">
                {isNtsaAccredited && (
                  <CustomTooltip
                    popup="NTSA Accredited"
                    content={
                      <img
                        src={ntsaAccredited}
                        alt="NTSA Accredited"
                        width={25}
                        className="mr-2"
                      />
                    }
                  />
                )}

                <CustomTooltip
                  popup={serviceTypeName}
                  content={
                    <FontAwesomeIcon
                      icon={getServiceIcon(service.type)}
                      className={styles.serviceIcon}
                      size="lg"
                    />
                  }
                />
              </div>
            </div>
          </Col>
        </Row>

        {onResultClick && (
          <Row className={cardInfoText}>
            <Col xs={12}>
              <i>(Click to view service details)</i>
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={4}>
            <div className={styles.line} />
          </Col>
        </Row>

        <Row className="mt-3">
          {showAvailability && (
            <Col xs={12} lg={6} className="mb-3">
              <div className="d-flex">
                <div className={styles.availabilityWrapper}>
                  <div className={styles.availabilityDetails}>
                    {service.availability.availableUnits}
                  </div>
                  <small>
                    {pluralizeWithoutCount(
                      service.availability.unit,
                      service.availability.availableUnits
                    )}
                  </small>
                </div>

                <div className={`${styles.availabilityWrapper} ml-3`}>
                  <div className={styles.availabilityDetails}>
                    {service.availability.isWaitlistOpen ? 'Open' : 'Closed'}
                  </div>
                  <small>Waitlist</small>
                </div>
              </div>
              <div className="mt-2">
                <small className="mr-1">Updated</small>
                <small>
                  {service.availability.updatedDate ? (
                    <Moment calendar={CalendarStrings} locale={Locale} local>
                      {service.availability.updatedDate}
                    </Moment>
                  ) : (
                    'N/A'
                  )}
                </small>
              </div>
            </Col>
          )}

          <Col xs={12} lg={showAvailability ? 6 : 12}>
            <Row>
              <Col xs={12} className={styles.detailText}>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className={styles.detailIcon}
                />
                {service.isUndisclosedAddress ? (
                  <>{`${service.countrySubdivision} (address undisclosed)`}</>
                ) : (
                  <a
                    href={mapLink(service.streetAddress, service.zipCode)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`${service.countrySubdivision}`}
                  </a>
                )}
              </Col>
              <Col xs={12} className={styles.detailText}>
                <FontAwesomeIcon
                  icon={faPhoneSquare}
                  className={styles.detailIcon}
                />
                <a
                  href={`tel:${
                    service.phoneNumber || service.serviceProvider.phoneNumber
                  }`}
                >
                  {service.phoneNumber || service.serviceProvider.phoneNumber}
                </a>
              </Col>
              <Col xs={12} className={styles.detailText}>
                <FontAwesomeIcon
                  icon={faExternalLinkSquare}
                  className={styles.detailIcon}
                />
                <a
                  href={service.serviceProvider.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {service.serviceProvider.url}
                </a>
              </Col>
              <Col xs={12} className={styles.detailText}>
                <FontAwesomeIcon
                  icon={faEnvelopeSquare}
                  className={styles.detailIcon}
                />
                <a
                  href={`mailto:${
                    service.emailAddress || service.serviceProvider.emailAddress
                  }`}
                >
                  {service.emailAddress || service.serviceProvider.emailAddress}
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        {features.referrals && showReferral && (
          <Row className="mt-3">
            <Col>
              {canMakeReferral ? (
                service.availability.availableUnits > 0 ||
                service.availability.isWaitlistOpen ? (
                  <PrimaryButton
                    content="Make a Referral"
                    onClick={onReferralClick}
                  />
                ) : (
                  <PrimaryButton
                    content="No availability to make a referral"
                    isDisabled={true}
                  />
                )
              ) : (
                <div className={styles.referralHelp}>
                  <div className={styles.line} />
                  {`To make a referral, navigate to a `}
                  <Link
                    to={Routes.AdvocateClientsPath}
                    onClick={onClientLinkClick}
                  >
                    client profile
                  </Link>
                  {` and click Find Services to search on behalf of
                  that client`}
                </div>
              )}
            </Col>
          </Row>
        )}
      </Card.Body>
      <Card.Footer className="text-center">
        <small>
          Service provided by <i>{service.serviceProvider.name}</i>
        </small>
      </Card.Footer>
    </Card>
  );
};
