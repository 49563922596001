export enum Routes {
  HomePath = '/',

  HandleLoginPath = '/handleLogin',
  DirectoryPath = '/directory',
  InvitePath = '/invite',

  AdminDashboardPath = '/admin/dashboard',
  AdminOrganizationsPath = '/admin/organizations',

  ProviderDashboardPath = '/provider/dashboard',
  ProviderOrganizationPath = '/provider/organization',
  ProviderServicesPath = '/provider/services',
  ProviderReferralPath = '/provider/referral',
  ProviderSettingsPath = '/provider/settings',

  AdvocateDashboardPath = '/advocate/dashboard',
  AdvocateDirectoryPath = '/advocate/directory',
  AdvocateDirectoryResultPath = '/advocate/directory/result',
  AdvocateClientsPath = '/advocate/clients',
}

export enum UrlParamNames {
  AdminOrganizationsPageIdUrlParamName = 'view',
  OrganizationIdUrlParamName = 'organizationId',
  ServiceIdUrlParamName = 'serviceId',
  ClientIdUrlParamName = 'clientId',
  DirectoryServiceTypeParamName = 'type',
  DirectoryServiceLocationParamName = 'location',
  DirectoryFiltersApplied = 'filtersApplied',
}
