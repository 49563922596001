import { LoadingSpinner } from 'src/components/loadingSpinner';

interface Props {
  text?: string;
  showIcon?: boolean;
}

export const PageLoading = (props: Props): JSX.Element => {
  return (
    <div className="d-flex flex-column h-100 justify-content-center align-items-center">
      {props.showIcon && <LoadingSpinner />}
      <div className={props.showIcon ? 'mt-1' : ''}>
        {props.text ?? 'Loading...'}
      </div>
    </div>
  );
};
