import { FormEvent, useState } from 'react';
import { Col, Container, Form } from 'react-bootstrap';
import { PrimaryButton } from 'src/components/primaryButton';
import { OrganizationRegistrationStep2Inputs } from 'src/components/organizationRegistrationStep2Inputs';
import { RegistrationHeader } from 'src/components/registrationHeader';
import { IdValuePair } from 'src/models/idValuePair';
import { Organization } from 'src/models/organization';
import {
  EmptyOrganizationRegistrationStep2ViewModel,
  OrganizationRegistrationStep2ViewModel,
} from 'src/models/organizationRegistrationStep2ViewModel';
import { useIdToken } from 'src/services/msal';
import {
  apiRoutes,
  authenticatedFetch,
  FetchMethod,
  useApi,
} from 'src/services/swr';
import {
  debounce,
  showErrorNotification,
  showInformationSavedNotification,
} from 'src/utils/helpers';

interface Props {
  onComplete: () => void;
}

export const OrganizationRegistrationStep2 = (props: Props): JSX.Element => {
  const { token } = useIdToken();

  const { isValidating: isLoadingTraits } = useApi<IdValuePair[]>(
    apiRoutes.organizationRegistrationStep2.traits
  );

  const organization = useApi<Organization>(apiRoutes.organization.get);

  const [state, setState] = useState<OrganizationRegistrationStep2ViewModel>(
    EmptyOrganizationRegistrationStep2ViewModel
  );

  const [isValidated, setIsValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingComplete, setIsSubmittingComplete] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (isSubmitting) {
      return;
    }

    const form = e.currentTarget;
    const isValid = form.checkValidity();
    setIsValidated(true);

    if (isValid) {
      setIsSubmitting(true);

      try {
        await authenticatedFetch<OrganizationRegistrationStep2ViewModel>(
          apiRoutes.organizationRegistrationStep2.submit,
          FetchMethod.POST,
          token,
          JSON.stringify(state)
        );
        setIsSubmittingComplete(true);
        await debounce();
        await organization.mutate();

        showInformationSavedNotification();
        props.onComplete();
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <RegistrationHeader
        title="Service Provider Registration"
        description="Step 2 of 2"
        isPageLoading={isLoadingTraits}
      />
      {!isLoadingTraits && (
        <Container>
          <Form validated={isValidated} onSubmit={handleSubmit}>
            <Form.Row>
              <OrganizationRegistrationStep2Inputs
                state={state}
                onStateChanged={(newState): void => setState(newState)}
                disableInputs={isSubmitting}
              />
            </Form.Row>
            <Form.Row className="my-2">
              <Col xs={12} sm={{ span: 2, offset: 10 }}>
                <PrimaryButton
                  content="Done"
                  isLoading={isSubmitting}
                  isComplete={isSubmittingComplete}
                />
              </Col>
            </Form.Row>
          </Form>
        </Container>
      )}
    </>
  );
};
