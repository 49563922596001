export interface Organization {
  email: string;
  id: number;
  isRegistrationComplete: boolean;
  isVerified: boolean;
  name: string;
  phoneNumber: string;
  registrationDate: Date;
  url: string;
}

export const EmptyOrganization: Organization = {
  email: '',
  id: 0,
  isRegistrationComplete: false,
  isVerified: false,
  name: '',
  phoneNumber: '',
  registrationDate: new Date(),
  url: '',
};
