import { useCallback, useEffect, useState } from 'react';
import { ContinueResourceRegistration } from 'src/components/continueResourceRegistration';
import { DynamicRegistrationSteps } from 'src/components/dynamicRegistrationSteps';
import { MenuLayout } from 'src/components/menuLayout';
import { PageLoading } from 'src/components/pageLoading';
import { ProviderMenuOptions } from 'src/models/providerMenuOptions';
import { Organization } from 'src/models/organization';
import {
  EmptyRegistrationDetails,
  RegistrationDetails,
} from 'src/models/registrationDetails';
import { OrganizationRegistrationContinue } from 'src/pages/provider/registration/Continue';
import { OrganizationRegistrationStep1 } from 'src/pages/provider/registration/Step1';
import { OrganizationRegistrationStep1Confirmation } from 'src/pages/provider/registration/Step1Confirmation';
import { OrganizationRegistrationStep2 as OrganizationRegistrationStep2 } from 'src/pages/provider/registration/Step2';
import { WelcomePage } from 'src/pages/provider/Welcome';
import { apiRoutes, useApi } from 'src/services/swr';
import { Routes, UrlParamNames } from 'src/utils/routes';
import { InvalidId } from 'src/utils/helpers';
import { ProfileRegistrationStep } from 'src/components/profileRegistrationStep';
import { ResourceType } from 'src/models/resource';

enum Page {
  Loading,
  Welcome,
  OrganizationRegistrationStep1,
  OrganizationRegistrationNotVerified,
  OrganizationRegistrationContinue,
  OrganizationRegistrationStep2,
  ServiceRegistrationStep1,
  ServiceRegistrationContinue,
  ServiceRegistrationStep2,
}

export const ProviderDashboard = (): JSX.Element => {
  const { data: organization, isValidating: isLoadingOrganization } =
    useApi<Organization>(apiRoutes.organization.get);
  const { data: incompleteService, isValidating: isLoadingProgress } =
    useApi<RegistrationDetails>(
      organization?.id
        ? apiRoutes.serviceRegistration.incompleteService
        : undefined,
      { dedupingInterval: 0 }
    );

  const [content, setContent] = useState<JSX.Element>(<></>);

  const updatePageContent = useCallback(
    (
      newPage: Page,
      registrationDetails: RegistrationDetails = EmptyRegistrationDetails
    ) => {
      switch (newPage) {
        case Page.Loading:
          setContent(<PageLoading />);
          break;
        case Page.OrganizationRegistrationStep1:
          setContent(
            <OrganizationRegistrationStep1
              onComplete={(): void =>
                updatePageContent(Page.OrganizationRegistrationNotVerified)
              }
            />
          );
          break;
        case Page.OrganizationRegistrationNotVerified:
          setContent(<OrganizationRegistrationStep1Confirmation />);
          break;
        case Page.OrganizationRegistrationContinue:
          setContent(
            <OrganizationRegistrationContinue
              onComplete={(): void =>
                updatePageContent(Page.OrganizationRegistrationStep2)
              }
            />
          );
          break;
        case Page.OrganizationRegistrationStep2:
          setContent(
            <OrganizationRegistrationStep2
              onComplete={(): void => updatePageContent(Page.Welcome)}
            />
          );
          break;
        case Page.ServiceRegistrationStep1:
          setContent(
            <ProfileRegistrationStep
              resourceType={ResourceType.Service}
              title="Service Registration"
              description="Let's add a service to your organization's profile! You will
                be able to manage and update information about your services at any time.
                Progress will be saved after each page so that you can return to complete
                your service registration. Please be thorough in answering the following
                questions so that TIRA can assist with making quality referrals to your
                organization."
              submitRoute={apiRoutes.serviceRegistration.submitProfile}
              onCancel={(): void => updatePageContent(Page.Welcome)}
              onComplete={(resourceId): void =>
                updatePageContent(Page.ServiceRegistrationStep2, {
                  id: resourceId,
                  name: '',
                })
              }
            />
          );
          break;
        case Page.ServiceRegistrationContinue:
          setContent(
            <ContinueResourceRegistration
              registration={registrationDetails}
              title="Service Registration"
              description={`Let's complete the service registration that you previously began for '${registrationDetails.name}'`}
              deleteRoute={apiRoutes.service.delete(registrationDetails.id)}
              onCancel={(): void => updatePageContent(Page.Welcome)}
              onComplete={(): void =>
                updatePageContent(
                  Page.ServiceRegistrationStep2,
                  registrationDetails
                )
              }
            />
          );
          break;
        case Page.ServiceRegistrationStep2:
          setContent(
            <DynamicRegistrationSteps
              title="Service Registration"
              resourcesRoute={apiRoutes.provider.services}
              progressRoute={apiRoutes.serviceRegistration.incompleteProgress}
              questionPageCountRoute={apiRoutes.serviceRegistration.questionPageCount(
                registrationDetails.id
              )}
              questionPageRoute={apiRoutes.serviceRegistration.questionPage}
              submitQuestionPageRoute={
                apiRoutes.serviceRegistration.submitQuestionPage
              }
              deleteRoute={apiRoutes.service.delete(registrationDetails.id)}
              resourcesPageUrl={Routes.ProviderServicesPath}
              resourceUrlParamName={UrlParamNames.ServiceIdUrlParamName}
              onCancel={(): void => updatePageContent(Page.Welcome)}
            />
          );
          break;
        default:
          setContent(
            <WelcomePage
              registerNewService={(): void =>
                updatePageContent(Page.ServiceRegistrationStep1)
              }
            />
          );
          break;
      }
    },
    []
  );

  useEffect(() => {
    if (isLoadingOrganization || isLoadingProgress) {
      updatePageContent(Page.Loading);
    } else if (organization?.id) {
      if (!organization.isVerified) {
        updatePageContent(Page.OrganizationRegistrationNotVerified);
      } else if (!organization.isRegistrationComplete) {
        updatePageContent(Page.OrganizationRegistrationContinue);
      } else {
        // Don't use optional chaining here because undefined !== InvalidId.
        if (incompleteService && incompleteService.id !== InvalidId) {
          updatePageContent(
            Page.ServiceRegistrationContinue,
            incompleteService
          );
        } else {
          updatePageContent(Page.Welcome);
        }
      }
    } else {
      // Not registered as a provider.
      updatePageContent(Page.OrganizationRegistrationStep1);
    }
  }, [
    incompleteService,
    isLoadingOrganization,
    isLoadingProgress,
    organization,
    updatePageContent,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [content]);

  return (
    <MenuLayout
      pageIndex={ProviderMenuOptions().findIndex(
        (o) => o.path === Routes.ProviderDashboardPath
      )}
      menuOptions={ProviderMenuOptions()}
    >
      {content}
    </MenuLayout>
  );
};
