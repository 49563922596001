import { FormEvent, useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ClientProfileInputs } from 'src/components/clientProfileInputs';
import { DynamicForm } from 'src/components/dynamicForm';
import { LoadingSpinner } from 'src/components/loadingSpinner';
import { Notes } from 'src/components/notes';
import { PrimaryButton } from 'src/components/primaryButton';
import { Referrals } from 'src/components/referrals';
import { ServiceProfileInputs } from 'src/components/serviceProfileInputs';
import { Answer } from 'src/models/answer';
import { Note } from 'src/models/note';
import { Referral } from 'src/models/referral';
import { ResourceType } from 'src/models/resource';
import {
  EmptyResourceDetails,
  ResourceDetails,
} from 'src/models/resourceDetails';
import { features } from 'src/utils/constants';
import {
  ClientProfileViewModel,
  EmptyClientProfileViewModel,
  EmptyServiceProfileViewModel,
  ResourceProfileViewModel,
  ServiceProfileViewModel,
} from 'src/models/resourceProfileViewModel';
import { Routes, UrlParamNames } from 'src/utils/routes';
import styles from './resourceLayout.module.scss';

interface Props {
  resourceType: ResourceType;
  resourceId?: number;
  resourceDetails?: ResourceDetails;
  isLoadingDetails?: boolean;
  isSavingDetails?: boolean;
  readOnly?: boolean;
  needsSave?: boolean;
  isValidated?: boolean;
  onProfileChanged?: (profile: ResourceProfileViewModel) => void;
  onDetailsChanged?: (pageIndex: number, answers: Answer[]) => void;
  onSaveDetails?: (e: FormEvent<HTMLFormElement>) => void;

  resourceReferrals?: Referral[];
  isLoadingReferrals?: boolean;
  onReferralUpdated?: (referral: Referral) => void;
  onReferralArchived?: (referral: Referral) => void;

  resourceNotes?: Note[];
  isLoadingNotes?: boolean;
  isSavingNote?: boolean;
  isSavingNoteComplete?: boolean;
  onSaveNote?: (text: string) => void;
}

const ReferralsIndex = -3;
const NotesIndex = -2;
const ProfileIndex = -1;

export const ResourceLayout = ({
  resourceType,
  resourceId,
  resourceDetails = EmptyResourceDetails,
  isLoadingDetails = false,
  isSavingDetails = false,
  readOnly = false,
  needsSave = false,
  isValidated = false,
  onProfileChanged,
  onDetailsChanged,
  onSaveDetails,
  resourceReferrals = [],
  isLoadingReferrals = false,
  onReferralUpdated,
  onReferralArchived,
  resourceNotes = [],
  isLoadingNotes = false,
  isSavingNote = false,
  isSavingNoteComplete = false,
  onSaveNote,
}: Props): JSX.Element => {
  const history = useHistory();
  const DefaultPageIndex = readOnly ? ProfileIndex : ReferralsIndex;

  const [selectedPageIndex, setSelectedPageIndex] = useState(DefaultPageIndex);

  const onProfileChangedLocal = useCallback(
    (profile: ResourceProfileViewModel): void => {
      if (onProfileChanged) {
        onProfileChanged(profile);
      }
    },
    [onProfileChanged]
  );

  const onDetailsChangedLocal = useCallback(
    (answers: Answer[], pageIndex: number): void => {
      if (onDetailsChanged) {
        onDetailsChanged(pageIndex, answers);
      }
    },
    [onDetailsChanged]
  );

  useEffect(() => {
    // Update the current state when the selected resource changes.
    setSelectedPageIndex(DefaultPageIndex);
  }, [DefaultPageIndex, resourceId]);

  return (
    <div className={styles.detailsBody}>
      <Row>
        <Col>
          <div className={styles.categories}>
            {isLoadingDetails && !resourceDetails ? (
              <div className={styles.loading}>
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {!readOnly && features.referrals && (
                  <div
                    className={`${styles.categoryItem} ${
                      selectedPageIndex === ReferralsIndex
                        ? styles.categoryActive
                        : ''
                    }`}
                    key={ReferralsIndex}
                    onClick={(): void => setSelectedPageIndex(ReferralsIndex)}
                  >
                    Referrals
                  </div>
                )}

                {!readOnly && resourceType === ResourceType.Client && (
                  <div
                    className={`${styles.categoryItem} ${
                      selectedPageIndex === NotesIndex
                        ? styles.categoryActive
                        : ''
                    }`}
                    key={NotesIndex}
                    onClick={(): void => setSelectedPageIndex(NotesIndex)}
                  >
                    Notes
                  </div>
                )}

                <div
                  className={`${styles.categoryItem} ${
                    selectedPageIndex === ProfileIndex
                      ? styles.categoryActive
                      : ''
                  }`}
                  onClick={(): void => setSelectedPageIndex(ProfileIndex)}
                >
                  Profile
                </div>

                {resourceDetails?.pages &&
                  resourceDetails.pages.map((page, index) => (
                    <div
                      className={`${styles.categoryItem} ${
                        index === selectedPageIndex ? styles.categoryActive : ''
                      }`}
                      key={page.id}
                      onClick={(): void => setSelectedPageIndex(index)}
                    >
                      {page.title}
                    </div>
                  ))}

                {!readOnly && resourceType === ResourceType.Client && (
                  <div className="d-none d-sm-block">
                    <div className={styles.categoryItem}>
                      <PrimaryButton
                        id="findServicesButton"
                        content="Find Services"
                        onClick={(): void =>
                          history.push(
                            `${Routes.AdvocateDirectoryPath}?${UrlParamNames.ClientIdUrlParamName}=${resourceId}`
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>

      {resourceId && resourceDetails && (
        <Row className="mx-1 my-3">
          <Col>
            <div>
              {selectedPageIndex === ReferralsIndex ? (
                <>
                  {!readOnly && resourceType === ResourceType.Client && (
                    <Col xs={12} className="d-sm-none">
                      <PrimaryButton
                        id="findServicesButton"
                        className="mt-3 mb-5"
                        content="Find Services"
                        onClick={(): void =>
                          history.push(
                            `${Routes.AdvocateDirectoryPath}?${UrlParamNames.ClientIdUrlParamName}=${resourceId}`
                          )
                        }
                      />
                    </Col>
                  )}
                  <Referrals
                    resourceType={resourceType}
                    resourceId={resourceId}
                    referrals={resourceReferrals}
                    isLoading={isLoadingReferrals}
                    onUpdated={onReferralUpdated}
                    onArchived={onReferralArchived}
                  />
                </>
              ) : selectedPageIndex === NotesIndex ? (
                <Notes
                  notes={resourceNotes}
                  isLoading={isLoadingNotes}
                  isSaving={isSavingNote}
                  isSavingComplete={isSavingNoteComplete}
                  onSave={onSaveNote}
                />
              ) : (
                <Form validated={isValidated} onSubmit={onSaveDetails}>
                  {selectedPageIndex === ProfileIndex ? (
                    <Form.Row>
                      {resourceType === ResourceType.Client ? (
                        <ClientProfileInputs
                          state={
                            resourceDetails === EmptyResourceDetails
                              ? EmptyClientProfileViewModel
                              : (resourceDetails.profile as ClientProfileViewModel)
                          }
                          onStateChanged={(profile): void =>
                            onProfileChangedLocal(
                              profile as ResourceProfileViewModel
                            )
                          }
                          disableInputs={!!isSavingDetails}
                          isEditMode={true}
                          readOnly={readOnly}
                        />
                      ) : resourceType === ResourceType.Service ? (
                        <ServiceProfileInputs
                          state={
                            resourceDetails === EmptyResourceDetails
                              ? EmptyServiceProfileViewModel
                              : (resourceDetails.profile as ServiceProfileViewModel)
                          }
                          onStateChanged={(profile): void =>
                            onProfileChangedLocal(
                              profile as ResourceProfileViewModel
                            )
                          }
                          disableInputs={!!isSavingDetails}
                          isEditMode={true}
                          readOnly={readOnly}
                        />
                      ) : (
                        <></>
                      )}
                    </Form.Row>
                  ) : (
                    <DynamicForm
                      questions={
                        resourceDetails.pages[selectedPageIndex].questions
                      }
                      answers={resourceDetails.pages[selectedPageIndex].answers}
                      onAnswersUpdated={(answers: Answer[]): void =>
                        onDetailsChangedLocal(answers, selectedPageIndex)
                      }
                      isDisabled={!!isSavingDetails}
                      readOnly={readOnly}
                    />
                  )}

                  {needsSave && (
                    <Form.Row className="my-2">
                      <Col
                        xs={12}
                        sm={{
                          span: 2,
                          offset: 10,
                        }}
                      >
                        <PrimaryButton
                          content="Save"
                          isLoading={isSavingDetails}
                        />
                      </Col>
                    </Form.Row>
                  )}
                </Form>
              )}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
