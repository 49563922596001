import {
  Configuration,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { useCallback, useEffect, useState } from 'react';
import { showErrorNotificationWithId } from 'src/utils/helpers';

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/README.md#msal-react-specific-concepts
// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/samples/msal-react-samples/nextjs-sample

export const msalConfig: Configuration = {
  auth: {
    clientId: 'b95fa6b4-ca0f-4e74-993e-5aa6a99df849',
    authority:
      'https://tiraauth.b2clogin.com/tiraauth.onmicrosoft.com/B2C_1_signup_signin',
    knownAuthorities: ['tiraauth.b2clogin.com'],
    redirectUri: `${window.location.origin}/handleLogin`,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage', // For supporting multiple tabs/windows
    storeAuthStateInCookie: true,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile', 'offline_access'],
  prompt: 'login',
};

/**
 * On login, we store the msal token in localStorage. Every time an API is called, we grab that token and use it in the request. If a 401 is hit, we know the token isn't valid so we call getToken() to refresh the token. If we have a current account in storage, that means we have a refresh token and can get a token silently. If there is no account or an error with the silent auth, we request an interactive login via popup
 */
export const useIdToken = (): {
  token: string | null;
  getToken: (allowRedirect: boolean) => Promise<void>;
} & Partial<MsalAuthenticationResult> => {
  const { accounts, inProgress, logger, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken] = useState(
    localStorage.getItem(
      `${accounts[0]?.homeAccountId}-${accounts[0]?.environment}-msalToken`
    )
  );

  const [redirectOnSilentFail, setRedirectOnSilentFail] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const {
    acquireToken: acquireTokenSilent,
    login,
    error,
    result,
  } = useMsalAuthentication(InteractionType.Silent, {
    ...loginRequest,
    account: accounts[0],
  });

  const getToken = useCallback(
    async (allowRedirect: boolean) => {
      setRedirectOnSilentFail(allowRedirect);

      if (inProgress === InteractionStatus.None && !isAuthenticating) {
        setIsAuthenticating(true);

        if (accounts.length) {
          try {
            // We already have an account and can try to refetch the token silently.
            await acquireTokenSilent();
          } catch {
            await login(InteractionType.Redirect, loginRequest);
          }
        } else if (!isAuthenticated) {
          // Get a token for a new user.
          await login(InteractionType.Redirect, loginRequest);
        }

        setRedirectOnSilentFail(false);
        setIsAuthenticating(false);
      }
    },
    [
      accounts.length,
      acquireTokenSilent,
      inProgress,
      isAuthenticated,
      isAuthenticating,
      login,
    ]
  );

  // When the login result is returned, save the token to localStorage.
  useEffect(() => {
    if (result) {
      setToken(result?.idToken);
      localStorage.setItem(
        `${result.account?.homeAccountId}-${result.account?.environment}-msalToken`,
        result?.idToken
      );
    }
  }, [result]);

  // Handle auth errors
  useEffect(() => {
    if (error) {
      if (error.errorCode === 'hash_empty_error') {
        // False-positive error - retrieve token silently on hash error
        getToken(false);
      } else if (error instanceof InteractionRequiredAuthError) {
        // Retry because we couldn't get a token silently.
        logger.error(
          'Unable to acquire token silently. User interaction required'
        );

        if (redirectOnSilentFail) {
          login(InteractionType.Redirect, loginRequest);
        }
      } else if (error.errorCode !== 'user_cancelled') {
        // Don't toast on login modal dismissal. Use the correlationId to prevent duplicate toasts.
        showErrorNotificationWithId(
          error.errorMessage ?? error.message,
          error.correlationId
        );
        logger.error(error.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, getToken, logger]);

  useEffect(() => {
    instance.addEventCallback((message: EventMessage) => {
      // Clear the token from storage on logout
      if (message.eventType === EventType.LOGOUT_END && accounts.length) {
        localStorage.removeItem(
          `${accounts[0]?.homeAccountId}-${accounts[0]?.environment}-msalToken`
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance.addEventCallback]);

  return { token, getToken, login };
};
