export enum ResourceType {
  Client,
  Service,
}

export type Resource = {
  id: number;
  lastUpdatedBy?: string;
  lastUpdatedOn?: Date;
  name: string;
};

export type Service = Resource & {
  type: ServiceKind;
  totalUnits: number;
  availabilityLastUpdatedBy?: string;
  availabilityLastUpdatedOn?: Date;
  availableUnits: number;
  unit: string;
  hasWaitlist: boolean;
  startDate: Date;
};

export type Client = Resource & {
  zipCode: string;
};

export const EmptyResource: Resource = {
  id: 0,
  name: '',
};

export const EmptyService: Service = {
  id: 0,
  name: '',
  type: 0,
  totalUnits: 0,
  availableUnits: 0,
  unit: '',
  hasWaitlist: false,
  startDate: new Date(),
};

export const EmptyClient: Client = {
  id: 0,
  name: '',
  zipCode: '',
};

export enum ServiceKind {
  ShortTermHousing = 1,
  LongTermHousing,
  Advocacy,
  MentalHealth,
  SubstanceUse,
  Education,
  Legal,
}
