import { useState } from 'react';
import { Col, Container, Form } from 'react-bootstrap';
import { CancelButton } from 'src/components/cancelButton';
import { ClientProfileInputs } from 'src/components/clientProfileInputs';
import { PrimaryButton } from 'src/components/primaryButton';
import { RegistrationHeader } from 'src/components/registrationHeader';
import { ServiceProfileInputs } from 'src/components/serviceProfileInputs';
import { ResourceType } from 'src/models/resource';
import {
  ClientProfileViewModel,
  EmptyProfileViewModel,
  ServiceProfileViewModel,
} from 'src/models/resourceProfileViewModel';
import { useIdToken } from 'src/services/msal';
import { authenticatedFetch, FetchMethod } from 'src/services/swr';
import {
  debounce,
  InvalidId,
  showErrorNotification,
  showInformationSavedNotification,
} from 'src/utils/helpers';

interface Props {
  resourceType: ResourceType;
  title: string;
  description: string;
  submitRoute: string;
  onCancel: () => void;
  onComplete: (resourceId: number) => void;
}

export const ProfileRegistrationStep = (props: Props): JSX.Element => {
  const { token } = useIdToken();

  const [state, setState] = useState(EmptyProfileViewModel);
  const [isValidated, setIsValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingComplete, setIsSubmittingComplete] = useState(false);

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (isSubmitting) {
      return;
    }

    const form = e.currentTarget;
    const isValid = form.checkValidity();
    setIsValidated(true);

    if (isValid) {
      setIsSubmitting(true);

      try {
        const result = await authenticatedFetch<number>(
          props.submitRoute,
          FetchMethod.POST,
          token,
          JSON.stringify(state)
        );
        setIsSubmittingComplete(true);
        await debounce();

        showInformationSavedNotification();
        props.onComplete(result ?? InvalidId);
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <RegistrationHeader title={props.title} description={props.description} />

      <Container>
        <Form validated={isValidated} onSubmit={handleSubmit}>
          <Form.Row>
            {props.resourceType === ResourceType.Client ? (
              <ClientProfileInputs
                state={state as ClientProfileViewModel}
                onStateChanged={(newState): void => {
                  setState(newState);
                }}
                disableInputs={isSubmitting}
              />
            ) : props.resourceType === ResourceType.Service ? (
              <ServiceProfileInputs
                state={state as ServiceProfileViewModel}
                onStateChanged={(newState): void => {
                  setState(newState);
                }}
                disableInputs={isSubmitting}
              />
            ) : (
              <></>
            )}
          </Form.Row>
          <Form.Row>
            <Col xs={12} sm={{ span: 2, offset: 8 }} className="my-2">
              <CancelButton
                content="Cancel"
                isDisabled={isSubmitting}
                onClick={props.onCancel}
              />
            </Col>
            <Col xs={12} sm={{ span: 2 }} className="my-2">
              <PrimaryButton
                content="Next"
                isLoading={isSubmitting}
                isComplete={isSubmittingComplete}
              />
            </Col>
          </Form.Row>
        </Form>
      </Container>
    </>
  );
};
