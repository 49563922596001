export type ResourceProfileViewModel = {
  name: string;
};

export type ClientProfileViewModel = ResourceProfileViewModel & {
  zipCode: string;
};

export type ServiceProfileViewModel = ResourceProfileViewModel & {
  type: number;
  startDate: Date;
  address: string;
  useOrganizationAddress: boolean;
  isUndisclosedAddress: boolean;
  zipCode: string;
  timeLimited: boolean;
  duration: number;
  durationUnits: number;
  allowExtensions: boolean;
  description: string;
  emailAddress: string;
  phoneNumber: string;
  totalUnits: number;
  hasWaitlist: boolean;
};

export const EmptyProfileViewModel: ResourceProfileViewModel = {
  name: '',
};

export const EmptyClientProfileViewModel: ClientProfileViewModel = {
  name: '',
  zipCode: '',
};

export const EmptyServiceProfileViewModel: ServiceProfileViewModel = {
  type: 0,
  name: '',
  startDate: new Date(),
  address: '',
  useOrganizationAddress: false,
  isUndisclosedAddress: false,
  zipCode: '',
  timeLimited: false,
  duration: 0,
  durationUnits: 0,
  allowExtensions: false,
  description: '',
  emailAddress: '',
  phoneNumber: '',
  totalUnits: 0,
  hasWaitlist: false,
};
