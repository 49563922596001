import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { CancelButton } from 'src/components/cancelButton';
import { PrimaryButton } from 'src/components/primaryButton';
import { TiraBrand } from 'src/components/tiraBrand';
import { RoleType, User } from 'src/models/user';
import { apiRoutes, useApi } from 'src/services/swr';
import { Routes } from 'src/utils/routes';
import styles from './HomePage.module.scss';
import { PrivacyLink, TiraLiveLink } from 'src/utils/constants';

const buttonClassName = 'btn-sm px-4 py-1';

export const HomePage = (): JSX.Element => {
  const history = useHistory();

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const { data: user, isValidating: isLoadingUser } = useApi<User>(
    isAuthenticated ? apiRoutes.user.get : undefined
  );

  const handleLoginClick = async (): Promise<void> => {
    accounts.length ? await instance.logout() : await instance.loginRedirect();
  };

  const canAccessProviderDashboard = (): boolean => {
    return (
      !!user &&
      (user.roles.includes(RoleType.OrganizationAdmin) ||
        user.roles.includes(RoleType.ServiceContributor))
    );
  };

  const canAccessAdvocateDashboard = (): boolean => {
    return (
      !!user &&
      (user.roles.includes(RoleType.OrganizationAdmin) ||
        user.roles.includes(RoleType.Advocate))
    );
  };

  // Can register a provider if they don't already have any roles for a provider.
  const canRegisterProvider = (): boolean => {
    return (
      !!user &&
      !user.roles.includes(RoleType.OrganizationAdmin) &&
      !user.roles.includes(RoleType.ServiceContributor) &&
      !user.roles.includes(RoleType.Advocate)
    );
  };

  return (
    <Container fluid className={styles.appBackground}>
      <Row>
        <Col xs={12} sm={{ offset: 2, span: 8 }} xl={{ offset: 3, span: 6 }}>
          <Container fluid>
            <div className={styles.content}>
              <div className="mb-3">
                <TiraBrand />
              </div>
              <div>Your one-stop Resource Agent for survivors</div>
              <div className="mt-3">
                Sign up with TIRA to find available services that match the
                needs and preferences of survivors of trafficking and sexual
                exploitation
              </div>

              <div className="mt-3">
                {isAuthenticated ? (
                  <CancelButton
                    content="Sign Out"
                    className={buttonClassName}
                    onClick={handleLoginClick}
                  />
                ) : (
                  <PrimaryButton
                    content="Sign In"
                    className={buttonClassName}
                    onClick={handleLoginClick}
                  />
                )}
              </div>

              <div className="d-flex flex-column align-items-center justify-content-center mt-3 mb-3">
                <Row>
                  <Col xs={12} lg={4} className={styles.mainCard}>
                    <h5>Organization</h5>
                    <PrimaryButton
                      content={
                        !canAccessProviderDashboard() && canRegisterProvider()
                          ? 'Register'
                          : 'Dashboard'
                      }
                      className={buttonClassName}
                      isLoading={isLoadingUser}
                      isDisabled={
                        !canAccessProviderDashboard() && !canRegisterProvider()
                      }
                      onClick={(): void =>
                        history.push(Routes.ProviderDashboardPath)
                      }
                    />
                    <div className={styles.bodyText}>
                      As an organization, you can register with TIRA to provide
                      services and/or work with clients
                    </div>
                  </Col>

                  <Col className="d-block d-lg-none">
                    <hr />
                  </Col>

                  <Col xs={12} lg={4} className={styles.mainCard}>
                    <h5>Advocate</h5>
                    <PrimaryButton
                      content="Dashboard"
                      className={buttonClassName}
                      isLoading={isLoadingUser}
                      isDisabled={!canAccessAdvocateDashboard()}
                      onClick={(): void =>
                        history.push(Routes.AdvocateDashboardPath)
                      }
                    />
                    <div className={styles.bodyText}>
                      As an advocate, you can assist survivors with finding
                      available services that meet their unique needs and
                      preferences
                    </div>
                  </Col>

                  <Col className="d-block d-lg-none">
                    <hr />
                  </Col>

                  <Col xs={12} lg={4} className={styles.mainCard}>
                    <h5>Public</h5>
                    <PrimaryButton
                      content="Directory"
                      className={buttonClassName}
                      isLoading={isLoadingUser}
                      onClick={(): void => history.push(Routes.DirectoryPath)}
                    />
                    <div className={styles.bodyText}>
                      Explore TIRA&apos;s public directory of service providers
                    </div>
                  </Col>
                </Row>
              </div>
              <Container className={styles.secondaryActionContainer}>
                <Row>
                  <Col xs={12} lg={6} className={styles.mainCard}>
                    <CancelButton
                      content="Learn More"
                      className={buttonClassName}
                      onClick={(): void => {
                        window.open(TiraLiveLink, '_blank', 'noopener');
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={6} className={styles.mainCard}>
                    <CancelButton
                      content="Privacy Policy"
                      className={buttonClassName}
                      onClick={(): void => {
                        window.open(PrivacyLink, '_blank', 'noopener');
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
