import {
  faCalendar,
  faHome,
  faSignOutAlt,
  faSearchLocation,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { CheckMenuOptions, MenuOption } from 'src/models/menuOption';
import { RoleType } from 'src/models/user';
import { Routes } from 'src/utils/routes';

const advocateMenuOptions: MenuOption[] = [
  {
    name: 'Home',
    icon: faHome,
    path: Routes.HomePath,
    requiresRole: [RoleType.OrganizationAdmin, RoleType.Advocate],
  },
  {
    name: 'Dashboard',
    icon: faCalendar,
    path: Routes.AdvocateDashboardPath,
    requiresRole: [RoleType.OrganizationAdmin, RoleType.Advocate],
  },
  {
    name: 'Clients',
    icon: faUsers,
    path: Routes.AdvocateClientsPath,
    requiresRole: [RoleType.OrganizationAdmin, RoleType.Advocate],
    requiresRegistration: true,
  },
  {
    name: 'Directory',
    icon: faSearchLocation,
    path: Routes.AdvocateDirectoryPath,
    requiresRole: [RoleType.OrganizationAdmin, RoleType.Advocate],
    requiresRegistration: true,
  },
  {
    name: 'Logout',
    icon: faSignOutAlt,
  },
];

export const AdvocateMenuOptions = (): MenuOption[] => {
  return CheckMenuOptions(advocateMenuOptions);
};
