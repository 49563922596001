import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Prompt, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { CancelButton } from 'src/components/cancelButton';
import { PrimaryButton } from 'src/components/primaryButton';

interface Props {
  needsSave: boolean;
}

export const BlockNavigation: React.FC<Props> = ({
  needsSave,
  children,
}): JSX.Element => {
  const history = useHistory();

  const [showConfirmNavigation, setShowConfirmNavigation] = useState(false);
  const [blockedNavigationLocation, setBlockedNavigationLocation] =
    useState('');
  const [isBlockedNavigationConfirmed, setIsBlockedNavigationConfirmed] =
    useState(false);

  const handleBlockedNavigation = (location: Location): boolean => {
    if (!needsSave || isBlockedNavigationConfirmed) {
      return true;
    }

    setBlockedNavigationLocation(location.pathname + location.search);
    setShowConfirmNavigation(true);
    return false;
  };

  useEffect(() => {
    // Go to the location the user was navigating to before it was blocked.
    if (isBlockedNavigationConfirmed && blockedNavigationLocation) {
      history.push(blockedNavigationLocation);
      setBlockedNavigationLocation('');
      setIsBlockedNavigationConfirmed(false);
      setShowConfirmNavigation(false);
    }
  }, [isBlockedNavigationConfirmed, blockedNavigationLocation, history]);

  return (
    <>
      {children}

      <Prompt message={handleBlockedNavigation} />

      <Modal
        centered
        show={showConfirmNavigation}
        onHide={(): void => setShowConfirmNavigation(false)}
      >
        <Modal.Header closeButton closeLabel="close">
          <Modal.Title>Unsaved Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to leave this service and discard changes?
        </Modal.Body>
        <Modal.Footer>
          <CancelButton
            content="Discard Changes"
            onClick={(): void => setIsBlockedNavigationConfirmed(true)}
          />
          <PrimaryButton
            content="Keep Changes"
            onClick={(): void => setShowConfirmNavigation(false)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
