import { MenuLayout } from 'src/components/menuLayout';
import { OrganizationLayout } from 'src/components/organizationLayout';
import { Organization } from 'src/models/organization';
import { ProviderMenuOptions } from 'src/models/providerMenuOptions';
import { apiRoutes, useApi } from 'src/services/swr';
import { Routes } from 'src/utils/routes';

export const ProviderOrganization = (): JSX.Element => {
  const { mutate: getOrganization } = useApi<Organization>(
    apiRoutes.organization.get
  );

  const onOrganizationNameChanged = async (): Promise<void> => {
    await getOrganization();
  };

  return (
    <>
      <MenuLayout
        pageIndex={ProviderMenuOptions().findIndex(
          (o) => o.path === Routes.ProviderOrganizationPath
        )}
        menuOptions={ProviderMenuOptions()}
      >
        <OrganizationLayout
          detailsRoute={apiRoutes.organizationAdmin.details}
          onNameChanged={onOrganizationNameChanged}
        />
      </MenuLayout>
    </>
  );
};
