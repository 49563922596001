import { Form } from 'react-bootstrap';

interface Props {
  allBoxIds?: number[] | string[];
  allAreSelected: () => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (newCheckBoxes: any) => void;
  disableInputs?: boolean;
}

export const SelectAllButton = (props: Props): JSX.Element => {
  const selectAllChanged = (): void => {
    // if all boxes are selected, deselects all boxes
    //    by assigning an empty array
    // else, selects all boxes
    //    by assigning all boxes as "selected" using the array passed in
    const newCheckBoxes = props.allAreSelected() ? [] : props.allBoxIds;
    // the part that actually changes the parent state
    props.onChange(newCheckBoxes);
  };

  return (
    <Form.Check
      label={'Select All'}
      checked={props.allAreSelected()}
      onChange={selectAllChanged}
      disabled={props.disableInputs}
    />
  );
};
