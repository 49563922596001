import { useEffect, useState } from 'react';
import { Col, Form, FormControlProps } from 'react-bootstrap';
import { ClientProfileViewModel } from 'src/models/resourceProfileViewModel';
import { getRandomNumberString } from 'src/utils/helpers';

const IdentifierDigits = 4;
const IdentifierDivider = '-';
const IdentifierHelp = 'Enter initials';

interface Props {
  state: ClientProfileViewModel;
  onStateChanged: (state: ClientProfileViewModel) => void;
  isEditMode?: boolean;
  disableInputs?: boolean;
  readOnly?: boolean;
}

export const ClientProfileInputs = ({
  state,
  onStateChanged,
  isEditMode,
  disableInputs,
  readOnly,
}: Props): JSX.Element => {
  const [identifier, setIdentifier] = useState({
    firstInitial: '',
    middleInitial: '',
    lastInitial: '',
    code: '',
  });

  const updateIdentifier = (
    first: string,
    middle: string,
    last: string
  ): void => {
    setIdentifier({
      ...identifier,
      firstInitial: first,
      middleInitial: middle,
      lastInitial: last,
    });

    const newIdentifier =
      `${first}${middle}${last}${IdentifierDivider}${identifier.code}`.toLocaleUpperCase();

    onStateChanged({
      ...state,
      name: newIdentifier,
    });
  };

  useEffect(() => {
    // Generate a identifier code when the component loads if one doesn't already exist.
    if (
      !isEditMode &&
      (!state.name.length || !state.name.includes(IdentifierDivider))
    ) {
      setIdentifier({
        ...identifier,
        code: getRandomNumberString(IdentifierDigits),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col xs={6} sm={12} md={3} lg={12}>
        <Form.Group controlId="formIdentifier">
          <Form.Label className="font-weight-bold">
            Client Identifier
          </Form.Label>
          <br />
          <Form.Label className="font-italic" id="formIdentifier">
            {state.name || IdentifierHelp}
          </Form.Label>
        </Form.Group>
      </Col>

      {!isEditMode && (
        <>
          <InputCol
            label="First Initial"
            id="formFirstInitial"
            value={identifier.firstInitial}
            onChange={(e): void =>
              updateIdentifier(
                e.target.value,
                identifier.middleInitial,
                identifier.lastInitial
              )
            }
            readOnly={readOnly}
            disableInputs={disableInputs}
            required={true}
          />
          <InputCol
            label="Middle Initial"
            id="formMiddleInitial"
            value={identifier.middleInitial}
            onChange={(e): void =>
              updateIdentifier(
                identifier.firstInitial,
                e.target.value,
                identifier.lastInitial
              )
            }
            readOnly={readOnly}
            disableInputs={disableInputs}
            required={false}
          />
          <InputCol
            label="Last Initial"
            id="formLastInitial"
            value={identifier.lastInitial}
            onChange={(e): void =>
              updateIdentifier(
                identifier.firstInitial,
                identifier.middleInitial,
                e.target.value
              )
            }
            readOnly={readOnly}
            disableInputs={disableInputs}
            required={true}
          />
        </>
      )}
      <Col xs={12} lg={3}>
        <Form.Group controlId="formZipCode">
          <Form.Label className="font-weight-bold">ZIP Code</Form.Label>
          {readOnly ? (
            <p className="font-italic">{state.zipCode}</p>
          ) : (
            <Form.Control
              size="sm"
              type="text"
              pattern="[0-9]{5}"
              maxLength={5}
              placeholder="ZIP Code"
              title="ZIP Code must be 5 digits"
              id="formZipCode"
              value={state.zipCode || ''}
              onChange={(e): void =>
                onStateChanged({
                  ...state,
                  zipCode: e.target.value,
                })
              }
              disabled={disableInputs}
              required
            />
          )}
        </Form.Group>
      </Col>
    </>
  );
};

const InputCol = ({
  label,
  id,
  value,
  onChange,
  readOnly,
  disableInputs,
  required,
}: {
  label: string;
  id: string;
  value: string;
  onChange: FormControlProps['onChange'];
  readOnly?: boolean;
  disableInputs?: boolean;
  required?: boolean;
}): JSX.Element => {
  return (
    <Col xs={6} sm={4} md={3}>
      <Form.Group controlId="formInput">
        <Form.Label className="font-weight-bold">{label}</Form.Label>
        {readOnly ? (
          <p className="font-italic">{value}</p>
        ) : (
          <Form.Control
            size="sm"
            type="text"
            pattern="[A-Za-z]{1}"
            title="Must be 1 letter"
            placeholder={label}
            maxLength={1}
            id={id}
            value={value}
            onChange={onChange}
            disabled={disableInputs}
            required={required}
          />
        )}
      </Form.Group>
    </Col>
  );
};
