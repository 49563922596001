import { createElement, forwardRef, Ref } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';

interface Props {
  date: Date;
  size?: 'sm' | 'lg';
  showYearDropdown?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onChanged?: (date: Date) => void;
}

export const SingleDatePicker = (props: Props): JSX.Element => {
  // This is the function signature for ReactDatePicker's onChange().
  // The date picker is in single date mode so the param will always be a single date.
  const onSelectedDateChanged = (
    date: Date | [Date | null, Date | null] | null
  ): void => {
    if (date instanceof Date) {
      props.onChanged?.(date);
    }
  };

  const DatePickerInput = forwardRef(
    (
      {
        value,
        onClick,
      }: {
        value: string;
        onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
      },
      ref: Ref<HTMLInputElement>
    ) =>
      props.readOnly ? (
        <p className="font-italic">{value}</p>
      ) : (
        <Form.Control
          size={props.size || 'sm'}
          type="text"
          placeholder="Date"
          defaultValue={value}
          onClick={onClick}
          ref={ref}
          required
        />
      )
  );

  DatePickerInput.displayName = 'DatePickerInput';

  return (
    <DatePicker
      selected={props.date ? new Date(props.date) : new Date()}
      onChange={onSelectedDateChanged}
      onYearChange={onSelectedDateChanged}
      customInput={createElement(DatePickerInput)}
      dateFormat="MMMM dd, yyyy"
      showYearDropdown={props.showYearDropdown}
      scrollableYearDropdown={props.showYearDropdown}
      yearDropdownItemNumber={100}
      disabled={props.disabled}
    />
  );
};
