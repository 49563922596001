import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIdToken } from 'src/services/msal';
import { Routes } from 'src/utils/routes';

export const HandleLogin = (): JSX.Element => {
  const { push } = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  useIdToken();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      push(Routes.HomePath);
    }
  }, [inProgress, isAuthenticated, push]);

  return <></>;
};
