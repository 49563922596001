import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { loginRequest } from 'src/services/msal';
import { Routes } from 'src/utils/routes';

export interface ProtectedRouteProps extends RouteProps {
  path: string;
  restrictedPath?: string;
  authenticationPath?: string;
  isAllowed?: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (
  props: ProtectedRouteProps
) => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (
      !props.isAllowed &&
      !isAuthenticated &&
      inProgress === InteractionStatus.None
    ) {
      if (accounts.length) instance.acquireTokenSilent(loginRequest);
      else instance.acquireTokenRedirect(loginRequest);
    }
  }, [inProgress, isAuthenticated, accounts, instance, props.isAllowed]);

  // Allow overrides for restricted paths
  if (props.isAllowed ?? isAuthenticated) {
    return <Route {...props} />;
  }

  return (
    <Redirect
      to={{
        pathname: props.restrictedPath ?? Routes.HomePath,
        state: props.location?.pathname,
      }}
    />
  );
};
