import { useMsal } from '@azure/msal-react';
import { Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { Link } from 'react-router-dom';
import logoImage from 'src/images/logo.svg';
import { MenuOption } from 'src/models/menuOption';
import styles from './menuNavbar.module.scss';
import { PrivacyLink } from 'src/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

interface Props {
  menuOptions: MenuOption[];
  selectedIndex: number;
  navDropdownClassName?: string;
}

export const MenuNavbar = (props: Props): JSX.Element => {
  const { instance } = useMsal();
  const getNavLinkClassNameDropdown = (index: number): string =>
    index === props.selectedIndex ? 'active' : '';

  const onClick = (opt: MenuOption): void => {
    if (!opt.isDisabled) {
      switch (opt.name) {
        case 'Logout':
          instance.logout();
          return;
        default:
          return;
      }
    }
  };

  return (
    <Navbar className={styles.navbar} fixed="top">
      <Nav className={styles.nav}>
        <div className="d-flex">
          <Navbar.Collapse>
            <NavDropdown
              title={props.menuOptions[props.selectedIndex].name}
              id="dropdown"
              className={props.navDropdownClassName || ''}
            >
              <div>
                {props.menuOptions.map((opt, index) => (
                  <NavDropdown.Item
                    key={index}
                    as={opt.path ? Link : DropdownItem}
                    to={opt.path}
                    className={getNavLinkClassNameDropdown(index)}
                    onClick={(): void => onClick(opt)}
                    disabled={opt.isDisabled}
                  >
                    {opt.name}
                  </NavDropdown.Item>
                ))}
                <NavDropdown.Item
                  onClick={(): void => {
                    window.open(PrivacyLink, '_blank', 'noopener');
                  }}
                >
                  Privacy Policy&ensp;
                  <FontAwesomeIcon icon={faExternalLink} />
                </NavDropdown.Item>
              </div>
            </NavDropdown>
          </Navbar.Collapse>
        </div>
        <NavItem className={styles.brand}>
          <Navbar.Brand>
            <img
              src={logoImage}
              className={styles.brandImage}
              alt="TIRA logo"
            />
          </Navbar.Brand>
          <Navbar.Brand>
            <div className={styles.brandName}>T I R A</div>
          </Navbar.Brand>
        </NavItem>
      </Nav>
    </Navbar>
  );
};
