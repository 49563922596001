import { MsalProvider } from '@azure/msal-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SWRConfig } from 'swr';
import { App } from './App';
import { msalInstance } from './services/msal';
import { initializeIcons } from '@fluentui/font-icons-mdl2';

const baseUrl =
  document.getElementsByTagName('base')[0].getAttribute('href') ??
  location.href;
const rootElement = document.getElementById('root');

initializeIcons();

const Index = (): JSX.Element => {
  return (
    <React.StrictMode>
      <BrowserRouter basename={baseUrl}>
        <MsalProvider instance={msalInstance}>
          <SWRConfig>
            <App />
            <ToastContainer
              position="top-right"
              newestOnTop={false}
              closeOnClick
              draggable
              // stacked <- would be great to enable but v10 requires react 18
            />
          </SWRConfig>
        </MsalProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

ReactDOM.render(<Index />, rootElement);
