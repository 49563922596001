import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Col, Form, InputGroup, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { PageLoading } from 'src/components/pageLoading';
import { PrimaryButton } from 'src/components/primaryButton';
import { Note } from 'src/models/note';
import { CalendarStrings, Locale } from 'src/utils/helpers';
import styles from './notes.module.scss';

const MaxNoteLength = 2000;

interface Props {
  notes: Note[];
  isLoading?: boolean;
  isSaving?: boolean;
  isSavingComplete?: boolean;
  onSave?: (text: string) => void;
}

export const Notes = ({
  notes,
  isLoading,
  isSaving,
  isSavingComplete,
  onSave,
}: Props): JSX.Element => {
  const [text, setText] = useState('');
  const [filter, setFilter] = useState('');
  const [displayedNotes, setDisplayedNotes] = useState<Note[] | undefined>([]);

  const updateDisplayedNotes = useCallback((): void => {
    if (!filter) {
      setDisplayedNotes(notes);
    } else {
      const filterLower = filter.toLowerCase();
      setDisplayedNotes(
        notes?.filter(
          (n) =>
            n.text.toLowerCase().includes(filterLower) ||
            n.createdBy?.toLowerCase().includes(filterLower)
        )
      );
    }
  }, [filter, notes]);

  const onInputKey = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      // Prevent newline in text.
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onSaveClick = (): void => {
    onSave?.(text);
  };

  useEffect(() => {
    // Clear the text when saving completes.
    setText('');
  }, [isSavingComplete]);

  useEffect(() => {
    // Update the displayed notes when the filter changes
    updateDisplayedNotes();
  }, [filter, updateDisplayedNotes]);

  useEffect(() => {
    // Update the displayed notes when the backing data changes.
    updateDisplayedNotes();
  }, [notes, updateDisplayedNotes]);

  return (
    <>
      {isLoading && !notes ? (
        <PageLoading />
      ) : (
        <>
          <Alert variant="primary">
            <small className={styles.notice}>
              <b>Important:</b> Please avoid including any personal data. TIRA
              is a secure platform but is not intended to handle personal data.
            </small>
          </Alert>

          <div className="mt-3">
            <b className={styles.sectionHeader}>New Note:</b>
          </div>
          <InputGroup>
            <Form.Control
              size="sm"
              as="textarea"
              rows={2}
              placeholder="Enter note..."
              value={text}
              onChange={(e): void => setText(e.target.value)}
              onKeyDown={onInputKey}
              disabled={isSaving}
              style={{ resize: 'none' }}
              maxLength={MaxNoteLength}
              required
            />
            <PrimaryButton
              content="Save"
              size="sm"
              noBlock={true}
              isLoading={isSaving}
              isComplete={isSavingComplete}
              onClick={onSaveClick}
            />
          </InputGroup>
          <small className={styles.charactersRemaining}>
            {MaxNoteLength - text.length}/{MaxNoteLength} remaining
          </small>

          {notes && notes.length > 0 && (
            <>
              <div className="mt-3">
                <b className={styles.sectionHeader}>Filter Notes:</b>
              </div>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Enter text to filter notes..."
                value={filter}
                onChange={(e): void => setFilter(e.target.value)}
                disabled={isSaving}
                required
              />
            </>
          )}

          <div className="mt-3">
            {displayedNotes &&
              displayedNotes.map((note, index) => (
                <div key={`note${index}`} className={styles.note}>
                  <Row>
                    <Col xs={12} md>
                      <b>{note.text}</b>
                    </Col>
                    <Col xs={12} md={'auto'} className="text-md-right">
                      <small>
                        <Moment
                          calendar={CalendarStrings}
                          locale={Locale}
                          local
                        >
                          {note.createdOn}
                        </Moment>
                      </small>
                      {note.createdBy && (
                        <small>{` by ${note.createdBy}`}</small>
                      )}
                    </Col>
                  </Row>
                  <hr className="mt-1 mb-1" />
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};
