import { Answer } from './answer';
import { Question } from './question';
import { ResourceProfileViewModel } from './resourceProfileViewModel';

export type ResourceDetails = {
  profile: ResourceProfileViewModel;
  pages: ResourceDetailsPage[];
  lastUpdatedBy: string | null;
  lastUpdatedOn: Date | null;
};

type ResourceDetailsPage = {
  id: number;
  title: string;
  questions: Question[];
  answers: Answer[];
};

export const EmptyResourceDetails: ResourceDetails = {} as ResourceDetails;
