import { useLocation } from 'react-router-dom';
import { MenuLayout } from 'src/components/menuLayout';
import { ResourceResult } from 'src/components/resourceResult';
import { ProviderMenuOptions } from 'src/models/providerMenuOptions';
import { ResourceType } from 'src/models/resource';
import { ResourceDetails } from 'src/models/resourceDetails';
import { apiRoutes, useApi } from 'src/services/swr';
import { Routes, UrlParamNames } from 'src/utils/routes';

export const ProviderReferral = (): JSX.Element => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const clientIdParam = urlParams.get(UrlParamNames.ClientIdUrlParamName);

  const { data: clientDetails, isValidating: isLoading } =
    useApi<ResourceDetails>(
      clientIdParam
        ? apiRoutes.api.clientDetails(Number(clientIdParam))
        : undefined
    );

  return (
    <MenuLayout
      pageIndex={ProviderMenuOptions().findIndex(
        (o) => o.path === Routes.ProviderServicesPath
      )}
      menuOptions={ProviderMenuOptions()}
    >
      <ResourceResult
        resourceType={ResourceType.Client}
        resourceId={clientIdParam ? Number(clientIdParam) : 0}
        resourceDetails={clientDetails}
        isLoading={isLoading}
      />
    </MenuLayout>
  );
};
