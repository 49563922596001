import { useCallback, useEffect, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomTooltip } from 'src/components/customTooltip';
import { MenuLayout } from 'src/components/menuLayout';
import { OrganizationLayout } from 'src/components/organizationLayout';
import { PageLoading } from 'src/components/pageLoading';
import { ResourcesLayout } from 'src/components/resourcesLayout';
import { UserInvitesAdminLayout } from 'src/components/userInvitesAdminLayout';
import { UsersLayout } from 'src/components/usersLayout';
import { AdminMenuOptions } from 'src/models/adminMenuOptions';
import { EmptyOrganization, Organization } from 'src/models/organization';
import { ResourceType } from 'src/models/resource';
import { apiRoutes, useApi } from 'src/services/swr';
import { Routes, UrlParamNames } from 'src/utils/routes';
import styles from './Organizations.module.scss';

enum PageId {
  Organization,
  Services,
  Clients,
  Users,
  UserInvites,
}

interface Page {
  id: PageId;
  name: string;
}

const pages: Page[] = [
  { id: PageId.Organization, name: 'Organization' },
  { id: PageId.Services, name: 'Services' },
  { id: PageId.Clients, name: 'Clients' },
  { id: PageId.Users, name: 'Users' },
  { id: PageId.UserInvites, name: 'User Invites' },
];

export const AdminOrganizations = (): JSX.Element => {
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  // Unique URLs are needed when switching between different components where
  // data can be edited so that the organization and services components can
  // recognize the URL change and prompt to save unsaved changes.
  const pageIdUrlParam = urlParams.get(
    UrlParamNames.AdminOrganizationsPageIdUrlParamName
  );
  const organizationIdUrlParam = urlParams.get(
    UrlParamNames.OrganizationIdUrlParamName
  );

  const {
    data: organizations,
    isValidating: isLoadingOrganizations,
    mutate: getOrganizations,
  } = useApi<Organization[]>(apiRoutes.systemAdmin.verifiedOrganizations);

  const [selectedOrganization, setSelectedOrganization] =
    useState(EmptyOrganization);

  const [selectedPage, setSelectedPage] = useState(pages[0]);

  const setSelectedOrganizationFromUrl = useCallback(() => {
    if (organizations?.length) {
      setSelectedOrganization(
        organizationIdUrlParam
          ? organizations.find(
              (organization) =>
                organization.id === Number(organizationIdUrlParam)
            ) ?? organizations[0]
          : organizations[0]
      );
    } else {
      setSelectedOrganization(EmptyOrganization);
    }
  }, [organizationIdUrlParam, organizations]);

  const setSelectedPageFromUrl = useCallback(() => {
    setSelectedPage(
      pageIdUrlParam
        ? pages.find(
            (page) => page.id === PageId[pageIdUrlParam as keyof typeof PageId]
          ) ?? pages[0]
        : pages[0]
    );
  }, [pageIdUrlParam]);

  useEffect(() => {
    setSelectedOrganizationFromUrl();
  }, [organizationIdUrlParam, organizations, setSelectedOrganizationFromUrl]);

  useEffect(() => {
    setSelectedPageFromUrl();
  }, [pageIdUrlParam, setSelectedPageFromUrl]);

  useEffect(() => {
    // When there is no organization ID in the URL and a default service is selected, update the URL
    if (selectedOrganization?.id > 0 && !organizationIdUrlParam) {
      urlParams.set(
        UrlParamNames.OrganizationIdUrlParamName,
        selectedOrganization.id.toString()
      );
      history.push(window.location.pathname + '?' + urlParams.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, selectedOrganization]);

  useEffect(() => {
    // When there is no page in the URL update the URL.
    if (!pageIdUrlParam) {
      urlParams.set(
        UrlParamNames.AdminOrganizationsPageIdUrlParamName,
        PageId[pages[0].id]
      );
      history.push(window.location.pathname + '?' + urlParams.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, pageIdUrlParam]);

  const onSelectedOrganizationNameChanged = async (): Promise<void> => {
    await getOrganizations();
  };

  return (
    <MenuLayout
      pageIndex={AdminMenuOptions.findIndex(
        ({ path }) => path === Routes.AdminOrganizationsPath
      )}
      menuOptions={AdminMenuOptions}
    >
      <Row className={styles.header}>
        <Col className="d-flex justify-content-center align-items-center">
          <div>
            <div className={styles.pageTitle}>
              {isLoadingOrganizations ? (
                <div className="d-flex justify-content-center align-items-center">
                  <PageLoading showIcon={false} />
                </div>
              ) : !organizations?.length ? (
                <div className="d-flex align-items-center justify-content-center">
                  <h3>No organizations found</h3>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <span>
                    {selectedOrganization.name ?? 'Select an organization'}
                  </span>

                  <Dropdown alignRight={true}>
                    <CustomTooltip
                      popup="Change organization"
                      content={
                        <Dropdown.Toggle
                          aria-label="Select organization"
                          className={styles.pageTitleButton}
                          variant="outline-dark"
                        />
                      }
                    />
                    <Dropdown.Menu>
                      {organizations?.map((organization) => (
                        <Dropdown.Item
                          key={organization.id}
                          active={organization.id === selectedOrganization.id}
                          onClick={(): void => {
                            urlParams.set(
                              UrlParamNames.OrganizationIdUrlParamName,
                              organization.id.toString()
                            );
                            history.push(
                              window.location.pathname +
                                '?' +
                                urlParams.toString()
                            );
                          }}
                        >
                          {organization.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
            <div className={styles.line} />
          </div>
        </Col>
      </Row>

      {/* Not using "organizations?length &&" here because if it is false it displays "0" */}
      {organizations && organizations.length > 0 && (
        <Row>
          <Col>
            <div className={styles.categories}>
              {pages.map((page) => (
                <div
                  className={`${styles.categoryItem} ${
                    page.id === selectedPage.id ? styles.categoryActive : ''
                  }`}
                  key={page.id}
                  onClick={(): void => {
                    urlParams.set(
                      UrlParamNames.AdminOrganizationsPageIdUrlParamName,
                      PageId[page.id]
                    );
                    history.push(
                      window.location.pathname + '?' + urlParams.toString()
                    );
                  }}
                >
                  {page.name}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          {selectedPage.id === PageId.Organization && (
            <OrganizationLayout
              detailsRoute={
                selectedOrganization !== EmptyOrganization
                  ? apiRoutes.systemAdmin.organizationDetails(
                      selectedOrganization.id
                    )
                  : undefined
              }
              hideName={true}
              onNameChanged={onSelectedOrganizationNameChanged}
            />
          )}

          {selectedPage.id === PageId.Services && (
            <ResourcesLayout
              resourceType={ResourceType.Service}
              resourcesRoute={
                selectedOrganization !== EmptyOrganization
                  ? apiRoutes.systemAdmin.organizationServices(
                      selectedOrganization.id
                    )
                  : undefined
              }
              detailsRoute={(resourceId): string | undefined =>
                selectedOrganization !== EmptyOrganization
                  ? apiRoutes.systemAdmin.organizationServiceDetails(
                      selectedOrganization.id,
                      resourceId
                    )
                  : undefined
              }
              deleteRoute={(resourceId): string =>
                apiRoutes.service.delete(resourceId)
              }
              dashboardPath={Routes.ProviderDashboardPath}
              resourcesPageUrl={Routes.ProviderServicesPath}
              resourceIdUrlParamName={UrlParamNames.ServiceIdUrlParamName}
              hideCards={true}
              hideNoResourcesLink={true}
            />
          )}

          {selectedPage.id === PageId.Clients && (
            <ResourcesLayout
              resourceType={ResourceType.Client}
              resourcesRoute={
                selectedOrganization !== EmptyOrganization
                  ? apiRoutes.systemAdmin.organizationClients(
                      selectedOrganization.id
                    )
                  : undefined
              }
              detailsRoute={(resourceId): string | undefined =>
                selectedOrganization !== EmptyOrganization
                  ? apiRoutes.systemAdmin.organizationClientDetails(
                      selectedOrganization.id,
                      resourceId
                    )
                  : undefined
              }
              notesRoute={(resourceId): string | undefined =>
                selectedOrganization !== EmptyOrganization
                  ? apiRoutes.systemAdmin.organizationClientNotes(
                      selectedOrganization.id,
                      resourceId
                    )
                  : undefined
              }
              deleteRoute={(resourceId): string =>
                apiRoutes.client.delete(resourceId)
              }
              dashboardPath={Routes.AdvocateDashboardPath}
              resourcesPageUrl={Routes.AdvocateClientsPath}
              resourceIdUrlParamName={UrlParamNames.ClientIdUrlParamName}
              hideCards={true}
              hideNoResourcesLink={true}
            />
          )}

          {selectedPage.id === PageId.Users && (
            <div className="mt-5">
              <UsersLayout
                usersRoute={
                  selectedOrganization !== EmptyOrganization
                    ? apiRoutes.systemAdmin.organizationUsers(
                        selectedOrganization.id
                      )
                    : undefined
                }
                deleteUserRoute={(userId): string | undefined =>
                  selectedOrganization !== EmptyOrganization
                    ? apiRoutes.systemAdmin.organizationUser(
                        selectedOrganization.id,
                        userId
                      )
                    : undefined
                }
                deleteRoleRoute={(userId, role): string | undefined =>
                  selectedOrganization !== EmptyOrganization
                    ? apiRoutes.systemAdmin.organizationUserRole(
                        selectedOrganization.id,
                        userId,
                        role
                      )
                    : undefined
                }
              />
            </div>
          )}

          {selectedPage.id === PageId.UserInvites && (
            <div className="mt-5">
              <UserInvitesAdminLayout
                invitesRoute={
                  selectedOrganization !== EmptyOrganization
                    ? apiRoutes.systemAdmin.organizationInvites(
                        selectedOrganization.id
                      )
                    : undefined
                }
                inviteRoute={(inviteId): string | undefined =>
                  selectedOrganization !== EmptyOrganization
                    ? apiRoutes.systemAdmin.organizationInvite(
                        selectedOrganization.id,
                        inviteId
                      )
                    : undefined
                }
              />
            </div>
          )}
        </Col>
      </Row>
    </MenuLayout>
  );
};
