import { FormEvent, useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import Moment from 'react-moment';
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from 'src/components/loadingSpinner';
import { PrimaryButton } from 'src/components/primaryButton';
import { Service } from 'src/models/resource';
import { ServiceAvailability } from 'src/models/serviceAvailability';
import { EmptyServiceAvailabilityViewModel } from 'src/models/serviceAvailabilityViewModel';
import { useIdToken } from 'src/services/msal';
import {
  apiRoutes,
  authenticatedFetch,
  FetchMethod,
  useApi,
} from 'src/services/swr';
import {
  BooleanOptions,
  CalendarStrings,
  Locale,
  plural,
  showErrorNotification,
  showSuccessNotification,
} from 'src/utils/helpers';
import styles from './serviceAvailabilityModal.module.scss';

interface Props {
  serviceId: number;
  show?: boolean;
  onHide?: () => void;
}

export const ServiceAvailabilityModal = (props: Props): JSX.Element => {
  const { token } = useIdToken();

  const { data: service } = useApi<Service>(
    apiRoutes.service.get(props.serviceId)
  );

  const { data: serviceAvailability, mutate: updateAvailability } =
    useApi<ServiceAvailability>(
      apiRoutes.service.availability(props.serviceId)
    );

  const { mutate: updateServices } = useApi<Service[]>(
    apiRoutes.provider.services
  );

  const [availabilityUpdate, setAvailabilityUpdate] = useState(
    EmptyServiceAvailabilityViewModel
  );
  const [isAvailabilityValidated, setIsAvailabilityValidated] = useState(false);
  const [isSubmittingAvailability, setIsSubmittingAvailability] =
    useState(false);
  const [
    isSubmittingAvailabilityComplete,
    setIsSubmittingAvailabilityComplete,
  ] = useState(false);

  const getWaitlistClassName = (index: number): string => {
    if (
      index + 1 === serviceAvailability?.availability.length ||
      serviceAvailability?.availability[index].isWaitlistOpen ===
        serviceAvailability?.availability[index + 1].isWaitlistOpen
    ) {
      return '';
    }

    return serviceAvailability?.availability[index].isWaitlistOpen
      ? styles.increase
      : styles.decrease;
  };

  const compareAvailableUnitsToPrevious = (index: number): number => {
    if (
      !serviceAvailability ||
      index + 1 === serviceAvailability?.availability.length ||
      serviceAvailability?.availability[index].availableUnits ===
        serviceAvailability?.availability[index + 1].availableUnits
    ) {
      return 0;
    }

    return (
      serviceAvailability?.availability[index].availableUnits -
      serviceAvailability?.availability[index + 1]?.availableUnits
    );
  };

  const getChangeElement = (index: number): JSX.Element => {
    const change = compareAvailableUnitsToPrevious(index);

    if (change === 0) {
      return <></>;
    }

    return (
      <>
        <FontAwesomeIcon
          icon={change > 0 ? faLongArrowAltUp : faLongArrowAltDown}
          className={change > 0 ? styles.increase : styles.decrease}
        />
        <span className={styles.changeValue}>
          {compareAvailableUnitsToPrevious(index)}
        </span>
      </>
    );
  };

  const submitAvailability = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (isSubmittingAvailability) {
      return;
    }

    const form = e.currentTarget;

    if (form.checkValidity()) {
      setIsAvailabilityValidated(true);
      setIsSubmittingAvailability(true);

      try {
        await authenticatedFetch(
          apiRoutes.service.updateAvailability(props.serviceId),
          FetchMethod.POST,
          token,
          JSON.stringify(availabilityUpdate)
        );
        setIsSubmittingAvailabilityComplete(true);

        await updateAvailability();
        await updateServices();

        setIsAvailabilityValidated(false);
        setIsSubmittingAvailability(false);
        setIsSubmittingAvailabilityComplete(false);

        showSuccessNotification('Availability saved!');
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsSubmittingAvailability(false);
      }
    }
  };

  // When service availability is returned, set the initial value to the first result (the latest).
  useEffect(() => {
    serviceAvailability?.availability?.[0]?.id &&
      setAvailabilityUpdate({
        availableUnits: serviceAvailability.availability[0].availableUnits,
        isWaitlistOpen: serviceAvailability.availability[0].isWaitlistOpen,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceAvailability]);

  return (
    <Modal centered show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{`Availability ${
          service ? `for ${service.name}` : ''
        }`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!service || !serviceAvailability ? (
          <div className="d-flex justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <Row>
              <Col>
                <Form
                  validated={isAvailabilityValidated}
                  onSubmit={submitAvailability}
                >
                  <Form.Row>
                    <Col xs={12}>
                      <Form.Group controlId="formAvailability">
                        <Form.Label className="font-weight-bold">
                          {`How many ${plural(
                            service.unit
                          ).toLowerCase()} are currently available?`}
                        </Form.Label>
                        <Form.Control
                          size="sm"
                          type="number"
                          min="0"
                          max={service.totalUnits}
                          value={availabilityUpdate.availableUnits}
                          onChange={(e): void =>
                            setAvailabilityUpdate({
                              ...availabilityUpdate,
                              availableUnits: Number(e.target.value),
                            })
                          }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {`${plural(
                            service.unit
                          )} must be between 0 and the service's max of ${
                            service.totalUnits
                          }`}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    {service.hasWaitlist && (
                      <Col xs={12}>
                        <Form.Group controlId="formWaitlist">
                          <Form.Label className="font-weight-bold">
                            {`Is the waitlist currently open?`}
                          </Form.Label>
                          <Form.Control
                            size="sm"
                            as="select"
                            value={Number(availabilityUpdate.isWaitlistOpen)}
                            onChange={(e): void =>
                              setAvailabilityUpdate({
                                ...availabilityUpdate,
                                isWaitlistOpen: Boolean(Number(e.target.value)),
                              })
                            }
                            required
                          >
                            {BooleanOptions.map((opt) => {
                              return (
                                <option key={opt.id} value={opt.id}>
                                  {opt.value}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )}
                  </Form.Row>
                  <PrimaryButton
                    content="Save"
                    isLoading={isSubmittingAvailability}
                    isComplete={isSubmittingAvailabilityComplete}
                  />
                </Form>
              </Col>
            </Row>

            <Row className={styles.tableWrapper}>
              <Col>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>User</th>
                      <th>Available</th>
                      {service.hasWaitlist && <th>Waitlist</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {serviceAvailability?.availability &&
                    serviceAvailability?.availability.length === 0 ? (
                      <tr>
                        <td colSpan={service.hasWaitlist ? 4 : 3}>
                          <div className="d-flex justify-content-center">
                            Add availability to see it here!
                          </div>
                        </td>
                      </tr>
                    ) : (
                      serviceAvailability?.availability &&
                      serviceAvailability?.availability.map(
                        (availability, index: number) => (
                          <tr key={availability.id}>
                            <td>
                              <Moment
                                calendar={CalendarStrings}
                                locale={Locale}
                                local
                              >
                                {availability.updatedDate}
                              </Moment>
                            </td>
                            <td>{availability.updatedBy}</td>
                            <td>
                              <Row>
                                <Col xs={6} md={8}>
                                  {availability.availableUnits}
                                </Col>
                                <Col
                                  xs={6}
                                  md={4}
                                  className={styles.changeWrapper}
                                >
                                  {getChangeElement(index)}
                                </Col>
                              </Row>
                            </td>
                            {service.hasWaitlist && (
                              <td>
                                <div className={getWaitlistClassName(index)}>
                                  {availability.isWaitlistOpen
                                    ? 'Open'
                                    : 'Closed'}
                                </div>
                              </td>
                            )}
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
