import { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelButton } from 'src/components/cancelButton';
import { CardLayout } from 'src/components/cardLayout';
import { Pill } from 'src/components/pill';
import { PrimaryButton } from 'src/components/primaryButton';
import { PseudoButton } from 'src/components/pseudoButton';
import { RoleType, User } from 'src/models/user';
import { cardInfoText, cardHeaderText } from 'src/utils/styles';
import styles from './userCard.module.scss';
import { roleToName } from 'src/utils/helpers';

interface Props {
  user: User;
  isDeletingRole?: boolean;
  isDeletingUser?: boolean;
  onDeleteRole?: (role: RoleType) => void;
  onDeleteUser?: () => void;
}

export const UserCard = (props: Props): JSX.Element => {
  const [pendingDeleteRole, setPendingDeleteRole] = useState(RoleType.Advocate);
  const [showConfirmDeleteRole, setShowConfirmDeleteRole] = useState(false);
  const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false);

  const handleConfirmDeleteUser = (): void => {
    setShowConfirmDeleteUser(false);
    props.onDeleteUser?.();
  };

  const handleShowConfirmDeleteRole = (role: RoleType): void => {
    setPendingDeleteRole(role);
    setShowConfirmDeleteRole(true);
  };

  const handleConfirmDeleteRole = (): void => {
    setShowConfirmDeleteRole(false);
    props.onDeleteRole?.(pendingDeleteRole);
  };

  return (
    <>
      <CardLayout
        icon={faUser}
        headerRow={
          <>
            <Row className={cardHeaderText}>
              <Col xs={props.onDeleteUser ? 10 : 12}>{props.user.name}</Col>
              {props.onDeleteUser && (
                <Col
                  xs={2}
                  className={styles.deleteWrapper}
                  id={`delete${props.user.id}`}
                >
                  <PseudoButton
                    content={
                      <FontAwesomeIcon icon={faTrashAlt} title="Delete" />
                    }
                    className={styles.delete}
                    onClick={(): void => setShowConfirmDeleteUser(true)}
                    isLoading={props.isDeletingUser}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <small className={cardInfoText}>
                  {props.user.emailAddress}
                </small>
              </Col>
            </Row>
          </>
        }
        contentRow={
          <Row>
            {props.user.roles.map((role) => (
              <Col xs={12} key={role} className="m-1">
                <Pill
                  content={roleToName(role)}
                  showDelete={true}
                  deleteButtonId={`deleteRole${role}`}
                  isDeleting={props.isDeletingRole}
                  onDeleteClicked={(): void =>
                    handleShowConfirmDeleteRole(role)
                  }
                />
              </Col>
            ))}
          </Row>
        }
      />

      <Modal
        centered
        show={showConfirmDeleteRole}
        onHide={(): void => setShowConfirmDeleteRole(false)}
      >
        <Modal.Header closeButton closeLabel="close">
          <Modal.Title>
            Delete {roleToName(pendingDeleteRole)} role for {props.user.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <CancelButton
            content="Cancel"
            onClick={(): void => setShowConfirmDeleteRole(false)}
          />
          <PrimaryButton content="Delete" onClick={handleConfirmDeleteRole} />
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={showConfirmDeleteUser}
        onHide={(): void => setShowConfirmDeleteUser(false)}
      >
        <Modal.Header closeButton closeLabel="close">
          <Modal.Title>
            Delete {props.user.name} from this organization
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <CancelButton
            content="Cancel"
            onClick={(): void => setShowConfirmDeleteUser(false)}
          />
          <PrimaryButton content="Delete" onClick={handleConfirmDeleteUser} />
        </Modal.Footer>
      </Modal>
    </>
  );
};
