import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { PageLoading } from 'src/components/pageLoading';
import { ResourceLayout } from 'src/components/resourceLayout';
import { ResourceType } from 'src/models/resource';
import { ResourceDetails } from 'src/models/resourceDetails';
import styles from './resourceResult.module.scss';

interface Props {
  resourceType: ResourceType;
  resourceId?: number;
  resourceDetails?: ResourceDetails | null;
  isLoading?: boolean;
}

export const ResourceResult = ({
  resourceType,
  resourceId,
  resourceDetails,
  isLoading,
}: Props): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <div>
        <div className={styles.back} onClick={(): void => history.goBack()}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="fa-2xs mr-1 align-middle"
          />
          <small>Back</small>
        </div>
        <div className={`${styles.pageTitle} pt-0`}>
          <div>
            <div>
              {isLoading ? (
                <PageLoading showIcon={false} />
              ) : resourceDetails ? (
                <span>{resourceDetails.profile.name}</span>
              ) : (
                <div>Not found...</div>
              )}
            </div>
            <div className={styles.line} />
          </div>
        </div>
      </div>

      {resourceDetails && (
        <Row>
          <Col>
            <ResourceLayout
              resourceType={resourceType}
              resourceId={resourceId}
              resourceDetails={resourceDetails}
              isLoadingDetails={isLoading}
              readOnly={true}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
