export enum AffiliationOrLicenseType {
  NtsaGeneralMember,
  NtsaAssociateMember,
  NtsaAccreditedMember,
  FreedomNetworkMember,
  OfficeForVictimsOfCrimeGrantee,
  OfficeForViolenceAgainstWomenGrantee,
  VocaGrantee,
  ResidentialCareForMinors,
}
