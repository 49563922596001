import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { LoadingSpinner } from 'src/components/loadingSpinner';
import { MenuLayout } from 'src/components/menuLayout';
import { OrganizationCard } from 'src/components/organizationCard';
import { AdminMenuOptions } from 'src/models/adminMenuOptions';
import { Organization } from 'src/models/organization';
import { useIdToken } from 'src/services/msal';
import {
  apiRoutes,
  authenticatedFetch,
  FetchMethod,
  useApi,
} from 'src/services/swr';
import {
  debounce,
  InvalidId,
  showErrorNotification,
  showSuccessNotification,
} from 'src/utils/helpers';
import { Routes } from 'src/utils/routes';
import styles from './Dashboard.module.scss';

export const AdminDashboard = (): JSX.Element => {
  const { token } = useIdToken();

  const {
    data: unverifiedProviders,
    isValidating: isLoadingUnverifiedProviders,
    mutate: getUnverifiedProviders,
  } = useApi<Organization[]>(apiRoutes.systemAdmin.unverifiedOrganizations);

  const {
    data: deniedProviders,
    isValidating: isLoadingDeniedProviders,
    mutate: getDeniedProviders,
  } = useApi<Organization[]>(apiRoutes.systemAdmin.deniedOrganizations);

  const [isModifyingProviderId, setIsModifyingProviderId] = useState(InvalidId);

  const approveProvider = async (id: number): Promise<void> => {
    setIsModifyingProviderId(id);

    try {
      await authenticatedFetch(
        apiRoutes.systemAdmin.approveOrganization(id),
        FetchMethod.POST,
        token,
        ''
      );

      getUnverifiedProviders();
      getDeniedProviders();
      await debounce();

      showSuccessNotification('Provider approved!');
    } catch (error) {
      showErrorNotification((error as Error).message);
    } finally {
      setIsModifyingProviderId(InvalidId);
    }
  };

  const denyProvider = async (id: number, reason: string): Promise<void> => {
    setIsModifyingProviderId(id);

    try {
      await authenticatedFetch(
        apiRoutes.systemAdmin.denyOrganization(id),
        FetchMethod.POST,
        token,
        JSON.stringify(reason)
      );

      getUnverifiedProviders();
      getDeniedProviders();
      await debounce();

      showSuccessNotification('Provider denied!');
    } catch (error) {
      showErrorNotification((error as Error).message);
    } finally {
      setIsModifyingProviderId(InvalidId);
    }
  };

  const deleteProvider = async (id: number): Promise<void> => {
    setIsModifyingProviderId(id);

    try {
      await authenticatedFetch(
        apiRoutes.systemAdmin.deleteOrganization(id),
        FetchMethod.DELETE,
        token,
        ''
      );

      getDeniedProviders();
      await debounce();

      showSuccessNotification('Provider deleted!');
    } catch (error) {
      showErrorNotification((error as Error).message);
    } finally {
      setIsModifyingProviderId(InvalidId);
    }
  };

  return (
    <MenuLayout
      pageIndex={AdminMenuOptions.findIndex(
        (o) => o.path === Routes.AdminDashboardPath
      )}
      menuOptions={AdminMenuOptions}
    >
      <Container>
        <Row>
          <div>
            <div className={styles.pageTitle}>UNVERIFIED PROVIDERS</div>
            <div className={styles.line} />
          </div>
        </Row>

        {isLoadingUnverifiedProviders && !unverifiedProviders ? (
          <LoadingSpinner />
        ) : unverifiedProviders?.length ? (
          <Row>
            {unverifiedProviders?.map((provider) => (
              <Col key={provider.id} xs={12} md={6}>
                <OrganizationCard
                  organization={provider}
                  canApprove={true}
                  canDeny={true}
                  onApprove={(): Promise<void> => approveProvider(provider.id)}
                  onDeny={(reason: string): Promise<void> =>
                    denyProvider(provider.id, reason)
                  }
                  isSubmittingAction={isModifyingProviderId === provider.id}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <p>No unverified providers</p>
        )}

        <Row>
          <div>
            <div className={styles.pageTitle}>DENIED PROVIDERS</div>
            <div className={styles.line} />
          </div>
        </Row>

        {isLoadingDeniedProviders && !deniedProviders ? (
          <LoadingSpinner />
        ) : deniedProviders?.length ? (
          <Row>
            {deniedProviders?.map((provider) => (
              <Col key={provider.id} xs={12} md={6}>
                <OrganizationCard
                  organization={provider}
                  canApprove={true}
                  canDelete={true}
                  onApprove={(): Promise<void> => approveProvider(provider.id)}
                  onDelete={(): Promise<void> => deleteProvider(provider.id)}
                  isSubmittingAction={isModifyingProviderId === provider.id}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <p>No denied providers</p>
        )}
      </Container>
    </MenuLayout>
  );
};
