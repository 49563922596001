import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ClientCard } from 'src/components/clientCard';
import { CustomTooltip } from 'src/components/customTooltip';
import { StatusBar } from 'src/components/statusBar';
import { Organization } from 'src/models/organization';
import { ReferralStat } from 'src/models/referralStat';
import { Client } from 'src/models/resource';
import { useIdToken } from 'src/services/msal';
import {
  apiRoutes,
  authenticatedFetch,
  FetchMethod,
  useApi,
} from 'src/services/swr';
import { InvalidId, showErrorNotification } from 'src/utils/helpers';
import styles from './Welcome.module.scss';

interface Props {
  registerNewClient: () => void;
}

export const WelcomePage = (props: Props): JSX.Element => {
  const organization = useApi<Organization>(apiRoutes.organization.get);
  const clients = useApi<Client[]>(apiRoutes.advocate.clients);
  const referralStats = useApi<ReferralStat[]>(
    apiRoutes.organization.referralStats.advocate
  );

  const [isDeletingClientId, setIsDeletingClientId] =
    useState<number>(InvalidId);

  const { token } = useIdToken();
  const deleteClient = async (clientId: number): Promise<void> => {
    try {
      setIsDeletingClientId(clientId);
      await authenticatedFetch(
        apiRoutes.client.delete(clientId),
        FetchMethod.DELETE,
        token,
        ''
      );
      await clients.mutate();
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
    } finally {
      setIsDeletingClientId(InvalidId);
    }
  };

  return (
    <div className={styles.background}>
      <Row className="align-items-center">
        <Col
          xs={{ span: 12, order: 2 }}
          lg={{ span: 6, order: 1 }}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <div className={styles.pageTitle}>
              CLIENTS
              <CustomTooltip
                popup="Add a new client"
                content={
                  <Button
                    className={styles.pageTitleButton}
                    variant="outline-dark"
                    onClick={props.registerNewClient}
                  >
                    +
                  </Button>
                }
              />
            </div>
            {clients.data?.length === 0 && !clients.isValidating && (
              <div className="text-center pt-3">
                Let&apos;s register your first client! Click the + button to
                begin
              </div>
            )}
            <div className={styles.line} />
          </div>
        </Col>
        <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }}>
          <StatusBar
            title={organization.data?.name || ''}
            stats={referralStats.data?.map((stat) => ({
              name: stat.name,
              description: stat.description,
              value: stat.value,
            }))}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        {clients.data?.map((client) => (
          <Col key={client.id} xs={12} md={6}>
            <ClientCard
              client={client}
              isDisabled={client.id === isDeletingClientId}
              onDelete={deleteClient}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};
