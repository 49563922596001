import { FormEvent, useEffect, useState } from 'react';
import { Col, Container, Form } from 'react-bootstrap';
import { PrimaryButton } from 'src/components/primaryButton';
import { OrganizationRegistrationStep1Inputs } from 'src/components/organizationRegistrationStep1Inputs';
import { RegistrationHeader } from 'src/components/registrationHeader';
import { IdValuePair } from 'src/models/idValuePair';
import { OrganizationRegistrationStep1ViewModel } from 'src/models/organizationRegistrationStep1ViewModel';
import { useIdToken } from 'src/services/msal';
import {
  apiRoutes,
  authenticatedFetch,
  FetchMethod,
  useApi,
} from 'src/services/swr';
import {
  debounce,
  showErrorNotification,
  showInformationSavedNotification,
} from 'src/utils/helpers';

export const OrganizationRegistrationStep1 = (props: {
  onComplete: () => void;
}): JSX.Element => {
  const { token } = useIdToken();

  const { data: nonProfitStatuses, isValidating: isLoadingNonprofitStatuses } =
    useApi<IdValuePair[]>(
      apiRoutes.organizationRegistrationStep1.nonProfitStatuses
    );
  const { data: primaryPurposes, isValidating: isLoadingPrimaryPurposes } =
    useApi<IdValuePair[]>(
      apiRoutes.organizationRegistrationStep1.primaryPurposes
    );
  const { isValidating: isLoadingAffiliationsOrLicenses } = useApi<
    IdValuePair[]
  >(apiRoutes.organizationRegistrationStep1.affiliationOrLicenses);

  const [state, setState] = useState<OrganizationRegistrationStep1ViewModel>(
    {}
  );
  const [isValidated, setIsValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingComplete, setIsSubmittingComplete] = useState(false);

  const isLoading =
    isLoadingNonprofitStatuses ||
    isLoadingPrimaryPurposes ||
    isLoadingAffiliationsOrLicenses;

  // Set default value after loading.
  useEffect(() => {
    setState((previous) => ({
      ...previous,
      nonprofitStatus: nonProfitStatuses?.[0].id,
    }));

    setState((previous) => ({
      ...previous,
      primaryPurpose: primaryPurposes?.[0].id,
    }));
  }, [nonProfitStatuses, primaryPurposes]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (isSubmitting) {
      return;
    }

    const form = e.currentTarget;
    if (form.checkValidity()) {
      setIsValidated(true);
      setIsSubmitting(true);
      try {
        await authenticatedFetch(
          apiRoutes.organizationRegistrationStep1.submit,
          FetchMethod.POST,
          token,
          JSON.stringify(state)
        );
        setIsSubmittingComplete(true);
        await debounce();

        showInformationSavedNotification();
        props.onComplete();
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <RegistrationHeader
        title="Organization Information"
        description="This information is for general inquiries and your organization's headquarters. "
        isPageLoading={isLoading}
      />
      {!isLoading && (
        <Container>
          <Form validated={isValidated} onSubmit={handleSubmit}>
            <Form.Row>
              <OrganizationRegistrationStep1Inputs
                state={state}
                onStateChanged={(newState): void => setState(newState)}
                disableInputs={isSubmitting}
              />
            </Form.Row>
            <Form.Row className="my-2">
              <Col xs={12} sm={{ span: 2, offset: 10 }}>
                <PrimaryButton
                  content="Next"
                  isLoading={isSubmitting}
                  isComplete={isSubmittingComplete}
                />
              </Col>
            </Form.Row>
          </Form>
        </Container>
      )}
    </>
  );
};
