import { Col, Row } from 'react-bootstrap';
import { CustomTooltip } from 'src/components/customTooltip';

interface Stat {
  name: string;
  description: string;
  value: number;
}

interface StatusProps {
  title?: string;
  stats?: Stat[];
}

export const StatusBar = ({ title, stats }: StatusProps): JSX.Element => {
  return (
    <div className="bg-primary text-white p-1 rounded shadow">
      {title && (
        <Row className="d-flex justify-content-center mb-1">
          <u>{title}</u>
        </Row>
      )}
      <Row className="d-flex justify-content-center">
        {stats?.map((stat) => (
          <Col
            xs={3}
            md={2}
            className="d-flex flex-column text-center"
            key={stat.name}
          >
            <CustomTooltip
              popup={stat.description}
              content={<small className="font-weight-light">{stat.name}</small>}
            />
          </Col>
        ))}
      </Row>
      <Row className="d-flex justify-content-center">
        {stats?.map((stat) => (
          <Col
            xs={3}
            md={2}
            className="d-flex flex-column text-center"
            key={stat.name}
          >
            <CustomTooltip
              popup={stat.description}
              content={<div className="h1 font-weight-light">{stat.value}</div>}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};
