import { ChangeEvent } from 'react';
import { Col, Form } from 'react-bootstrap';
import { IdValuePair } from 'src/models/idValuePair';
import { OrganizationRegistrationStep2ViewModel } from 'src/models/organizationRegistrationStep2ViewModel';
import { apiRoutes, useApi } from 'src/services/swr';
import { SelectAllButton } from 'src/components/SelectAllButton';

interface IProps {
  state: OrganizationRegistrationStep2ViewModel;
  onStateChanged: (state: OrganizationRegistrationStep2ViewModel) => void;
  disableInputs: boolean;
}

export const OrganizationRegistrationStep2Inputs = ({
  state,
  onStateChanged,
  disableInputs,
}: IProps): JSX.Element => {
  const { data: traits } = useApi<IdValuePair[]>(
    apiRoutes.organizationRegistrationStep2.traits
  );

  /*
    const handleFile = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.item(0);

        if (file !== null) {
            readFileDataAsBase64(event);
        } else {
            setState({
                ...state,
                logo: null,
            });
        }
    };

    const splitOnBase64 = (src: string): string => {
        return src.split(',')[1];
    }

    const readFileDataAsBase64 = (e: ChangeEvent<HTMLInputElement>): Promise<string> => {
        const file = e?.target?.files?.item(0);

        return new Promise(() => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader?.result !== null) {
                    setState({
                        ...state,
                        logo: splitOnBase64(reader?.result as string),
                    });
                }
            };

            reader.onerror = () => {
                setState({
                    ...state,
                    logo: null,
                });
            };
            if (file !== undefined && file !== null) {
                // Based on what the backend is expecting, read in data as a certain format
                reader.readAsDataURL(file);
            }
        });
    }
    */

  const handleTraitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const checkbox = event.currentTarget;
    const optionId = Number(checkbox.value);

    // Add to the array if the ID isn't there, otherwise remove.
    const newTraits = state.traits.includes(optionId)
      ? state.traits.filter((x) => x !== optionId)
      : [...state.traits, optionId];

    onStateChanged({
      ...state,
      traits: newTraits,
    });
  };

  return (
    <>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formOrganizationTraits">
          <Form.Label className="font-weight-bold">
            Please select the traits that best describe your organization:
            (Select all that apply)
          </Form.Label>

          {traits?.map((trait) => (
            <Form.Check
              key={trait.id}
              id={`trait${trait.id}`}
              value={trait.id}
              label={trait.value}
              checked={state.traits?.some((a) => a === trait.id)}
              onChange={handleTraitChange}
              disabled={disableInputs}
            />
          ))}

          <SelectAllButton
            allBoxIds={traits?.map((a) => a.id)}
            allAreSelected={(): boolean =>
              state.traits?.length === traits?.length
            }
            onChange={(newTraits: number[]): void =>
              onStateChanged({
                ...state,
                traits: newTraits,
              })
            }
            disableInputs={disableInputs}
          />
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formOrganizationDescription">
          <Form.Label className="font-weight-bold">
            Please provide a brief overview of your organization&apos;s mission
            and services related to Human Trafficking (800 characters max).
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            maxLength={800}
            value={state.description}
            onChange={(e): void =>
              onStateChanged({
                ...state,
                description: e.target.value,
              })
            }
            disabled={disableInputs}
            required
          />
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="formOrganizationReligion">
          <Form.Label className="font-weight-bold">
            What religion or denomination is your organization associated with,
            or None?
          </Form.Label>
          <Form.Control
            size="sm"
            maxLength={50}
            value={state.religiousAffiliation}
            onChange={(e): void =>
              onStateChanged({
                ...state,
                religiousAffiliation: e.target.value,
              })
            }
            disabled={disableInputs}
            required
          />
        </Form.Group>
      </Col>
      {/*<Col>
        <Form.Group>
            <Form.Label className="font-weight-bold">
                Please upload your organization&apos;s logo
            </Form.Label>

            <Form.File
                id="exampleFormControlFile1"
                onChange={handleFile}
            />
        </Form.Group>
      </Col>*/}
    </>
  );
};
