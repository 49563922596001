import { MouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { LoadingSpinner } from 'src/components/loadingSpinner';

interface Props {
  content: string | JSX.Element;
  id?: string;
  className?: string;
  noBlock?: boolean;
  isLoading?: boolean;
  isComplete?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const PseudoButton = (props: Props): JSX.Element => {
  const onClick = (e: MouseEvent<Element>): void => {
    if (props.isDisabled || props.isLoading) {
      return;
    }

    e.stopPropagation();
    props.onClick?.();
  };

  return (
    <div
      id={props.id || ''}
      className={`${props.isDisabled ? '' : 'clickable'} ${
        props.className || ''
      }`}
      onClick={onClick}
    >
      {props.isComplete ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : props.isLoading ? (
        <LoadingSpinner />
      ) : (
        props.content
      )}
    </div>
  );
};
