import { FormEvent, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { CancelButton } from 'src/components/cancelButton';
import { CardLayout } from 'src/components/cardLayout';
import { PrimaryButton } from 'src/components/primaryButton';
import { PseudoButton } from 'src/components/pseudoButton';
import { Organization } from 'src/models/organization';
import { cardInfoText, noWrap, cardHeaderText } from 'src/utils/styles';
import { DateFormat, Locale } from 'src/utils/helpers';
import styles from './organizationCard.module.scss';

enum Action {
  None = 'None',
  Approve = 'Approve',
  Deny = 'Deny',
  Delete = 'Delete',
}

interface Props {
  organization: Organization;
  canApprove?: boolean;
  canDeny?: boolean;
  canDelete?: boolean;
  onApprove?: () => void;
  onDeny?: (reason: string) => void;
  onDelete?: () => void;
  isSubmittingAction?: boolean;
}

export const OrganizationCard = (props: Props): JSX.Element => {
  const [pendingAction, setPendingAction] = useState(Action.None);
  const [pendingDenyReason, setPendingDenyReason] = useState('');
  const [showActionModal, setShowActionModal] = useState(false);

  const handleShowActionModal = (action: Action): void => {
    setPendingAction(action);
    setShowActionModal(true);
  };

  const handleCloseActionModal = (): void => {
    setShowActionModal(false);
  };

  const getQualifiedUrl = (url: string): string =>
    url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `http://${url}`;

  const actionToClassName = (action: Action): string => {
    switch (action) {
      case Action.Approve:
        return styles.approve;
      case Action.Deny:
        return styles.deny;
      case Action.Delete:
        return styles.delete;
      default:
        return '';
    }
  };

  const submitProviderAction = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      return;
    }

    switch (pendingAction) {
      case Action.Approve:
        props.onApprove?.();
        break;
      case Action.Deny:
        props.onDeny?.(pendingDenyReason);
        break;
      case Action.Delete:
        props.onDelete?.();
        break;
      default:
        break;
    }

    handleCloseActionModal();
  };

  return (
    <>
      <CardLayout
        icon={faBuilding}
        headerRow={
          <Row className={cardHeaderText}>
            <Col className={noWrap}>{props.organization.name}</Col>
          </Row>
        }
        contentRow={
          <Row>
            <Col xs={5}>
              <div className="d-flex flex-column">
                <div className={`${cardInfoText} ${noWrap}`}>Email</div>
                <div className={`${cardInfoText} ${noWrap}`}>Phone</div>
                <div className={`${cardInfoText} ${noWrap}`}>Website</div>
                <div className={`${cardInfoText} ${noWrap}`}>Registered</div>
                <div className={`${cardInfoText} ${noWrap}`}>Actions</div>
              </div>
            </Col>
            <Col xs={7}>
              <div className="d-flex flex-column">
                <div className={`${cardInfoText} ${noWrap}`}>
                  {props.organization.email}
                </div>
                <div className={`${cardInfoText} ${noWrap}`}>
                  {props.organization.phoneNumber}
                </div>
                <div className={`${cardInfoText} ${noWrap}`}>
                  <a
                    href={getQualifiedUrl(props.organization.url)}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {props.organization.url}
                  </a>
                </div>
                <div className={`${cardInfoText} ${noWrap}`}>
                  <Moment format={DateFormat} locale={Locale} local>
                    {props.organization.registrationDate}
                  </Moment>
                </div>
                <div className={cardInfoText + ' d-flex'}>
                  {props.canApprove && (
                    <PseudoButton
                      className={`${styles.approve} ${styles.clickableText} mr-2`}
                      content="Approve"
                      onClick={(): void =>
                        handleShowActionModal(Action.Approve)
                      }
                      isLoading={props.isSubmittingAction}
                    />
                  )}
                  {props.canDeny && (
                    <PseudoButton
                      className={`${styles.deny} ${styles.clickableText} mr-2`}
                      content="Deny"
                      onClick={(): void => handleShowActionModal(Action.Deny)}
                      isLoading={props.isSubmittingAction}
                    />
                  )}
                  {props.canDelete && (
                    <PseudoButton
                      className={`${styles.delete} ${styles.clickableText}`}
                      content="Delete"
                      onClick={(): void => handleShowActionModal(Action.Delete)}
                      isLoading={props.isSubmittingAction}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        }
      />

      <Modal centered show={showActionModal} onHide={handleCloseActionModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className={actionToClassName(pendingAction)}>
              {pendingAction.toString()}
            </span>
            {pendingAction === Action.Delete
              ? ` ${props.organization.name} and all associated data`
              : ` access for '${props.organization.name}'`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitProviderAction}>
            {pendingAction === Action.Deny && (
              <Form.Row>
                <Col xs={12}>
                  <Form.Group controlId="formProviderAction">
                    <Form.Label className="font-weight-bold">
                      Please provide the reason this provider has been denied.
                      This reason will be included in the denial email they
                      receive.
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      onChange={(e): void =>
                        setPendingDenyReason(e.target.value)
                      }
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a reason
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
            )}
            <CancelButton
              content="Cancel"
              isDisabled={props.isSubmittingAction}
              onClick={handleCloseActionModal}
            />
            <PrimaryButton
              content="Confirm"
              isLoading={props.isSubmittingAction}
            />
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
