import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { CancelButton } from 'src/components/cancelButton';
import { CardLayout } from 'src/components/cardLayout';
import { CustomTooltip } from 'src/components/customTooltip';
import { PrimaryButton } from 'src/components/primaryButton';
import { SecondaryButton } from 'src/components/secondaryButton';
import { ServiceAvailabilityModal } from 'src/components/serviceAvailabilityModal';
import {
  ClientReferral,
  Referral,
  ReferralStatus,
  ServiceReferral,
} from 'src/models/referral';
import { ReferralStat } from 'src/models/referralStat';
import { ReferralStats } from 'src/models/referralStats';
import { ResourceType } from 'src/models/resource';
import { ServiceReferralUpdate } from 'src/models/serviceReferralUpdate';
import { ServiceType } from 'src/models/serviceType';
import { useIdToken } from 'src/services/msal';
import {
  FetchMethod,
  apiRoutes,
  authenticatedFetch,
  useApi,
} from 'src/services/swr';
import {
  CalendarStrings,
  Locale,
  NotificationAutoCloseDefault,
  NotificationAutoCloseLong,
  debounce,
  getServiceIcon,
  referralStatusToAdvocateDescription,
  referralStatusToName,
  referralStatusToProviderDescription,
  showErrorNotification,
  showSuccessNotification,
} from 'src/utils/helpers';
import { Routes, UrlParamNames } from 'src/utils/routes';
import {
  cardHeaderText,
  cardInfoText,
  cardInfoTitle,
  noWrap,
} from 'src/utils/styles';
import styles from './referralCard.module.scss';

enum Action {
  Accept,
  Waitlist,
  Decline,
  Place,
  Cancel,
  Archive,
}

const ClientActions: { [key: number]: Action[] } = {
  [ReferralStatus.Pending]: [Action.Cancel],
  [ReferralStatus.AcceptedByProvider]: [Action.Accept, Action.Cancel],
  [ReferralStatus.WaitlistedByProvider]: [Action.Cancel],
  [ReferralStatus.DeclinedByProvider]: [Action.Archive],
  [ReferralStatus.AcceptedByClient]: [Action.Cancel],
  [ReferralStatus.Placed]: [Action.Archive],
  [ReferralStatus.CanceledByProvider]: [Action.Archive],
  [ReferralStatus.CanceledByClient]: [Action.Archive],
};

const ServiceActions: { [key: number]: Action[] } = {
  [ReferralStatus.Pending]: [Action.Accept, Action.Waitlist, Action.Decline],
  [ReferralStatus.AcceptedByProvider]: [Action.Cancel],
  [ReferralStatus.WaitlistedByProvider]: [Action.Accept, Action.Decline],
  [ReferralStatus.DeclinedByProvider]: [Action.Archive],
  [ReferralStatus.AcceptedByClient]: [Action.Place, Action.Cancel],
  [ReferralStatus.Placed]: [Action.Archive],
  [ReferralStatus.CanceledByProvider]: [Action.Archive],
  [ReferralStatus.CanceledByClient]: [Action.Archive],
};

const ClientActionMessages: {
  [key: number]: [message: string, autoClose: number];
} = {
  [Action.Accept]: [
    'Referral accepted! The service provider will be notified and will connect with you to coordinate placement',
    NotificationAutoCloseLong,
  ],
  [Action.Cancel]: [
    'Referral canceled! The referral can be archived to remove it from your referral list',
    NotificationAutoCloseDefault,
  ],
  [Action.Archive]: ['Referral archived!', NotificationAutoCloseDefault],
};

const ServiceActionMessages: {
  [key: number]: [message: string, autoClose: number];
} = {
  [Action.Accept]: [
    'Referral accepted! The client will be notified and will have the option to confirm the acceptance. You will be notified once the client confirms or cancels the referral',
    NotificationAutoCloseLong,
  ],
  [Action.Waitlist]: [
    'Referral waitlisted! You have the option to accept or decline the referral as a followup. The client will be notified that they have been waitlisted and will have the option to wait or cancel the referral',
    NotificationAutoCloseLong,
  ],
  [Action.Decline]: [
    'Referral declined! The client will be notified that their referral has been declined',
    NotificationAutoCloseLong,
  ],
  [Action.Place]: [
    'Referral placed! We appreciate you being an essential part of the TIRA community!',
    NotificationAutoCloseLong,
  ],
  [Action.Cancel]: [
    'Referral canceled! The referral can be archived to remove it from your referral list',
    NotificationAutoCloseDefault,
  ],
  [Action.Archive]: ['Referral archived!', NotificationAutoCloseDefault],
};

const getClientRoute = (
  clientId: number,
  referralId: number,
  action: Action
): string => {
  switch (action) {
    case Action.Accept:
      return apiRoutes.client.clientReferral.accept(clientId, referralId);
    case Action.Cancel:
      return apiRoutes.client.clientReferral.cancel(clientId, referralId);
    case Action.Archive:
      return apiRoutes.client.clientReferral.archive(clientId, referralId);
    default:
      return '';
  }
};

const getServiceRoute = (
  serviceId: number,
  referralId: number,
  action: Action
): string => {
  switch (action) {
    case Action.Accept:
      return apiRoutes.service.serviceReferral.accept(serviceId, referralId);
    case Action.Waitlist:
      return apiRoutes.service.serviceReferral.waitlist(serviceId, referralId);
    case Action.Decline:
      return apiRoutes.service.serviceReferral.decline(serviceId, referralId);
    case Action.Place:
      return apiRoutes.service.serviceReferral.place(serviceId, referralId);
    case Action.Cancel:
      return apiRoutes.service.serviceReferral.cancel(serviceId, referralId);
    case Action.Archive:
      return apiRoutes.service.serviceReferral.archive(serviceId, referralId);
    default:
      return '';
  }
};

interface Props {
  resourceType: ResourceType;
  resourceId: number;
  referral: Referral;
  onUpdated?: (referral: Referral) => void;
  onArchived?: (referral: Referral) => void;
}

export const ReferralCard = ({
  resourceType,
  resourceId,
  referral,
  onUpdated,
  onArchived,
}: Props): JSX.Element => {
  const history = useHistory();
  const { token } = useIdToken();

  const serviceTypes = useApi<ServiceType[]>(
    resourceType === ResourceType.Client
      ? apiRoutes.api.serviceTypes
      : undefined
  );

  const { mutate: updateAdvocateReferralStats } = useApi<ReferralStat[]>(
    resourceType === ResourceType.Client
      ? apiRoutes.organization.referralStats.advocate
      : undefined
  );

  const { mutate: updateProviderReferralStats } = useApi<ReferralStat[]>(
    resourceType === ResourceType.Service
      ? apiRoutes.organization.referralStats.provider
      : undefined
  );

  const { mutate: updateClientReferralStats } = useApi<ReferralStats>(
    resourceType === ResourceType.Client
      ? apiRoutes.client.referralStats(resourceId)
      : undefined
  );

  const { mutate: updateServiceReferralStats } = useApi<ReferralStats>(
    resourceType === ResourceType.Service
      ? apiRoutes.service.referralStats(resourceId)
      : undefined
  );

  const [isUpdatingStatus, setIsUpdatingStatus] = useState<Action | undefined>(
    undefined
  );
  const [isUpdatingStatusComplete, setIsUpdatingStatusComplete] = useState<
    Action | undefined
  >(undefined);

  const [subTitle, setSubTitle] = useState('');

  const [pendingAction, setPendingAction] = useState<Action | undefined>(
    undefined
  );
  const [pendingReason, setPendingReason] = useState('');
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showAvailability, setShowAvailability] = useState(false);

  const handleShowReasonModal = (action: Action): void => {
    setPendingAction(action);
    setShowReasonModal(true);
  };

  const handleCloseReasonModal = (): void => {
    setShowReasonModal(false);
  };

  const clientReferral = referral as ClientReferral;
  const serviceReferral = referral as ServiceReferral;

  const actions =
    resourceType === ResourceType.Client ? ClientActions : ServiceActions;

  const actionMessages =
    resourceType === ResourceType.Client
      ? ClientActionMessages
      : ServiceActionMessages;

  const title =
    resourceType === ResourceType.Client
      ? clientReferral.serviceName
      : serviceReferral.clientIdentifier;

  const clickText =
    resourceType === ResourceType.Client
      ? '(Click to view service details)'
      : '(Click to view client details)';

  const otherRoleType =
    resourceType === ResourceType.Client ? 'service provider' : 'advocate';

  const icon =
    resourceType === ResourceType.Client
      ? getServiceIcon(clientReferral.serviceKind)
      : faUser;

  const statusDescription =
    resourceType === ResourceType.Client
      ? referralStatusToAdvocateDescription(referral.status)
      : referralStatusToProviderDescription(referral.status);

  const resourceUrl =
    resourceType === ResourceType.Client
      ? `${Routes.AdvocateDirectoryResultPath}?${UrlParamNames.ServiceIdUrlParamName}=${clientReferral.serviceId}`
      : `${Routes.ProviderReferralPath}?${UrlParamNames.ClientIdUrlParamName}=${serviceReferral.clientId}`;

  const onUpdate = async (action: Action): Promise<void> => {
    if (isUpdatingStatus) {
      return;
    }

    const route =
      resourceType === ResourceType.Client
        ? getClientRoute(resourceId, referral.id, action)
        : getServiceRoute(resourceId, referral.id, action);

    if (!route) {
      return;
    }

    setIsUpdatingStatus(action);

    const data: ServiceReferralUpdate = {
      reason: pendingReason,
    };

    try {
      const updatedReferral = await authenticatedFetch<Referral>(
        route,
        FetchMethod.POST,
        token,
        JSON.stringify(data)
      );

      setIsUpdatingStatusComplete(action);
      await debounce();

      handleCloseReasonModal();

      if (updatedReferral) {
        if (action === Action.Archive) {
          onArchived?.(referral);
        } else {
          onUpdated?.(updatedReferral);
        }
      }

      setIsUpdatingStatus(undefined);
      setIsUpdatingStatusComplete(undefined);

      // Refresh referral stats for the next time they are viewed.
      if (resourceType === ResourceType.Client) {
        updateAdvocateReferralStats();
        updateClientReferralStats();
      } else if (resourceType === ResourceType.Service) {
        updateProviderReferralStats();
        updateServiceReferralStats();
      }

      // Show success notification based on the action.
      if (action in actionMessages) {
        showSuccessNotification(
          actionMessages[action][0],
          actionMessages[action][1]
        );
      } else {
        showSuccessNotification('Referral updated!');
      }

      // If the referral was placed, prompt to update the service availability.
      if (action === Action.Place && resourceType === ResourceType.Service) {
        setShowAvailability(true);
      }
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
      setIsUpdatingStatus(undefined);
    }
  };

  const actionSpan = useCallback(
    (status: ReferralStatus) => {
      if (!(status in actions)) {
        return 0;
      }

      const actionCount = actions[status].length;
      return actionCount === 1 ? 6 : 4;
    },
    [actions]
  );

  const actionOffset = useCallback(
    (index: number, status: ReferralStatus) => {
      // Only the first action should be offset.
      if (index !== 0 || !(status in actions)) {
        return 0;
      }

      const actionCount = actions[status].length;
      switch (actionCount) {
        // Span=6, offset=3
        case 1:
          return 3;
        // Span=4, offset=2
        case 2:
          return 2;
        default:
          return 0;
      }
    },
    [actions]
  );

  const onClick = (): void => {
    history.push(resourceUrl);
  };

  useEffect(() => {
    if (resourceType === ResourceType.Client) {
      setSubTitle(
        `${
          serviceTypes.data?.find((s) => s.id === clientReferral.serviceKind)
            ?.name ?? ''
        } provided by ${clientReferral.organizationName}`
      );
    } else {
      setSubTitle(`Referred by ${serviceReferral.organizationName}`);
    }
  }, [
    serviceTypes.data,
    resourceType,
    clientReferral.serviceKind,
    clientReferral.organizationName,
    serviceReferral.organizationName,
  ]);

  return (
    <>
      <CardLayout
        onClick={onClick}
        icon={icon}
        headerRow={
          <Row>
            <Col xs={12} md={8}>
              <Row className={cardHeaderText}>
                <Col xs={12} className={noWrap}>
                  {title}
                </Col>
              </Row>
              <Row className={cardInfoText}>
                <Col xs={12}>{subTitle}</Col>
              </Row>
              <Row className={cardInfoText}>
                <Col xs={12}>
                  <i>{clickText}</i>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4}>
              <div className={`${cardInfoTitle} text-md-center`}>
                <CustomTooltip
                  popup={statusDescription}
                  content={
                    <small className={styles.status}>
                      {referralStatusToName(referral.status)}
                    </small>
                  }
                />
              </div>
            </Col>
          </Row>
        }
        contentRow={
          <>
            <Row>
              <Col xs={12} sm={6}>
                <div className="d-flex flex-column">
                  <div className={`${cardInfoTitle} ${noWrap}`}>Created</div>
                  <div className={cardInfoText}>
                    <>
                      <Moment calendar={CalendarStrings} locale={Locale} local>
                        {referral.createdOn}
                      </Moment>
                      {referral.createdBy && ` by ${referral.createdBy}`}
                    </>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="d-flex flex-column">
                  <div className={`${cardInfoTitle} ${noWrap}`}>
                    Last Updated
                  </div>
                  <div className={cardInfoText}>
                    <>
                      <Moment calendar={CalendarStrings} locale={Locale} local>
                        {referral.lastUpdatedOn}
                      </Moment>
                      {referral.lastUpdatedBy &&
                        ` by ${referral.lastUpdatedBy}`}
                    </>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              {referral.status in actions &&
                actions[referral.status].map((action, index) => {
                  return (
                    <Col
                      key={`action${index}`}
                      xs={12}
                      sm={{
                        span: actionSpan(referral.status),
                        offset: actionOffset(index, referral.status),
                      }}
                    >
                      {action === Action.Accept ? (
                        <PrimaryButton
                          className={styles.action}
                          content="Accept"
                          isLoading={isUpdatingStatus === Action.Accept}
                          isComplete={
                            isUpdatingStatusComplete === Action.Accept
                          }
                          isDisabled={!!isUpdatingStatus}
                          onClick={(): Promise<void> => onUpdate(action)}
                        />
                      ) : action === Action.Waitlist ? (
                        <SecondaryButton
                          className={styles.action}
                          content="Waitlist"
                          isLoading={isUpdatingStatus === Action.Waitlist}
                          isComplete={
                            isUpdatingStatusComplete === Action.Waitlist
                          }
                          isDisabled={!!isUpdatingStatus}
                          onClick={(): Promise<void> => onUpdate(action)}
                        />
                      ) : action === Action.Decline ? (
                        <CancelButton
                          className={styles.action}
                          content="Decline"
                          isLoading={isUpdatingStatus === Action.Decline}
                          isComplete={
                            isUpdatingStatusComplete === Action.Decline
                          }
                          isDisabled={!!isUpdatingStatus}
                          onClick={(): void => handleShowReasonModal(action)}
                        />
                      ) : action === Action.Place ? (
                        <PrimaryButton
                          className={styles.action}
                          content="Mark Placed"
                          isLoading={isUpdatingStatus === Action.Place}
                          isComplete={isUpdatingStatusComplete === Action.Place}
                          isDisabled={!!isUpdatingStatus}
                          onClick={(): Promise<void> => onUpdate(action)}
                        />
                      ) : action === Action.Cancel ? (
                        <CancelButton
                          className={styles.action}
                          content="Cancel"
                          isLoading={isUpdatingStatus === Action.Cancel}
                          isComplete={
                            isUpdatingStatusComplete === Action.Cancel
                          }
                          isDisabled={!!isUpdatingStatus}
                          onClick={(): Promise<void> => onUpdate(action)}
                        />
                      ) : action === Action.Archive ? (
                        <CancelButton
                          className={styles.action}
                          content="Archive"
                          isLoading={isUpdatingStatus === Action.Archive}
                          isComplete={
                            isUpdatingStatusComplete === Action.Archive
                          }
                          isDisabled={!!isUpdatingStatus}
                          onClick={(): Promise<void> => onUpdate(action)}
                        />
                      ) : (
                        <></>
                      )}
                    </Col>
                  );
                })}
            </Row>
          </>
        }
      />

      <Modal centered show={showReasonModal} onHide={handleCloseReasonModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Col xs={12}>
                <Form.Group controlId="formProviderAction">
                  <Form.Label>
                    Please provide the reason for this action.
                  </Form.Label>
                  <Form.Label className="font-weight-bold text-primary">
                    This reason will be provided to the {otherRoleType} when
                    they are notified of the referral update
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    onChange={(e): void => setPendingReason(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a reason
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <CancelButton
              content="Cancel"
              isDisabled={isUpdatingStatus === pendingAction}
              onClick={handleCloseReasonModal}
            />
            <PrimaryButton
              content="Confirm"
              isLoading={isUpdatingStatus === pendingAction}
              onClick={(): void => {
                if (pendingAction) {
                  onUpdate(pendingAction);
                }
              }}
            />
          </Form>
        </Modal.Body>
      </Modal>

      {resourceType === ResourceType.Service && (
        <ServiceAvailabilityModal
          serviceId={resourceId}
          show={showAvailability}
          onHide={(): void => setShowAvailability(false)}
        />
      )}
    </>
  );
};
