import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { LoadingSpinner } from 'src/components/loadingSpinner';
import { PrimaryButton } from 'src/components/primaryButton';
import styles from './cancelButton.module.scss';

interface Props {
  content: string | JSX.Element;
  id?: string;
  className?: string;
  size?: 'sm' | 'lg';
  noBlock?: boolean;
  isLoading?: boolean;
  isComplete?: boolean;
  isDisabled?: boolean;
  shouldConfirm?: boolean;
  onClick?: () => void;
}

export const CancelButton = (props: Props): JSX.Element => {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClick = (e: React.MouseEvent): void => {
    // Prevent bubbling up if the button is inside another element that handles clicks.
    e.stopPropagation();

    if (props.shouldConfirm) {
      setShowConfirm(true);
    } else {
      props.onClick?.();
    }
  };

  const handleAcceptConfirm = (): void => {
    setShowConfirm(false);
    props.onClick?.();
  };

  return (
    <>
      <Button
        variant="light"
        id={props.id || ''}
        className={`${styles.button} ${props.className || ''}`}
        onClick={handleClick}
        disabled={props.isDisabled || props.isLoading}
        size={props.size ?? 'sm'}
        block={!props.noBlock}
      >
        {props.isComplete ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : props.isLoading ? (
          <LoadingSpinner />
        ) : (
          props.content
        )}
      </Button>

      <Modal show={showConfirm} onHide={(): void => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm cancel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel? All existing progress will be lost
        </Modal.Body>
        <Modal.Footer>
          <CancelButton
            content="No"
            onClick={(): void => setShowConfirm(false)}
          />
          <PrimaryButton content="Yes" onClick={handleAcceptConfirm} />
        </Modal.Footer>
      </Modal>
    </>
  );
};
