import { Col, Collapse, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { QuestionFilter, QuestionFilterType } from 'src/models/questionFilter';
import styles from './filterForm.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { QuestionKind } from 'src/models/question';
import { Answer } from 'src/models/answer';
import { BooleanOptions, Delimiter } from 'src/utils/helpers';
import { SelectAllButton } from 'src/components/SelectAllButton';

interface Props {
  filters?: QuestionFilter[] | null;
  answers: Answer[];
  onAnswersUpdated: (answers: Answer[]) => void;
  scopedFilters?: QuestionFilterType[];
}

interface InputProps {
  filter: QuestionFilter;
  answers: Answer[];
  onAnswersUpdated: (state: Answer[]) => void;
}

export const FilterForm = (props: Props): JSX.Element => {
  const [filterExpansionState, setFilterExpansionState] = useState<boolean[]>(
    []
  );

  const renderFilterInput = (filter: QuestionFilter): JSX.Element => {
    switch (filter.questionKind) {
      case QuestionKind.Boolean:
      case QuestionKind.SingleOption:
      case QuestionKind.MultiSelect:
        return (
          <div key={filter.filterType}>
            <MultipleOptionInput
              filter={{
                filterType: filter.filterType,
                label: filter.label,
                questionKind: filter.questionKind,
                options:
                  filter.questionKind === QuestionKind.Boolean
                    ? BooleanOptions
                    : filter.options,
              }}
              answers={props.answers}
              onAnswersUpdated={props.onAnswersUpdated}
            />
          </div>
        );
      case QuestionKind.Number:
        return (
          <div key={filter.filterType}>
            <NumberInput
              filter={{
                filterType: filter.filterType,
                label: filter.label,
                questionKind: filter.questionKind,
                options: filter.options,
              }}
              answers={props.answers}
              onAnswersUpdated={props.onAnswersUpdated}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  const isFilterSelected = (filter: QuestionFilter): boolean => {
    return props.answers.some((a) => a.questionId === filter.filterType);
  };

  const clearFilter = (filter: QuestionFilter): void => {
    props.onAnswersUpdated(
      [...props.answers].filter((a) => a.questionId !== filter.filterType)
    );
  };

  useEffect(() => {
    if (props.filters) {
      setFilterExpansionState(Array(props.filters.length).fill(true));
    } else {
      setFilterExpansionState([]);
    }
  }, [props.filters]);

  return (
    <>
      {props.filters
        ?.filter(
          (f) =>
            !props.scopedFilters || props.scopedFilters.includes(f.filterType)
        )
        .map((filter, filterIndex) => (
          <div key={filter.filterType}>
            <Row
              className={styles.filterItem}
              onClick={(): void => {
                setFilterExpansionState(
                  filterExpansionState.map((exp, expIndex) => {
                    return filterIndex === expIndex ? !exp : exp;
                  })
                );
              }}
            >
              <Col xs={2}>
                <FontAwesomeIcon
                  icon={
                    filterExpansionState[filterIndex]
                      ? faChevronDown
                      : faChevronRight
                  }
                />
              </Col>

              <Col xs={8}>
                <div
                  className={`${
                    isFilterSelected(filter) ? styles.labelSelected : ''
                  }`}
                >
                  {filter.label}
                </div>
              </Col>

              {isFilterSelected(filter) && (
                <Col xs={2} className="text-right">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={(e): void => {
                      clearFilter(filter);
                      e.stopPropagation();
                    }}
                  />
                </Col>
              )}
            </Row>

            <Row className="mt-2">
              <Col xs={{ offset: 2, span: 10 }}>
                <Collapse in={filterExpansionState[filterIndex]}>
                  {renderFilterInput(filter)}
                </Collapse>
              </Col>
            </Row>

            <hr />
          </div>
        ))}
    </>
  );
};

const MultipleOptionInput = (props: InputProps): JSX.Element => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const getSelectedBoxes = useCallback((): string[] => {
    const answer = props.answers.find(
      (a) => a.questionId === props.filter.filterType
    );
    return answer?.value.split(Delimiter) || [];
  }, [props]);

  const updateValues = useCallback(() => {
    const ids = getSelectedBoxes();

    inputRefs.current.forEach((ref) => {
      ref.checked = ids.some((id) => id === ref.value);
    });
  }, [getSelectedBoxes]);

  const onChange = (): void => {
    const checkedIds = inputRefs.current
      .filter((ref) => ref.checked)
      .map((ref) => Number(ref.value));

    const value = checkedIds.join(Delimiter);

    updateAnswers(value);
  };

  const updateAnswers = (value: string): void => {
    // Update the answer if it exists, otherwise add it.
    let answers = [...props.answers];
    const index = answers.findIndex(
      (a) => a.questionId === props.filter.filterType
    );

    if (index === -1) {
      answers.push({ questionId: props.filter.filterType, value: value });
    } else {
      answers = answers.map((answer) => {
        if (answer.questionId === props.filter.filterType) {
          return {
            ...answer,
            value: value,
          };
        }
        return answer;
      });
    }

    props.onAnswersUpdated(answers);
  };

  useEffect(() => {
    updateValues();
  }, [props.answers, updateValues]);

  return (
    <>
      {props.filter.options.map((opt, index) => {
        return (
          <Form.Check
            key={opt.id}
            id={opt.id.toString()}
            value={opt.id}
            label={opt.value}
            onChange={onChange}
            ref={(e: HTMLInputElement): void => {
              inputRefs.current[index] = e;
            }}
          />
        );
      })}
      <SelectAllButton
        allBoxIds={props.filter.options.map((a) => a.id)}
        allAreSelected={(): boolean =>
          getSelectedBoxes().length === props.filter.options.length
        }
        onChange={(newBoxes: number[]): void =>
          updateAnswers(newBoxes.join(Delimiter))
        }
      />
    </>
  );
};

const NumberInput = (props: InputProps): JSX.Element => {
  const getValue = (): string => {
    const answer = props.answers.find(
      (a) => a.questionId === props.filter.filterType
    );

    return answer?.value ?? '';
  };

  const onChange = (value: string): void => {
    // Update the answer if it exists, otherwise add it.
    let answers = [...props.answers];
    const index = answers.findIndex(
      (a) => a.questionId === props.filter.filterType
    );

    if (index === -1) {
      answers.push({ questionId: props.filter.filterType, value: value });
    } else {
      answers = answers.map((answer) => {
        if (answer.questionId === props.filter.filterType) {
          return {
            ...answer,
            value: value,
          };
        }
        return answer;
      });
    }

    props.onAnswersUpdated(answers);
  };

  return (
    <Form.Control
      size="sm"
      type="number"
      min={0}
      placeholder={props.filter.label}
      value={getValue()}
      onChange={(e): void => onChange(e.target.value)}
    />
  );
};
