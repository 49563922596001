import { Image } from 'react-bootstrap';
import logoImage from 'src/images/logo.svg';
import styles from './tiraBrand.module.scss';

interface Props {
  imageHeight?: number;
  fontSize?: number;
  fontMarginTop?: number;
}

export const TiraBrand = (props: Props): JSX.Element => {
  return (
    <div>
      <Image src={logoImage} height={props.imageHeight ?? 60} alt="TIRA Logo" />
      <div
        className={styles.tiraFont}
        style={{
          fontSize: props.fontSize ?? 40,
          marginTop: props.fontMarginTop ?? 24,
        }}
      >
        TIRA
      </div>
    </div>
  );
};
