import { MenuLayout } from 'src/components/menuLayout';
import { ResourcesLayout } from 'src/components/resourcesLayout';
import { AdvocateMenuOptions } from 'src/models/advocateMenuOptions';
import { ResourceType } from 'src/models/resource';
import { apiRoutes } from 'src/services/swr';
import { Routes, UrlParamNames } from 'src/utils/routes';

export const AdvocateClients = (): JSX.Element => {
  return (
    <MenuLayout
      pageIndex={AdvocateMenuOptions().findIndex(
        (o) => o.path === Routes.AdvocateClientsPath
      )}
      menuOptions={AdvocateMenuOptions()}
    >
      <ResourcesLayout
        resourceType={ResourceType.Client}
        resourcesRoute={apiRoutes.advocate.clients}
        detailsRoute={(resourceId): string =>
          apiRoutes.client.details(resourceId)
        }
        referralsRoute={(resourceId): string =>
          apiRoutes.client.referrals(resourceId)
        }
        notesRoute={(resourceId): string => apiRoutes.client.notes(resourceId)}
        deleteRoute={(resourceId): string =>
          apiRoutes.client.delete(resourceId)
        }
        dashboardPath={Routes.AdvocateDashboardPath}
        resourcesPageUrl={Routes.AdvocateClientsPath}
        resourceIdUrlParamName={UrlParamNames.ClientIdUrlParamName}
      />
    </MenuLayout>
  );
};
