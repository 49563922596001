import { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import Moment from 'react-moment';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CancelButton } from 'src/components/cancelButton';
import { CardLayout } from 'src/components/cardLayout';
import { PrimaryButton } from 'src/components/primaryButton';
import { PseudoButton as PseudoButton } from 'src/components/pseudoButton';
import { UserInvite } from 'src/models/user';
import { cardInfoText, noWrap } from 'src/utils/styles';
import { DateFormat, Locale, roleToName } from 'src/utils/helpers';
import styles from './userInviteCard.module.scss';

interface Props {
  invite: UserInvite;
  isAccepting?: boolean;
  isAcceptingComplete?: boolean;
  isDeleting?: boolean;
  onAccept?: () => void;
  onDelete?: () => void;
}

export const UserInviteCard = (props: Props): JSX.Element => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleConfirmDelete = (): void => {
    setShowConfirmDelete(false);
    props.onDelete?.();
  };

  return (
    <>
      <CardLayout
        icon={faEnvelope}
        headerRow={
          <Row>
            <Col className={noWrap}>{props.invite.organizationName}</Col>
          </Row>
        }
        contentRow={
          <Row>
            <Col xs={5}>
              <div className="d-flex flex-column">
                <div className={`${cardInfoText} ${noWrap}`}>Role</div>
                <div className={`${cardInfoText} ${noWrap}`}>Invited By</div>
                <div className={`${cardInfoText} ${noWrap}`}>Expires</div>
                <div className={`${cardInfoText} ${noWrap}`}>Actions</div>
              </div>
            </Col>
            <Col xs={7}>
              <div className="d-flex flex-column">
                <div className={`${cardInfoText} ${noWrap}`}>
                  {roleToName(props.invite.role)}
                </div>
                <div className={`${cardInfoText} ${noWrap}`}>
                  {props.invite.invitedBy}
                </div>
                <div className={`${cardInfoText} ${noWrap}`}>
                  <div
                    className={
                      moment(props.invite.expiresOn).isBefore(moment())
                        ? styles.expired
                        : ''
                    }
                  >
                    <Moment format={DateFormat} locale={Locale} local>
                      {props.invite.expiresOn}
                    </Moment>
                  </div>
                </div>
                <div className={cardInfoText + ' d-flex'}>
                  <PseudoButton
                    className={`${styles.primaryButton} ${styles.clickableText} mr-2`}
                    content="Accept"
                    onClick={(): void => props.onAccept?.()}
                    isLoading={props.isAccepting}
                    isComplete={props.isAcceptingComplete}
                  />
                  <PseudoButton
                    id={`deleteInvite${props.invite.id}`}
                    className={`${styles.deleteButton} ${styles.clickableText}`}
                    content="Decline"
                    onClick={(): void => setShowConfirmDelete(true)}
                    isLoading={props.isDeleting}
                  />
                </div>
              </div>
            </Col>
          </Row>
        }
      />

      <Modal
        centered
        show={showConfirmDelete}
        onHide={(): void => setShowConfirmDelete(false)}
      >
        <Modal.Header closeButton closeLabel="close">
          <Modal.Title>
            {`Delete invite as ${roleToName(props.invite.role)} for ${
              props.invite.organizationName
            }`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <CancelButton
            content="Cancel"
            onClick={(): void => setShowConfirmDelete(false)}
          />
          <PrimaryButton content="Delete" onClick={handleConfirmDelete} />
        </Modal.Footer>
      </Modal>
    </>
  );
};
