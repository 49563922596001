import { toast } from 'react-toastify';
import { DirectorySearchViewModel } from 'src/models/directorySearchViewModel';
import { RegistrationProgress } from 'src/models/registrationProgress';
import { RoleType } from 'src/models/user';
import { debounce, showErrorNotificationWithId } from 'src/utils/helpers';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { useIdToken } from './msal';
import { useMsal } from '@azure/msal-react';

interface SwrError extends Error {
  status: number;
}

export const fetcher = <T>(url: string): Promise<T> =>
  fetch(url).then((r) => r.json());

export enum FetchMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const authenticatedFetch = async <T>(
  url: string,
  method: FetchMethod,
  token: string | null,
  body?: string
): Promise<T | null> => {
  const res = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      lang: 'en',
    },
    body,
  });

  // Debounce
  if (method === FetchMethod.POST || method === FetchMethod.DELETE)
    await debounce();

  if (!res.ok) {
    let message = (await res.text()) ?? res.statusText;

    // Don't fail on 401s for root '/user' route since we check that on home page.
    if (res.status === 401 && url !== apiRoutes.user.get) {
      message =
        'Authentication expired. Please refresh the page or sign out and then sign back in';
    }

    const error = new Error(message) as SwrError;
    error.status = res.status;
    throw error;
  }

  try {
    // If the request is readable, return JSON
    const json = await res.json();
    return json;
  } catch (error) {
    return null;
  }
};

export const useApi = <T>(
  url?: string,
  options?: SWRConfiguration,
  method = 'GET',
  body?: string
): SWRResponse<T | null, SwrError> => {
  const { token, getToken } = useIdToken();
  const { instance } = useMsal();

  return useSWR<T | null>(url ? [url, method, token, body] : null, {
    dedupingInterval: 30_000,
    revalidateOnFocus: false,
    errorRetryCount: 1,
    ...options,
    fetcher: authenticatedFetch,
    onErrorRetry: async (
      error: SwrError,
      _key,
      { errorRetryCount, errorRetryInterval },
      revalidate,
      { retryCount }
    ) => {
      // Don't retry on 403 or 404
      if (error.status === 403 || error.status === 404) {
        return;
      }

      if (error.status === 401) {
        // Don't authenticate on 401s for the root '/user' route since we check that on home page.
        // Just sign the user out so fully to avoid the "signed in but expired" case.
        // If we want to be fancier we could try a silent-refresh-only first (getToken(false))
        // and then sign out only if that fails.
        if (url === apiRoutes.user.get) {
          instance.logout();
        } else {
          await getToken(true);
        }
        return;
      }

      // If we exceed the retry count, stop retrying
      if (errorRetryCount && retryCount > errorRetryCount) {
        return;
      }

      setTimeout(() => revalidate({ retryCount }), errorRetryInterval);
    },
    onError: async (error: SwrError, key, config): Promise<void> => {
      if (options?.onError) {
        options.onError(error, key, config);
        return;
      }
      if (!url) {
        return;
      }

      // Don't show errors on 401s for root '/user' route since we check that on home page.
      if (error.status === 401 && url === apiRoutes.user.get) {
        return;
      }

      showErrorNotificationWithId(
        `${error.message} (${error.status})`,
        error.name
      );
    },
  });
};

const apiRoot = '/api';

export const apiPaths = {
  adminOrganizations: `${apiRoot}/admin/organizations`,
  advocate: `${apiRoot}/advocate`,
  client: `${apiRoot}/client`,
  clientRegistration: `${apiRoot}/clientRegistration`,
  directory: `${apiRoot}/directory`,
  provider: `${apiRoot}/provider`,
  organization: `${apiRoot}/organization`,
  organizationAdmin: `${apiRoot}/organizationAdmin`,
  organizationRegistration: `${apiRoot}/organizationRegistration`,
  service: `${apiRoot}/service`,
  serviceRegistration: `${apiRoot}/serviceRegistration`,
  user: `${apiRoot}/user`,
  userInvites: `${apiRoot}/userInvites`,
};

export const apiRoutes = {
  ...apiPaths,
  api: {
    serviceTypes: `${apiRoot}/serviceTypes`,
    serviceDurationUnits: `${apiRoot}/serviceDurationUnits`,
    clientDetails: (clientId: number): string =>
      `${apiRoot}/clientDetails/${clientId}`,
    serviceDetails: (serviceId: number): string =>
      `${apiRoot}/serviceDetails/${serviceId}`,
  },
  user: {
    get: apiPaths.user,
    invites: apiPaths.userInvites,
    invite: (inviteId: number): string => `${apiPaths.userInvites}/${inviteId}`,
  },
  organizationRegistrationStep1: {
    nonProfitStatuses: `${apiPaths.organizationRegistration}/step1/nonprofitStatuses`,
    primaryPurposes: `${apiPaths.organizationRegistration}/step1/primaryPurposes`,
    affiliationOrLicenses: `${apiPaths.organizationRegistration}/step1/affiliationOrLicenses`,
    submit: `${apiPaths.organizationRegistration}/step1`,
  },
  organizationRegistrationStep2: {
    traits: `${apiPaths.organizationRegistration}/step2/traits`,
    submit: `${apiPaths.organizationRegistration}/step2`,
  },
  organizationAdmin: {
    details: `${apiPaths.organizationAdmin}/details`,
    users: `${apiPaths.organizationAdmin}/users`,
    user: (userId: number): string =>
      `${apiPaths.organizationAdmin}/users/${userId}`,
    userRole: (userId: number, role: RoleType): string =>
      `${apiPaths.organizationAdmin}/users/${userId}/roles/${role}`,
    invites: `${apiPaths.organizationAdmin}/invites`,
    invite: (inviteId: number): string =>
      `${apiPaths.organizationAdmin}/invites/${inviteId}`,
  },
  organization: {
    get: apiPaths.organization,
    referralStats: {
      advocate: `${apiPaths.organization}/referralStats/advocate`,
      provider: `${apiPaths.organization}/referralStats/provider`,
    },
  },
  provider: {
    services: `${apiPaths.provider}/services`,
    botContact: `${apiPaths.provider}/botContact`,
  },
  serviceRegistration: {
    incompleteService: `${apiPaths.serviceRegistration}/incomplete`,
    incompleteProgress: `${apiPaths.serviceRegistration}/progress`,
    submitProfile: `${apiPaths.serviceRegistration}/profile`,
    questionPageCount: (serviceId: number): string =>
      `${apiPaths.serviceRegistration}/${serviceId}/questions`,
    questionPage: ({ id, page }: RegistrationProgress): string =>
      `${apiPaths.serviceRegistration}/${id}/questions/${page}`,
    submitQuestionPage: ({ id, page }: RegistrationProgress): string =>
      `${apiPaths.serviceRegistration}/${id}/questions/${page}`,
  },
  service: {
    get: (serviceId: number): string => `${apiPaths.service}/${serviceId}`,
    details: (serviceId: number): string =>
      `${apiPaths.service}/${serviceId}/details`,
    availability: (serviceId: number): string =>
      `${apiPaths.service}/${serviceId}/availability`,
    referrals: (serviceId: number): string =>
      `${apiPaths.service}/${serviceId}/referrals`,
    referralStats: (serviceId: number): string =>
      `${apiPaths.service}/${serviceId}/referralStats`,
    delete: (serviceId: number): string => `${apiPaths.service}/${serviceId}`,
    updateAvailability: (serviceId: number): string =>
      `${apiPaths.service}/${serviceId}/availability`,

    serviceReferral: {
      referral: (serviceId: number, referralId: number): string =>
        `${apiPaths.service}/${serviceId}/referrals/${referralId}`,
      accept: (serviceId: number, referralId: number): string =>
        `${apiPaths.service}/${serviceId}/referrals/${referralId}/accept`,
      waitlist: (serviceId: number, referralId: number): string =>
        `${apiPaths.service}/${serviceId}/referrals/${referralId}/waitlist`,
      decline: (serviceId: number, referralId: number): string =>
        `${apiPaths.service}/${serviceId}/referrals/${referralId}/decline`,
      place: (serviceId: number, referralId: number): string =>
        `${apiPaths.service}/${serviceId}/referrals/${referralId}/place`,
      cancel: (serviceId: number, referralId: number): string =>
        `${apiPaths.service}/${serviceId}/referrals/${referralId}/cancel`,
      archive: (serviceId: number, referralId: number): string =>
        `${apiPaths.service}/${serviceId}/referrals/${referralId}/archive`,
    },
  },
  advocate: {
    clients: `${apiPaths.advocate}/clients`,
  },
  client: {
    details: (clientId: number): string =>
      `${apiPaths.client}/${clientId}/details`,
    notes: (clientId: number): string => `${apiPaths.client}/${clientId}/notes`,
    referrals: (clientId: number): string =>
      `${apiPaths.client}/${clientId}/referrals`,
    referralStats: (clientId: number): string =>
      `${apiPaths.client}/${clientId}/referralStats`,
    filters: (clientId: number): string =>
      `${apiPaths.client}/${clientId}/filters`,
    delete: (clientId: number): string => `${apiPaths.client}/${clientId}`,

    clientReferral: {
      referral: (clientId: number, referralId: number): string =>
        `${apiPaths.client}/${clientId}/referrals/${referralId}`,
      accept: (clientId: number, referralId: number): string =>
        `${apiPaths.client}/${clientId}/referrals/${referralId}/accept`,
      cancel: (clientId: number, referralId: number): string =>
        `${apiPaths.client}/${clientId}/referrals/${referralId}/cancel`,
      archive: (clientId: number, referralId: number): string =>
        `${apiPaths.client}/${clientId}/referrals/${referralId}/archive`,
    },
  },
  clientRegistration: {
    incompleteClient: `${apiPaths.clientRegistration}/incomplete`,
    incompleteProgress: `${apiPaths.clientRegistration}/progress`,
    submitProfile: `${apiPaths.clientRegistration}/profile`,
    questionPageCount: `${apiPaths.clientRegistration}/questions`,
    questionPage: ({ page }: RegistrationProgress): string =>
      `${apiPaths.clientRegistration}/questions/${page}`,
    submitQuestionPage: ({ id, page }: RegistrationProgress): string =>
      `${apiPaths.clientRegistration}/${id}/questions/${page}`,
  },
  systemAdmin: {
    verifiedOrganizations: `${apiPaths.adminOrganizations}/verified`,
    unverifiedOrganizations: `${apiPaths.adminOrganizations}/unverified`,
    deniedOrganizations: `${apiPaths.adminOrganizations}/denied`,
    deleteOrganization: (organizationId: number): string =>
      `${apiPaths.adminOrganizations}/${organizationId}`,
    approveOrganization: (organizationId: number): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/approve`,
    denyOrganization: (organizationId: number): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/deny`,
    organizationDetails: (organizationId: number): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/details`,
    organizationServices: (organizationId: number): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/services`,
    organizationServiceDetails: (
      organizationId: number,
      serviceId: number
    ): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/services/${serviceId}/details`,
    organizationClients: (organizationId: number): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/clients`,
    organizationClientDetails: (
      organizationId: number,
      clientId: number
    ): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/clients/${clientId}/details`,
    organizationClientNotes: (
      organizationId: number,
      clientId: number
    ): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/clients/${clientId}/notes`,
    organizationUsers: (organizationId: number): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/users`,
    organizationUser: (organizationId: number, userId: number): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/users/${userId}`,
    organizationUserRole: (
      organizationId: number,
      userId: number,
      role: RoleType
    ): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/users/${userId}/role/${role}`,
    organizationInvites: (organizationId: number): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/invites`,
    organizationInvite: (organizationId: number, inviteId: number): string =>
      `${apiPaths.adminOrganizations}/${organizationId}/invites/${inviteId}`,
  },
  directory: {
    filters: `${apiPaths.directory}/filters`,
    search: (search: DirectorySearchViewModel): string =>
      `${apiPaths.directory}/services?serviceType=${search.serviceType}${
        search.states.length > 0
          ? `&countrySubdivisions=${search.states.join(',')}`
          : ''
      }${
        search.filters.length > 0
          ? search.filters.map((f) => `&${f.id}=${f.value}`).join('')
          : ''
      }`,
  },
};
