import { Col, Row } from 'react-bootstrap';
import { RegistrationHeader } from 'src/components/registrationHeader';
import { PrimaryButton } from 'src/components/primaryButton';

interface Props {
  onComplete: () => void;
}

export const OrganizationRegistrationContinue = (props: Props): JSX.Element => {
  return (
    <>
      <RegistrationHeader
        title="Complete your profile now"
        description="Your organization has been verified! Let's complete your organization profile by providing more information about your organization and then registering services."
      />

      <Row>
        <Col xs={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 4 }}>
          <PrimaryButton
            content="Complete Profile"
            isLoading={false}
            onClick={props.onComplete}
          />
        </Col>
      </Row>
    </>
  );
};
