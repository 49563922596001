import { FormEvent, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { LoadingSpinner } from 'src/components/loadingSpinner';
import { PrimaryButton } from 'src/components/primaryButton';
import { UserInviteAdminCard } from 'src/components/userInviteAdminCard';
import { NewUserInvite, RoleType, UserInvite } from 'src/models/user';
import { useIdToken } from 'src/services/msal';
import { authenticatedFetch, FetchMethod, useApi } from 'src/services/swr';
import {
  debounce,
  InvalidId,
  roleToDescription,
  roleToName,
  showErrorNotification,
  showSuccessNotification,
} from 'src/utils/helpers';

const RolesToInvite: RoleType[] = [
  RoleType.OrganizationAdmin,
  RoleType.ServiceContributor,
  RoleType.Advocate,
];

// The routes are passed in because this component is shared
// between organizations and admins. The backend routes need to be kept separate for
// security, so the routes get provided by the parent component.
interface Props {
  invitesRoute?: string;
  inviteRoute: (userId: number) => string | undefined;
}

export const UserInvitesAdminLayout = (props: Props): JSX.Element => {
  const { token } = useIdToken();

  const {
    data: invites,
    isValidating: isLoadingInvites,
    mutate: getInvites,
  } = useApi<UserInvite[]>(props.invitesRoute);

  const [newUserInvite, setNewUserInvite] = useState<NewUserInvite>({
    emailAddress: '',
    role: RoleType.Advocate,
  });

  const [newInviteIsValidated, setNewInviteIsValidated] = useState(false);
  const [isSubmittingNewInvite, setIsSubmittingNewInvite] = useState(false);
  const [isSubmittingNewInviteComplete, setIsSubmittingNewInviteComplete] =
    useState(false);

  const [modifyingInviteId, setModifyingInviteId] = useState(InvalidId);
  const [isDeletingInvite, setIsDeletingInvite] = useState(false);
  const [isResendingInvite, setIsResendingInvite] = useState(false);
  const [isResendingInviteComplete, setIsResendingInviteComplete] =
    useState(false);

  const handleSubmitUserInvite = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (isSubmittingNewInvite) {
      return;
    }

    const form = e.currentTarget;
    if (form.checkValidity()) {
      setNewInviteIsValidated(true);
      setIsSubmittingNewInvite(true);

      try {
        await authenticatedFetch(
          props.invitesRoute || '',
          FetchMethod.POST,
          token,
          JSON.stringify(newUserInvite)
        );

        setIsSubmittingNewInviteComplete(true);
        await debounce();

        setIsSubmittingNewInvite(false);
        setIsSubmittingNewInviteComplete(false);
        setNewInviteIsValidated(false);

        setNewUserInvite({
          ...newUserInvite,
          emailAddress: '',
        });

        await getInvites();
        showSuccessNotification('Invite sent!');
      } catch (error: unknown) {
        showErrorNotification((error as Error).message);
        setIsSubmittingNewInvite(false);
        setNewInviteIsValidated(false);
      }
    }
  };

  const resendUserInvite = async (id: number): Promise<void> => {
    setModifyingInviteId(id);
    setIsResendingInvite(true);

    try {
      await authenticatedFetch(
        props.inviteRoute(id) || '',
        FetchMethod.POST,
        token
      );

      setIsResendingInviteComplete(true);
      await getInvites();
      await debounce();
      setIsResendingInvite(false);
      setIsResendingInviteComplete(false);
      setModifyingInviteId(InvalidId);

      showSuccessNotification('Invite re-sent!');
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
      setIsResendingInvite(false);
      setModifyingInviteId(InvalidId);
    }
  };

  const deleteUserInvite = async (id: number): Promise<void> => {
    setModifyingInviteId(id);
    setIsDeletingInvite(true);

    try {
      await authenticatedFetch(
        props.inviteRoute(id) || '',
        FetchMethod.DELETE,
        token
      );

      await getInvites();
      await debounce();
      setIsDeletingInvite(false);
      setModifyingInviteId(InvalidId);

      showSuccessNotification('Invite deleted!');
    } catch (error: unknown) {
      showErrorNotification((error as Error).message);
      setIsDeletingInvite(false);
      setModifyingInviteId(InvalidId);
    }
  };

  return (
    <>
      <Form validated={newInviteIsValidated} onSubmit={handleSubmitUserInvite}>
        <Form.Row>
          <Col xs={12} lg={8}>
            <Form.Group>
              <Form.Label className="font-weight-bold">
                Invite a user to a role in your organization:
              </Form.Label>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Control
                    id="formInviteRole"
                    size="sm"
                    as="select"
                    value={newUserInvite.role}
                    onChange={(e): void => {
                      setNewUserInvite({
                        ...newUserInvite,
                        role: Number(e.target.value),
                      });
                    }}
                    required
                  >
                    {RolesToInvite.map((role) => (
                      <option key={role} value={role}>
                        {roleToName(role)}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col xs={12} sm={6}>
                  <InputGroup size="sm">
                    <Form.Control
                      id="formInviteEmail"
                      type="email"
                      placeholder="Email"
                      value={newUserInvite.emailAddress}
                      onChange={(e): void => {
                        setNewUserInvite({
                          ...newUserInvite,
                          emailAddress: e.target.value,
                        });
                      }}
                      required
                    />
                    <InputGroup.Append>
                      <PrimaryButton
                        id="formInviteSubmit"
                        content="+"
                        isLoading={isSubmittingNewInvite}
                        isComplete={isSubmittingNewInviteComplete}
                      />
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Form.Label className="small font-italic mt-2">
                {roleToDescription(newUserInvite.role)}
              </Form.Label>
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>

      {isLoadingInvites && !invites ? (
        <div className="d-flex justify-content-center">
          <LoadingSpinner />
        </div>
      ) : invites?.length ? (
        <Row>
          {invites?.map((invite) => (
            <Col key={invite.id} xs={12} md={6}>
              <UserInviteAdminCard
                invite={invite}
                isResending={
                  isResendingInvite && modifyingInviteId === invite.id
                }
                isResendingComplete={
                  isResendingInviteComplete && modifyingInviteId === invite.id
                }
                isDeleting={isDeletingInvite && modifyingInviteId === invite.id}
                onResend={(): Promise<void> => resendUserInvite(invite.id)}
                onDelete={(): Promise<void> => deleteUserInvite(invite.id)}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};
